import React from 'react';

interface DropdownProps {
  onSelectYear: (year: number) => void;
}

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

const Dropdown: React.FC<DropdownProps> = ({ onSelectYear }) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onSelectYear(Number(event.target.value));
  };

  return (
    <div className="dropdown me-2">
      <select className="form-select" onChange={handleChange}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;