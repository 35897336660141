import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../core/common/dataTable/index";
import apiDefinitions from "../../../api/apiDefinitions";

import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";

import { useNavigate } from "react-router-dom";
import FileUpload from "./fileUploader";

import Swal from "sweetalert2";
import toast from "react-hot-toast";

const PenddingBudgets = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(13), (val, index) => currentYear + index);

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [fieldErrors, setFieldErrors] = useState<any>({
    year: "",
    budget: "",
    file: "",
  });
  const [clearFileUploader, setClearFileUploader] = useState(false);
  const [year, setYear] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [employeeId, setEmployeeId] = useState("");
  const [initalError, setInitalError] = useState(false);
  const hasDataLoaded = useRef(false);

  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  const handleClear = () => {
    setYear("");
    setBudget("");
    setUploadedFile([]);
    setClearFileUploader(true);

    // Clear errors
    setFieldErrors({ year: "", budget: "", file: "" });
  };

  useEffect(() => {
    if (clearFileUploader) {
      setClearFileUploader(false);
    }
  }, [clearFileUploader]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  const handleSubmit = () => {
    let isvalid = true;

    if (year === "") {
      handleErrors({ year: "Year is required" });
      isvalid = false;
    }

    if (budget === "") {
      handleErrors({ budget: "Budget is required" });
      isvalid = false;
    }

    if (budget === "0.00") {
      handleErrors({ budget: "Budget must be greater than 0" });
      isvalid = false;
    }

    if (uploadedFile.length === 0) {
      handleErrors({ file: "File is required" });
      isvalid = false;
    }

    if (
      fieldErrors.year === "" &&
      fieldErrors.budget === "" &&
      fieldErrors.file === "" &&
      isvalid
    ) {
      // Collect all form data
      const formData = new FormData();

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        requestBudget: budget,
        year: year,
        requestedBy: employeeId,
      };

      formData.append("budgetDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want Request New Annual Budget?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Requesting budget...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .createBudget(token, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Budget Requested Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Request Budget");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          toast("Changes Were Not Saved", {
            icon: "⚠️",
          });
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date in YYYY-MM-DD format
  };

  interface Budget {
    id: string;
    requestBudget: string;
    year: string;
    status: { id: number; name: string; color: string }[];
    startDate: string;
    endDate: string;
  }

  interface Column {
    title: string;
    dataIndex: string;
    render?: (text: any, record: Budget) => JSX.Element;
  }

  const columns: Column[] = [
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Requested Budget",
      dataIndex: "requestBudget",
    },
    {
      title: "Requested Date",
      dataIndex: "startDate",
      render: (startDate: string) => <span>{formatDate(startDate)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: { id: number; name: string; color: string }[]) => {
        if (status.length > 0) {
          const { name, color } = status[0]; // Extract the first status
          return (
            <div>
              <span className={`badge badge-pill badge-status bg-${color}`}>
                {name}
              </span>
            </div>
          );
        }
        return <span>No Status</span>; // Handle case where status is empty
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record: Budget) => (
        <div className="action-icons d-flex gap-2">
          <Link
            to={`/budget/approvedBudgets/viewBudget/${record.id}`}
            className="text-info"
            title="View Details"
          >
            <i className="ti ti-eye" style={{ fontSize: "1.8rem" }}></i>
          </Link>
          <Link
            to="#"
            className="text-danger"
            title="Delete"
            onClick={() => handleDelete(record.id)}
          >
            <i className="ti ti-trash" style={{ fontSize: "1.8rem" }}></i>
          </Link>
        </div>
      ),
    },
  ];

  const refreshCampaigns = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasDataLoaded.current) {
      hasDataLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions.getNotApprovedBudgets(token).then((response: any) => {
          if (response.data.code === 200) {
            console.log("Campaign Categories: ", response.data.data);
            setCampaignList(response.data.data);
            toast.dismiss(toastId);
            toast.success("Data Loaded Successfully");
          } else {
            toast.dismiss(toastId);
            toast.error("Failed to Load Data");
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    refreshCampaigns();
  }, []);

  useEffect(() => {
    const budgetRegex = /^\d+(\.\d{2})$/;
    if (budget === "") {
      if (initalError) {
        handleErrors({ budget: "Budget is required" });
      } else {
        setInitalError(true);
      }
    } else if (!budgetRegex.test(budget)) {
      handleErrors({
        budget: "Budget must be a number with exactly two decimal places",
      });
    } else {
      handleErrors({ budget: "" });
    }
  }, [budget]);

  useEffect(() => {
    if (year === "") {
      if (initalError) {
        handleErrors({ year: "Year is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ year: "" });
    }
  }, [year]);

  useEffect(() => {
    if (uploadedFile.length === 0) {
      if (initalError) {
        handleErrors({ file: "File is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ file: "" });
    }
  }, [uploadedFile]);

  const handleDelete = (id: any) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        apiDefinitions.deleteBudget(token, id).then((response) => {
          if (response.data.code === 200) {
            toast.success("Budget Deleted Successfully");
            refreshCampaigns();
          } else {
            toast.error("Failed to Delete Budget");
          }
        });
      }
    });
  };

  useEffect(() => {
    const modalElement = document.getElementById("con-close-modal");
    if (modalElement) {
      modalElement.addEventListener("hidden.bs.modal", handleClear);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hidden.bs.modal", handleClear);
      }
    };
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Annual Budget</h4>
                  </div>
                  <div className="col-8 text-end">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              <div className="campaign-tab">
                <ul className="nav">
                  <li>
                    <Link
                      to={route.createBudget}
                      className={
                        isLinkActive(route.createBudget) ? "active" : ""
                      }
                    >
                      Approved Budgets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={route.PenddingBudgets}
                      className={
                        isLinkActive(route.PenddingBudgets) ? "active" : ""
                      }
                    >
                      Requested Budgets
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Campaign Tab */}
              <div className="card">
                <div className="card-header">
                  {/* Search */}
                  <div className="row align-items-center">
                    <div className="row align-items-center justify-content-between">
                      {/* Search Bar aligned to the Left */}
                      <div className="col-sm-6">
                        {/* <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Campaign"
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                        </div> */}
                      </div>
                      <div
                        className="modal fade"
                        id="con-close-modal"
                        tabIndex={-1}
                        aria-labelledby="con-close-modal-label"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="con-close-modal-label"
                              >
                                Request New Budget for Year
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body p-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="field-1"
                                      className="form-label"
                                    >
                                      Year
                                    </label>
                                    <select
                                      className="form-control"
                                      id="field-1"
                                      value={year}
                                      onChange={(e) => setYear(e.target.value)}
                                    >
                                      {years.map((year) => (
                                        <option key={year} value={year}>
                                          {year}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  {fieldErrors.year !== "" ? (
                                    <div className="text-danger">
                                      {fieldErrors.year}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-6">
                                  <label
                                    htmlFor="field-2"
                                    className="form-label"
                                  >
                                    Estimated Budget
                                  </label>
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      LKR
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder="50000.00"
                                      aria-describedby="basic-addon1"
                                      value={budget}
                                      onChange={(e) =>
                                        setBudget(e.target.value)
                                      }
                                    />
                                  </div>
                                  {fieldErrors.budget !== "" ? (
                                    <div className="text-danger">
                                      {fieldErrors.budget}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="modal-body">
                                  <FileUpload
                                    onFileSelect={handleFileSelect}
                                    clear={clearFileUploader}
                                  />
                                  {fieldErrors.file !== "" ? (
                                    <div className="text-danger">
                                      {fieldErrors.file}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-cancel waves-effect"
                                data-bs-dismiss="modal"
                                onClick={handleClear}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Button aligned to the Right */}
                      <div className="col-sm-3 text-end">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mt-1 me-1"
                          data-bs-toggle="modal"
                          data-bs-target="#con-close-modal"
                          onClick={() => {
                            setInitalError(false);
                            setFieldErrors({ file: "" });
                          }}
                        >
                          Request New Budget
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12 mt-3">
                        <div className="table-responsive custom-table">
                          <Table dataSource={campaignList} columns={columns} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default PenddingBudgets;
