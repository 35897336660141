import ApexCharts from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import CollapseHeader from "../../../core/common/collapse-header";
import { all_routes } from "../../router/all_routes";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import Dropdown from "../leadsDashboard/yearDropDown";
import apiDefinitions from "../../../api/apiDefinitions";
import ReactApexChart from "react-apexcharts";
// import ImageWithBasePath from "../../../core/common/imageWithBasePath";
import { jwtDecode } from "jwt-decode";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";

const currentYear = new Date().getFullYear();
const route = all_routes;
const defultMonth = 30;

const CampainDashBoard = () => {
  const chartRef = useRef(null);
  const navigate = useNavigate();

  const [selectedStatusMonth, setSelectedStatusMonth] = useState(defultMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedChanelDistribution, setSelectedChanelDistribution] =
    useState(defultMonth);
  const [channelDistribution, setChannelDistribution] = useState<any[]>([]);
  const [leadVariation, setLeadVariation] = useState<any[]>([]);
  const [activeCampaigns, setActiveCampaigns] = useState<any[]>([]);
  const hasCampaignStatusLoad = useRef(false);
  const hasActiveCampaignLoad = useRef(false);
  const hasDistributionLoad = useRef(false);
  const hasCampaignByMonthLoad = useRef(false);
  const hasLeadVariationLoad = useRef(false);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const handleSelectYear = (year: number) => {
    setSelectedYear(year);
    console.log("Selected year:", year); // You can use the selected year value as needed
  };

  const [chartOptions, setChartOptions] = useState<any>({
    series: [20, 50, 100],
    chart: {
      width: 400,
      height: 300,
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
    colors: ["#4A00E5", "#FFA201", "#0092E4"],
    labels: ["Active", "Inactive", "Completed"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [sCol, setSCol] = useState<any>({
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Actual Leads",
        data: [],
      },
      {
        name: "Target Leads",
        data: [],
      },
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "Lead Count",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  });

  //get campaign status data

  useEffect(() => {
    if (!hasCampaignStatusLoad.current) {
      hasCampaignStatusLoad.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getCampaignStatusCount(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;

            setChartOptions((prevOptions: any) => {
              const updatedOptions = {
                ...prevOptions,
                series: [apiData.active, apiData.inactive, apiData.completed],
              };
              console.log("Updated Chart Options: ", updatedOptions);
              return updatedOptions;
            });
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [navigate]);

  useEffect(() => {
    if (!hasDistributionLoad.current) {
      hasDistributionLoad.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getCampaignsByChannel(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;
            setChannelDistribution(apiData);
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [navigate]);

  useEffect(() => {
    if (!hasCampaignByMonthLoad.current) {
      hasCampaignByMonthLoad.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getCampainsByMonth(token, selectedYear)
          .then((response) => {
            if (response.data.code === 200) {
              const apiData = response.data.data;

              // Transform API data into an array for the chart
              const monthlyCounts = Array.from(
                { length: 12 },
                (_, i) => apiData[i + 1] || 0
              );

              // Update chart options dynamically
              setChartOptions1((prevOptions: any) => ({
                ...prevOptions,
                series: [
                  {
                    name: "Reports",
                    data: monthlyCounts,
                  },
                ],
              }));
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [navigate, selectedYear]);

  //get active campaign data
  useEffect(() => {
    if (!hasActiveCampaignLoad.current) {
      hasActiveCampaignLoad.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getActiveCampaigns(token).then((response: any) => {
          if (response.data.code === 200) {
            const apiData = response.data.data;

            setActiveCampaigns(apiData);
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [navigate]);

  useEffect(() => {
    if (!hasLeadVariationLoad.current) {
      hasLeadVariationLoad.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getLeadVariationByYear(token, currentYear)
          .then((response: any) => {
            if (response.data.code === 200) {
              const apiData = response.data.data;

              // Transform the API response for chart data
              const actualLeads: any[] = [];
              const targetLeads: any[] = [];
              for (let i = 1; i <= 12; i++) {
                const monthData = apiData[i] || {
                  actualCount: 0,
                  estimatedCount: 0,
                };
                actualLeads.push(monthData.actualCount);
                targetLeads.push(monthData.estimatedCount);
              }

              // Update the chart configuration
              setSCol((prevConfig: any) => ({
                ...prevConfig,
                series: [
                  { name: "Actual Leads", data: actualLeads },
                  { name: "Target Leads", data: targetLeads },
                ],
              }));

              setLeadVariation(apiData);
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [navigate]);

  const [chartOptions1, setChartOptions1] = useState<any>({
    series: [
      {
        name: "Reports",
        data: [20, 30, 20, 40, 50, 20, 30, 7, 30, 40, 10, 40],
      },
    ],
    colors: ["#4A00E5"],
    chart: {
      height: 273,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      min: 0,
      max: 60,
      tickAmount: 5,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  });

  useEffect(() => {
    if (chartRef.current && channelDistribution.length > 0) {
      // Map API data to ApexCharts format
      const chartData = channelDistribution?.map((item) => ({
        x: item.channelName,
        y: item.count,
      }));

      const options = {
        series: [
          {
            name: "Campaign Counts",
            data: chartData,
          },
        ],
        chart: {
          type: "bar",
          height: 300,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            columnWidth: "40%",
          },
        },
        colors: ["#00918E"],
        xaxis: {
          type: "category",
          labels: {
            style: {
              fontSize: "10px",
              fontWeight: 700,
            },
          },
        },
        yaxis: {
          min: 0,
          max: Math.max(...chartData.map((item) => item.y)) + 2, // Dynamically adjust max
          tickAmount: 5,
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Cleanup on unmount
      return () => {
        chart.destroy();
      };
    }
  }, [channelDistribution]);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center ">
                <div className="col-md-4">
                  <h3 className="page-title">Campaign Dashboard</h3>
                </div>
                <div className="col-md-8 float-end ms-auto">
                  <div className="d-flex title-head">
                    <div className="daterange-picker d-flex align-items-center justify-content-center">
                      <div className="head-icons mb-0">
                        <CollapseHeader />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 d-flex">
            <div className="card  w-100">
              <div
                className="card-header"
                style={{
                  textAlign: "center",
                  padding: "1rem",
                  borderBottom: "none",
                  color: "#000",
                  borderRadius: "12px 12px 0 0",
                }}
              >
                <h5
                  className="card-title"
                  style={{ fontSize: "1.5rem", margin: 0 }}
                >
                  This Year Summary
                </h5>
              </div>
              <div className="card-body">
                {[
                  {
                    title: "Top Campaign By Leads",
                    subText: "New Campaign45",
                    icon: "fa-solid fa-chart-line",
                  },
                  {
                    title: "Most Successful Campaign",
                    subText: "Name696",
                    icon: "fa-solid fa-trophy",
                  },
                  {
                    title: "Leads Per Amount",
                    subText: "LKR 500",
                    icon: "fa-solid fa-money-bill-wave",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="col-xl-12"
                    style={{ marginBottom: "1rem" }}
                  >
                    <div
                      className="card"
                      style={{
                        border: "none",
                        borderRadius: "10px",
                        background: "linear-gradient(135deg, #ffffff, #d4e8f7)",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "1rem",
                        }}
                      >
                        <i
                          className={item.icon}
                          style={{
                            fontSize: "2rem",
                            marginRight: "1rem",
                            color: "#4a90e2",
                          }}
                        ></i>
                        <div style={{ textAlign: "center", flexGrow: 1 }}>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "1.3rem",
                              fontWeight: "600",
                              color: "#333",
                            }}
                          >
                            {item.title}
                          </p>
                          <p
                            style={{
                              margin: 0,
                              fontSize: "1.1rem",
                              fontWeight: "400",
                              color: "#777",
                            }}
                          >
                            {item.subText}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-8 d-flex">
            <div className="card w-100">
              <div className="card-header">
                <h5 className="card-title">Lead Variations</h5>
              </div>
              <div className="card-body">
                <div id="s-col" />
                <ReactApexChart
                  options={sCol}
                  series={sCol.series}
                  type="bar"
                  height={350}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 d-flex">
            <div className="card w-100">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Campaign Status
                  </h4>
                </div>
              </div>
              <div className="card-body">
                <div id="leadpiechart">
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type={chartOptions.chart.type}
                    width={chartOptions.chart.width}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 d-flex">
            <div className="card flex-fill">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Channel Distribution
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        Last {selectedChanelDistribution} Days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedChanelDistribution(30)}
                        >
                          Last 30 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedChanelDistribution(15)}
                        >
                          Last 15 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedChanelDistribution(7)}
                        >
                          Last 7 Days
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="deals-chart" ref={chartRef} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card w-100">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Campaigns By Months
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <Dropdown onSelectYear={handleSelectYear} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="contact-report">
                  <Chart
                    options={chartOptions1}
                    series={chartOptions1.series}
                    type="area"
                    height={chartOptions1.chart.height}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card w-100">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Active Campaigns
                  </h4>
                </div>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <div className="row">
                    {/* Card 1 */}
                    <div className="row">
                      {activeCampaigns?.map(
                        (
                          campaign: {
                            name:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined;
                            leadCount:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined;
                          },
                          index: React.Key | null | undefined
                        ) => (
                          <div className="col-md-4 d-flex" key={index}>
                            <div className="card border mb-3 w-100">
                              <div className="card-body d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <ImageWithBasePath
                                    src="/assets/img/picture.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                  <div className="ms-3">
                                    <h6 className="fw-semibold">
                                      {campaign.name}
                                    </h6>
                                    <p className="mb-0">
                                      Leads: {campaign.leadCount}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {/* Card 2 */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampainDashBoard;
