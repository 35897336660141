export const contracts = [
  {
    id: "1",
    contractId: "#1493024",
    subject: "SEO Proposal",
    customer: "NovaWave LLC",
    client_img: "assets/img/icons/company-icon-01.svg",
    contractValue: "$2,05,426",
    contractType: "Contracts under Seal",
    startDate: "15 Aug 2024",
    endDate: "15 May 2024",
    key: "1",
  },
  {
    id: "2",
    contractId: "#1493023",
    subject: "Web Design",
    customer: "Redwood Inc",
    client_img: "assets/img/icons/company-icon-02.svg",
    contractValue: "$2,105",
    contractType: "Implied Contracts",
    startDate: "15 Sep 2024",
    endDate: "15 Apr 2024",
    key: "2",
  },
  {
    id: "3",
    contractId: "#1493022",
    subject: "Logo & Branding",
    customer: "HarborView",
    client_img: "assets/img/icons/company-icon-03.svg",
    contractValue: "$4,05,656",
    contractType: "Implied Contracts",
    startDate: "15 Nov 2024",
    endDate: "15 Mar 2024",
    key: "3",
  },
  {
    id: "4",
    contractId: "#1493021",
    subject: "Development",
    customer: "CoastalStar Co.",
    client_img: "assets/img/icons/company-icon-04.svg",
    contractValue: "$2,05,426",
    contractType: "Executory Contracts",
    startDate: "15 Jun 2024",
    endDate: "15 Feb 2024",
    key: "4",
  },
  {
    id: "5",
    contractId: "#1493020",
    subject: "SEO Proposal",
    customer: "RiverStone Ventur",
    contractValue: "$3,15,145",
    client_img: "assets/img/icons/company-icon-05.svg",
    contractType: "Voidable Contracts",
    startDate: "15 Oct 2024",
    endDate: "15 Jan 2024",
    key: "5",
  },
  {
    id: "6",
    contractId: "#1493019",
    subject: "Web Design",
    customer: "Summit Peak",
    contractValue: "$6,154",
    contractType: "Unilateral Contracts",
    client_img: "assets/img/icons/company-icon-06.svg",
    startDate: "08 Aug 2024",
    endDate: "15 Dec 2023",
    key: "6",
  },
  {
    id: "7",
    contractId: "#1493018",
    subject: "Logo",
    customer: "Silver Hawk",
    client_img: "assets/img/icons/company-icon-07.svg",
    contractValue: "$1,457",
    contractType: "Unconscionable",
    startDate: "25 Jan 2025",
    endDate: "15 Nov 2023",
    key: "7",
  },
  {
    id: "8",
    contractId: "#1493017",
    subject: "Branding",
    customer: "BlueSky Industries",
    client_img: "assets/img/icons/company-icon-08.svg",
    contractValue: "$2,01,464",
    contractType: "Express Contracts",
    startDate: "12 Aug 2024",
    endDate: "15 Sep 2023",
    key: "8",
  },
  {
    id: "9",
    contractId: "#1493018",
    subject: "Development",
    customer: "Golden Gate Ltd",
    client_img: "assets/img/icons/company-icon-06.svg",
    contractValue: "$1,10,145",
    contractType: "Contracts under Seal",
    startDate: "07 Dec 2024",
    endDate: "15 Aug 2023",
    key: "9",
  },
];
