import React, { useEffect, useState } from "react";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import { Link } from "react-router-dom";
import { Pagination, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const ViewCallHistory = () => {
  const route = all_routes;
  const navigate = useNavigate();

  const [callHistory, setCallHistory] = useState<Lead[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface Lead {
    id: number;
    calldate: string;
    src: number;
    dst: number;
    dcontext: string;
    durationFormatted: number;
    disposition: string;
    cnam: string;
    cnum: string;
  }

  interface FetchLeadsResponse {
    content: Lead[];
    totalElements: number;
    totalPages: number;
  }

  const fetchLeads = async (
    page: number,
    itemsPerPage: number
  ): Promise<void> => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    // const offset = (page - 1) * itemsPerPage;
    const offset = currentPage - 1;

    console.log("Fetching leads with offset", offset);

    const loadingToastId = toast.loading("Fetching leads...");

    try {
      const response = await apiDefinitions.getCallHistory(
        token,
        offset,
        itemsPerPage
      );
      const data: FetchLeadsResponse = response.data;
      setCallHistory(response.data.data.content);
      console.log("Fetched leads", data.content);
      setTotalPages(data.totalPages);
      toast.update(loadingToastId, {
        render: "Leads fetched successfully",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });
    } catch (error) {
      console.error("Failed to fetch leads", error);
      toast.update(loadingToastId, {
        render: "Failed to fetch leads",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        fetchLeads(currentPage, itemsPerPage);
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    console.log("Current page:", currentPage);
  }, [currentPage]);

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header justify-content-between">
                  <div className="card-title">Call History</div>
                  <Form.Select
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    aria-label="Select number of items per page"
                    className="w-auto"
                    style={{ marginTop: 10 }}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </Form.Select>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table text-nowrap table-sm">
                      <thead>
                        <tr>
                          <th scope="col">Call Date & Time</th>
                          <th scope="col">Call From</th>
                          <th scope="col">Call To</th>
                          <th scope="col">Call Type</th>
                          <th scope="col">Department</th>
                          <th scope="col">Dept. Call No.</th>
                          <th scope="col">Duration</th>
                          <th scope="col">Disposition</th>
                        </tr>
                      </thead>
                      <tbody>
                        {callHistory.map((lead, index) => (
                          <tr key={index}>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.calldate}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.dst}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.src}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.dcontext === "from-internal"
                                  ? "Internal"
                                  : lead.dcontext === "ext-local"
                                  ? "External"
                                  : lead.dcontext}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.cnam}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.cnum}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.durationFormatted}
                              </div>
                            </td>
                            {/* <td>
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                {lead.disposition}
                              </div>
                            </td> */}

                            <td>
                              <span
                                className={`badge bg-soft-${
                                  lead.disposition === "FAILED"
                                    ? "danger"
                                    : lead.disposition === "NO ANSWER"
                                    ? "secondary"
                                    : lead.disposition === "ANSWERED"
                                    ? "success"
                                    : lead.disposition === "BUSY"
                                    ? "danger"
                                    : "danger"
                                }`}
                              >
                                {lead.disposition ? lead.disposition : "N/A"}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination className="mt-3 justify-content-center">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCallHistory;
