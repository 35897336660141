

export const options1 = [
    { value: 'Select a View', label: 'Select a View' },
    { value: 'Contact View List', label: 'Contact View List' },
    { value: 'Contact Location View', label: 'Contact Location View' }
  ];
export  const optionssymbol = [
    { value: 'Choose', label: 'Choose' },
    { value: '$', label: '$' },
    { value: '€', label: '€' }
  ];
export  const duration = [
    { value: 'Choose', label: 'Choose' },
    { value: 'Days', label: 'Days' },
    { value: 'Month', label: 'Month' }
  ];

export const optionschoose = [
    { value: 'Choose', label: 'Choose' },
    { value: 'Work', label: 'Work' },
    { value: 'Home', label: 'Home' }
  ];
  
  export const options = [
    { value: 'Choose', label: 'Choose' },
    { value: 'NovaWave LLC', label: 'NovaWave LLC' },
    { value: 'SilverHawk', label: 'SilverHawk' },
    { value: 'SummitPeak', label: 'SummitPeak' },
    { value: 'RiverStone Ventur', label: 'RiverStone Ventur' },
    { value: 'China', label: 'China' },
  ];
  export const clientList = [
    { value: 'Choose', label: 'Choose' },
    { value: 'NovaWave LLC', label: 'NovaWave LLC' },
    { value: 'SilverHawk', label: 'SilverHawk' },
    { value: 'HarborView', label: 'HarborView' },
  ];
  export const category = [
    { value: 'Select', label: 'Select' },
    { value: 'HarborView', label: 'HarborView' },
    { value: 'LLC', label: 'LLC' },
  ];
  export const projectTiming = [
    { value: 'Select', label: 'Select' },
    { value: 'Hourly', label: 'Hourly' },
    { value: 'Minutes', label: 'Minutes' },
  ];
  
  export const optionsource = [
    { value: 'Choose', label: 'Choose' },
    { value: 'Phone Calls', label: 'Phone Calls' },
    { value: 'Social Media', label: 'Social Media' },
    { value: 'Referral Sites', label: 'Referral Sites' },
    { value: 'Web Analytics', label: 'Web Analytics' },
    { value: 'Previous Purchases', label: 'Previous Purchases' },
  ];
  export const optionindustry = [
    { value: 'Choose', label: 'Choose' },
    { value: 'Retail Industry', label: 'Retail Industry' },
    { value: 'Banking', label: 'Banking' },
    { value: 'Hotels', label: 'Hotels' },
    { value: 'Financial Services', label: 'Financial Services' },
    { value: 'Insurance', label: 'Insurance' },
  ];
  export const optiondeals = [
    { value: 'Choose', label: 'Choose' },
    { value: 'Collins', label: 'Collins' },
    { value: 'Konopelski', label: 'Konopelski' },
    { value: 'Adams', label: 'Adams' },
    { value: 'Schumm', label: 'Schumm' },
    { value: 'Wisozk', label: 'Wisozk' },
  ];
 export const languageOptions = [
  { value: 'Choose', label: 'Choose' },
    { value: 'English', label: 'English' },
    { value: 'Arabic', label: 'Arabic' },
    { value: 'Chinese', label: 'Chinese' },
    { value: 'Hindi', label: 'Hindi' },
];
 export const optionsowner = [
    { label: 'Jerald', value: 'Jerald', image: 'assets/img/profiles/avatar-14.jpg' },
    { label: 'Sharon Roy', value: 'Sharon Roy', image: 'assets/img/profiles/avatar-20.jpg' },
    { label: 'Vaughan', value: 'Vaughan', image: 'assets/img/profiles/avatar-21.jpg' },
    { label: 'Jessica', value: 'Jessica', image: 'assets/img/profiles/avatar-23.jpg' },
    { label: 'Carol Thomas', value: 'Carol Thomas', image: 'assets/img/profiles/avatar-16.jpg' }
  ];
 export const optionsowner1 = [
    { label: 'Darlee Robertson', value: 'Jerald', image: 'assets/img/profiles/avatar-19.jpg' },
    { label: 'Sharon Roy', value: 'Sharon Roy', image: 'assets/img/profiles/avatar-20.jpg' },
    { label: 'Vaughan', value: 'Vaughan', image: 'assets/img/profiles/avatar-21.jpg' },
    { label: 'Jessica', value: 'Jessica', image: 'assets/img/profiles/avatar-23.jpg' },
    { label: 'Carol Thomas', value: 'Carol Thomas', image: 'assets/img/profiles/avatar-16.jpg' }
  ];

export  const countryoptions1 = [
    { value: 'Choose', label: 'Choose' },
    { value: 'India', label: 'India' },
    { value: 'USA', label: 'USA' },
    { value: 'France', label: 'France' },
    { value: 'UK', label: 'UK' },
    { value: 'UAE', label: 'UAE' },

  ];
  export const lastModified  = [
    { value: "sortByDate", label: "Sort by Date" },
    { value: "Ascending", label: "Ascending" },
    { value: "Descending", label: "Descending" },
    { value: "Recently Viewed", label: "Recently Viewed" },
    { value: "Recently Added", label: "Recently Added" },
    { value: "Creation Date", label: "Creation Date" },
  ];
  export const recent  = [
    { value: "sortByDate", label: "Recent" },
    { value: "Last Modified", label: "Last Modified" },
    { value: "Last Modified by me", label: "Last Modified by me" },
  ];
  export const companyName = [
    { value: 'choose', label: 'Choose' },
    { value: 'novaWave', label: 'NovaWave LLC' },
    { value: 'blueSky', label: 'BlueSky Industries' },
    { value: 'silverHawk', label: 'SilverHawk' },
    { value: 'summitPeak', label: 'SummitPeak' },
    { value: 'riverStone', label: 'RiverStone Ventur' }
  ];

  export const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
        "Last 30 Days": [
            new Date("2020-07-12T04:57:17.076Z"),
            new Date("2020-08-10T04:57:17.076Z"),
        ],
        "Last 7 Days": [
            new Date("2020-08-04T04:57:17.076Z"),
            new Date("2020-08-10T04:57:17.076Z"),
        ],
        "Last Month": [
            new Date("2020-06-30T18:30:00.000Z"),
            new Date("2020-07-31T18:29:59.999Z"),
        ],
        "This Month": [
            new Date("2020-07-31T18:30:00.000Z"),
            new Date("2020-08-31T18:29:59.999Z"),
        ],
        Today: [
            new Date("2020-08-10T04:57:17.076Z"),
            new Date("2020-08-10T04:57:17.076Z"),
        ],
        Yesterday: [
            new Date("2020-08-09T04:57:17.076Z"),
            new Date("2020-08-09T04:57:17.076Z"),
        ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
};



export const salestypelist  = [
  { value: 'sales', label: 'Sales' },
  { value: 'marketing', label: 'Marketing' },
  { value: 'calls', label: 'Calls' }
];
export const status  = [
  { value: 'Choose', label: 'Choose' },
  { value: 'Open', label: 'Open' },
  { value: 'Lost', label: 'Lost' },
  { value: 'Won', label: 'Won' },
];
export const project  = [
  { value: 'Devops Design', label: 'Devops Design' },
  { value: 'MargrateDesign', label: 'MargrateDesign' },
  { value: 'UI for Chat', label: 'UI for Chat' },
  { value: 'Web Chat', label: 'Web Chat' },
];
export const defaultValues = [
  { value: 'Devops Design', label: 'Devops Design' },
  { value: 'MargrateDesign', label: 'MargrateDesign' },
  { value: 'UI for Chat', label: 'UI for Chat' }
];

export const socialMedia  = [
  { value: 'Select', label: 'Select' },
  { value: 'Google', label: 'Google' },
  { value: 'Social Media', label: 'Social Media' },
];
export const priorityList  = [
  { value: 'Select', label: 'Select' },
  { value: 'Highy', label: 'Highy' },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
];
export const tagInputValues = [
  { value: 'Devops Design', label: 'Devops Design' },
  { value: 'MargrateDesign', label: 'MargrateDesign' },
];
export const projectType = [
  { value: 'Choose', label: 'Choose' },
  { value: 'Mobile App', label: 'Mobile App' },
  { value: 'Meeting', label: 'Meeting' },
];
export const status1 = [
  { value: 'Choose', label: 'Choose' },
  { value: 'Mobile App', label: 'Mobile App' },
  { value: 'Meeting', label: 'Meeting' },
];
export const activeList = [
  { value: 'Select', label: 'Select' },
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
];
export const ascendingandDecending = [
  { value: 'Sort By Date', label: 'Sort By Date' },
  { value: 'Ascending', label: 'Ascending' },
  { value: 'Descending', label: 'Descending' },
];
export const statusList = [
  { value: 'Busy', label: 'Busy' },
  { value: 'Unavailable', label: 'Unavailable' },
  { value: 'No Answer', label: 'No Answer' },
  { value: 'Wrong Number', label: 'Wrong Number' },
  { value: 'Left Voice Message', label: 'Left Voice Message' },
  { value: 'Moving Forward', label: 'Moving Forward' },
];
export const owner = [
  { value: 'Jerald', label: 'Jerald' },
  { value: 'Guillory', label: 'Guillory' },
  { value: 'Jami', label: 'Jami' },
  { value: 'Theresa', label: 'Theresa' },
  { value: ' Espinosa', label: ' Espinosa' },
];
export const assigneeOption = [
  { value: 'Darlee Robertson', label: 'Darlee Robertson', image: 'assets/img/profiles/avatar-19.jpg' },
  { value: 'Sharon Roy', label: 'Sharon Roy', image: 'assets/img/profiles/avatar-20.jpg' },
  { value: 'Vaughan', label: 'Vaughan', image: 'assets/img/profiles/avatar-21.jpg' },
  { value: 'Jessica', label: 'Jessica', image: 'assets/img/profiles/avatar-23.jpg' },
  { value: 'Carol Thomas', label: 'Carol Thomas', image: 'assets/img/profiles/avatar-16.jpg' }
];
export const ratings = [
  { value: '', label: 'Choose(1-5)' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];
export const priority = [
  { value: '', label: 'Choose' },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
]
export const statusOption = [
  { value: 'Closed', label: 'Closed' },
  { value: 'Open', label: 'Open' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Resolved', label: 'Resolved' },
];
export const newselectOption = [
  { value: 'Select', label: 'Select' },
  { value: 'Truelysell', label: 'Truelysell' },
  { value: 'Dreamsports', label: 'Dreamsports' },
  { value: 'Doccure', label: 'Doccure' },
  { value: 'Kofejob', label: 'Kofejob' },
];
export const releatdselectOption = [
  { value: 'Select', label: 'Select' },
  { value: 'Truelysell', label: 'Truelysell' },
  { value: 'Dreamsports', label: 'Dreamsports' },
  { value: 'Doccure', label: 'Doccure' },
  { value: 'Kofejob', label: 'Kofejob' },
  { value: 'Best@laundry', label: 'Best@laundry' },
];
export const editreleatdselectOption = [
  { value: 'Select', label: 'Select' },
  { value: 'Deals', label: 'Deals' },
  { value: 'Truelysell', label: 'Truelysell' },
  { value: 'Dreamsports', label: 'Dreamsports' },
  { value: 'Doccure', label: 'Doccure' },
  { value: 'Kofejob', label: 'Kofejob' },
  { value: 'Best@laundry', label: 'Best@laundry' },
];
export const dealsselectOption = [
  { value: 'Select', label: 'Select' },
  { value: 'Collins', label: 'Collins' },
  { value: 'Best@laundry', label: 'Best@laundry' },
  { value: 'Dreamsports', label: 'Dreamsports' },
  { value: 'Doccure', label: 'Doccure' },
  { value: 'Kofejob', label: 'Kofejob' },
 
];
export const currency = [
  { value: '$', label: '$' },
  { value: 'Low', label: 'Low' },
  { value: 'Medium', label: 'Medium' },
  { value: 'High', label: 'High' },
]
export const contracts = [
  { value: 'Contracts under Seal', label: 'Contracts under Seal' },
  { value: 'Meeting', label: 'Meeting' },
]
export const documentType = [
  { value: 'Contract', label: '1' },
  { value: 'Proposal', label: '2' },
  { value: 'Quote', label: '3' },
]
export const LocaleData = [
  { value: 'en', label: '1' },
  { value: 'es', label: '2' },
  { value: 'ru', label: '3' },
]
export const accountType = [
  { value: 'Gmail', label: '1' },
  { value: 'Outlook', label: '2' },
  { value: 'Imap', label: '3' },
]
export const client = [
  { value: 'Harbor View', label: 'Harbor View' },
  { value: 'CoastalStar Co.', label: 'CoastalStar Co.' },
  { value: 'RiverStone Ventur.', label: 'RiverStone Ventur.' },
]
export const contracttype = [
  { value: 'Contracts under Seal', label: 'Contracts under Seal' },
  { value: 'Contract', label: 'Contract' },
  { value: 'RiverStone Ventur', label: 'RiverStone Ventur' },
]
export const currencyselect= [
  { value: 'Choose', label: 'Choose' },
  { value: '$', label: '$' },
  { value: 'INR', label: 'INR' },
  { value: 'Euro', label: 'Euro' },
]



