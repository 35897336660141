import ApexCharts from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CollapseHeader from "../../core/common/collapse-header";
import { all_routes } from "../router/all_routes";
import { useNavigate, useParams } from "react-router-dom";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import FileUpload from "./approvedBudgets/fileUploader";
import toast from "react-hot-toast";
import apiDefinitions from "../../api/apiDefinitions";
import ImageWithBasePath from "../../core/common/imageWithBasePath";
import Swal from "sweetalert2";
import SingleFileUpload from "./approvedBudgets/singleFileUploader";

const route = all_routes;
const defultMonth = 30;

const ViewBudget = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [budget, setBudget] = useState<string>("");
  const [uploadedFile, setUploadedFile] = useState<File[]>([]);
  const [note, setNote] = useState<string>("");
  const [fieldErrors, setFieldErrors] = useState<any>({
    note: "",
    budget: "",
    file: "",
  });
  const [clearFileUploader, setClearFileUploader] = useState(false);
  const [attendaceDocuments, setAttendaceDocuments] = useState<any>([]);
  const [uploadAttendaceDocuments, setUploadAttendaceDocuments] =
    useState<any>(null);
  const [checkValidation, setCheckValidation] = useState(false);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [reload, setReload] = useState(false);
  const [topUpData, setTopUpData] = useState<any>([]);
  const [campaignData, setCampaignData] = useState<any>([]);
  const [budgetData, setBudgetData] = useState<any>([]);
  const [diffData, setDiffData] = useState<string>("0");
  const [allocatedBudget, setAllocatedBudget] = useState<string>("0");
  const [initalError, setInitalError] = useState(false);

  const { id } = useParams();
  const budgetId = id;
  const hasImageLoaded = useRef(false);
  const hasTopUpListLoaded = useRef(false);
  const hasbudgetLoaded = useRef(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  const handleClear = () => {
    setFieldErrors({ note: "", budget: "", file: "" });

    console.log(fieldErrors);
    setBudget("");
    setNote("");
    setUploadedFile([]);
    setClearFileUploader(true);
  };

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  useEffect(() => {
    if (clearFileUploader) {
      setClearFileUploader(false);
    }
  }, [clearFileUploader]);

  const handleFileSelect = (files: any) => {
    setUploadedFile(files);
  };

  useState(defultMonth);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    if (!hasImageLoaded.current) {
      hasImageLoaded.current = true;
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getJustificationByBudgetID(token, budgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              setUploadAttendaceDocuments(response.data.data);
              toast.dismiss(toastId);
              toast.success("Data Loaded", {
                duration: 5000,
              });
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    if (!hasTopUpListLoaded.current) {
      hasTopUpListLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getTopUpBudgets(token, budgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              const apiData = response.data.data;
              setTopUpData(apiData);
              // Do something with maxDataValue if needed
            } else {
              console.error("Failed to fetch data: ", response.data.message);
            }
          })
          .catch((error: any) => {
            console.error("API call failed: ", error);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    if (!hasbudgetLoaded.current) {
      hasbudgetLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getBudgetByBudgetId(token, budgetId)
          .then((response: any) => {
            if (response.data.code === 200) {
              const apiData = response.data.data;
              setBudgetData(apiData);

              apiDefinitions
                .getCampaignsByAnualBudget(token, apiData[0].year)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    const apiData1 = response.data.data;
                    setCampaignData(apiData1);
                    // Do something with maxDataValue if needed
                  } else {
                    console.error(
                      "Failed to fetch data: ",
                      response.data.message
                    );
                  }
                })
                .catch((error: any) => {
                  console.error("API call failed: ", error);
                });

              apiDefinitions
                .getBudgetDifference(token, apiData[0].year)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    const apiData = response.data.data;

                    setChartOptions((prevOptions: any) => {
                      const updatedOptions = {
                        ...prevOptions,
                        series: [
                          parseInt(apiData.difference, 10),
                          parseInt(apiData.allocatedBudgetSum, 10),
                        ],
                      };
                      console.log("Updated Chart Options: ", updatedOptions);
                      return updatedOptions;
                    });
                  } else {
                    console.error(
                      "Failed to fetch data: ",
                      response.data.message
                    );
                  }
                })
                .catch((error: any) => {
                  console.error("API call failed: ", error);
                });
            } else {
              console.error("Failed to fetch data: ", response.data.message);
            }
          })
          .catch((error: any) => {
            console.error("API call failed: ", error);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    if (uploadAttendaceDocuments !== null) {
      const transformedData = uploadAttendaceDocuments.map(
        (url: string, index: number) => {
          const filePath = url || "";
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "";
          const fileExtension = filePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "xls";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        }
      );

      setAttendaceDocuments(transformedData);
    } else {
      // Handle the case where uploadAttendaceDocuments is null
      // You might want to set some default value or handle it based on your requirements
      setAttendaceDocuments([]);
    }
  }, [uploadAttendaceDocuments]);

  const [chartOptions, setChartOptions] = useState<any>({
    series: [0, 0],
    chart: {
      width: 400,
      height: 300,
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
    colors: ["#4CAF50", "#F44336"],
    labels: ["Remaining", "Spent"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [sline] = useState<any>({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    // colors: ['#4361ee'],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    series: [
      {
        name: "Desktops",
        data: [100, 90, 80, 60, 49, 62, 69, 91, 148, 40, 51, 49],
      },
    ],

    grid: {
      row: {
        colors: ["#f1f2f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });

  useEffect(() => {
    const budgetRegex = /^\d+(\.\d{2})$/;
    if (budget === "") {
      if (initalError) {
        handleErrors({ budget: "Budget is required" });
      } else {
        setInitalError(true);
      }
    } else if (!budgetRegex.test(budget)) {
      handleErrors({
        budget: "Budget must be a number with exactly two decimal places",
      });
    } else {
      handleErrors({ budget: "" });
    }
  }, [budget]);

  useEffect(() => {
    if (uploadedFile.length === 0) {
      if (initalError) {
        handleErrors({ file: "File is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ file: "" });
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (note === "") {
      if (initalError) {
        handleErrors({ note: "Note is required" });
      } else {
        setInitalError(true);
      }
    } else {
      handleErrors({ note: "" });
    }
  }, [note]);

  useEffect(() => {
    console.log("errors", fieldErrors);
  }, [fieldErrors]);

  const handleDownloadJustification = (urls: any) => {
    if (Array.isArray(urls) && urls.length > 0) {
      urls.forEach((url: string) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split("/").pop() || "justification";
        link.click();
      });
    } else {
      alert("No justification file available.");
    }
  };

  const handleSubmit = () => {
    let isvalid = true;

    if (budget === "0.00") {
      handleErrors({ budget: "Budget must be greater than 0" });
      isvalid = false;
    }

    if (budget === "") {
      handleErrors({ budget: "Budget is required" });
      isvalid = false;
    }

    if (note === "") {
      handleErrors({ note: "Note is required" });
      isvalid = false;
    }

    if (uploadedFile.length === 0) {
      handleErrors({ file: "File is required" });
      isvalid = false;
    }

    if (
      fieldErrors.note === "" &&
      fieldErrors.budget === "" &&
      fieldErrors.file === "" &&
      isvalid
    ) {
      // Collect all form data
      const formData = new FormData();

      if (uploadedFile && uploadedFile.length > 0) {
        uploadedFile.forEach((file) => {
          formData.append("files", file);
        });
      }

      const payload = {
        topUpAmount: parseFloat(budget),
        reason: note,
        topUpBy: employeeId,
      };

      formData.append("topUpBudgetDto", JSON.stringify(payload));

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      Swal.fire({
        title: "Do You Want Annual Budget Top Up ?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Requesting budget...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .requestTopUpBudget(token, budgetId, formData)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Budget Top Up Requested Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to Request Budget Budget Top Up");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center ">
                <div className="col-md-4">
                  <h3 className="page-title">
                    {budgetData[0]?.year} Annual Budget
                  </h3>
                </div>
                <div className="col-md-8 float-end ms-auto">
                  <div className="d-flex title-head">
                    {/* Back Button */}
                    <div className="col-2 text-end me-3">
                      {" "}
                      {/* Added 'me-3' for spacing */}
                      <Link
                        to="/budget/approvedBudgets"
                        className="btn btn-primary"
                      >
                        <i
                          className="feather-arrow-left"
                          style={{ fontSize: "17px", marginRight: "5px" }}
                        />
                        Back to list
                      </Link>
                    </div>
                    <div className="daterange-picker d-flex align-items-center justify-content-center">
                      <div className="head-icons mb-0">
                        <CollapseHeader />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {budgetData[0]?.status[0]?.name !== "Approved" ? (
          <div>
            <div className="col-md-12">
              <div
                className="card"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "500px", // Ensure it takes full height of the row
                }}
              >
                <div className="card-header">
                  <h5 className="card-title">Justification Documents</h5>
                </div>
                <div
                  className="card-body"
                  style={{
                    overflowY: "auto", // Make scrollable if content overflows
                  }}
                >
                  <div className="row">
                    {attendaceDocuments.map(
                      (data: {
                        id: React.Key | null | undefined;
                        fileType: string;
                        fileName: any;
                        filePath: string | URL | undefined;
                      }) => (
                        <div className="col-md-3 mb-5" key={data.id}>
                          <div className="d-flex flex-column align-items-center justify-content-center">
                            {/* File Type Icon */}
                            <div className="mb-3">
                              {data.fileType === "pdf" ? (
                                <ImageWithBasePath
                                  src="assets/img/pdf.png"
                                  className="img-fluid"
                                  alt="Logo"
                                  style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                />
                              ) : data.fileType === "image" ? (
                                <ImageWithBasePath
                                  src="assets/img/picture.png"
                                  className="img-fluid"
                                  alt="Logo"
                                  style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                />
                              ) : data.fileType === "doc" ? (
                                <ImageWithBasePath
                                  src="assets/img/doc.png"
                                  className="img-fluid"
                                  alt="Logo"
                                  style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                />
                              ) : data.fileType === "xls" ? (
                                <ImageWithBasePath
                                  src="assets/img/xls.png"
                                  className="img-fluid"
                                  alt="Logo"
                                  style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                />
                              ) : null}
                            </div>
                            {/* File Details */}
                            <div className="text-center">
                              <h6
                                className="mb-2 text-truncate"
                                style={{
                                  maxWidth: "150px", // Adjust width as per your layout
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                title={data.fileName} // Show full name on hover
                              >
                                {data.fileName || "Placeholder Name"}
                              </h6>
                            </div>
                            {/* Action Buttons */}
                            <div className="d-flex justify-content-center">
                              <a
                                href={data.filePath?.toString()} // File URL
                                download={data.fileName} // Download file with its name
                                className="btn btn-outline-primary me-2 btn-sm"
                              >
                                Download File
                              </a>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-5 d-flex">
                <div className="card w-100">
                  {/* <div className="card-header border-0 pb-0"> */}
                  <div className="card-header">
                    <h5 className="card-title">Budget Status</h5>
                  </div>
                  {/* </div> */}
                  <div className="card-body">
                    <div id="leadpiechart">
                      <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type={chartOptions.chart.type}
                        width={chartOptions.chart.width}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div
                  className="card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "400px", // Ensure it takes full height of the row
                  }}
                >
                  <div className="card-header">
                    <h5 className="card-title">Justification Documents</h5>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto", // Make scrollable if content overflows
                    }}
                  >
                    <div className="row">
                      {attendaceDocuments.map(
                        (data: {
                          id: React.Key | null | undefined;
                          fileType: string;
                          fileName: any;
                          filePath: string | URL | undefined;
                        }) => (
                          <div className="col-md-4 mb-4" key={data.id}>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                              {/* File Type Icon */}
                              <div className="mb-3">
                                {data.fileType === "pdf" ? (
                                  <ImageWithBasePath
                                    src="assets/img/pdf.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "image" ? (
                                  <ImageWithBasePath
                                    src="assets/img/picture.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "doc" ? (
                                  <ImageWithBasePath
                                    src="assets/img/doc.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : data.fileType === "xls" ? (
                                  <ImageWithBasePath
                                    src="assets/img/xls.png"
                                    className="img-fluid"
                                    alt="Logo"
                                    style={{ width: "35px", height: "auto" }} // Adjust the width and height as needed
                                  />
                                ) : null}
                              </div>
                              {/* File Details */}
                              <div className="text-center">
                                <h6
                                  className="mb-2 text-truncate"
                                  style={{
                                    maxWidth: "150px", // Adjust width as per your layout
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                  title={data.fileName} // Show full name on hover
                                >
                                  {data.fileName || "Placeholder Name"}
                                </h6>
                              </div>
                              {/* Action Buttons */}
                              <div className="d-flex justify-content-center">
                                <a
                                  href={data.filePath?.toString()} // File URL
                                  download={data.fileName} // Download file with its name
                                  className="btn btn-outline-primary me-2 btn-sm"
                                >
                                  Download File
                                </a>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 d-flex">
                <div
                  className="card "
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "400px", // Ensure it takes full height of the row
                    width: "100%",
                  }}
                >
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <h4>
                        <i className="ti ti-grip-vertical me-1" />
                        Budget Top-Up
                      </h4>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-1 me-1"
                        data-bs-toggle="modal"
                        data-bs-target="#con-close-modal"
                        onClick={() => {
                          setInitalError(false);
                          setFieldErrors({ file: "" });
                        }}
                        disabled={
                          budgetData[0]?.status[0]?.name === "Approved"
                            ? false
                            : true
                        }
                      >
                        Top Up Budget
                      </button>
                    </div>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto", // Make scrollable if content overflows
                    }}
                  >
                    <div className="table-responsive custom-table">
                      <table className="table dataTable" id="deals-project">
                        <thead className="thead-light">
                          <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {topUpData.map(
                            (
                              data: {
                                topUpFileJustifications: never[];
                                topUpDate:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                                topUpAmount:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                                status: {
                                  color: any;
                                  name:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<
                                        any,
                                        | string
                                        | React.JSXElementConstructor<any>
                                      >
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                }[];
                              },
                              index: React.Key | null | undefined
                            ) => (
                              <tr
                                key={index}
                                className={
                                  typeof index === "number" && index % 2 === 0
                                    ? "even"
                                    : "odd"
                                }
                              >
                                <td>{data.topUpDate}</td>
                                <td>LKR {data.topUpAmount}</td>
                                <td>
                                  <div>
                                    <span
                                      className={`badge badge-pill badge-status bg-${data.status[0]?.color}`}
                                    >
                                      {data.status[0]?.name}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    className="btn btn-link text-info"
                                    title="Download Justification"
                                    onClick={() =>
                                      handleDownloadJustification(
                                        data.topUpFileJustifications || []
                                      )
                                    }
                                  >
                                    <i
                                      className="feather-download"
                                      style={{
                                        fontSize: "1.3rem",
                                        marginLeft: "10px",
                                      }}
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div
                  className="card "
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "400px", // Ensure it takes full height of the row
                    width: "100%",
                  }}
                >
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <div className="col-md-12 m-2">
                        <h4>
                          <i className="ti ti-grip-vertical me-1" />
                          Campaigns Under {budgetData[0]?.year} Budget
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body"
                    style={{
                      overflowY: "auto", // Make scrollable if content overflows
                    }}
                  >
                    <div className="table-responsive custom-table">
                      <table className="table dataTable" id="deals-project">
                        <thead className="thead-light">
                          <tr>
                            <th>Name</th>
                            <th>Allocated Budget</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campaignData.map(
                            (
                              campaign: {
                                id: number;
                                name:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                                allocatedBudget:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                                startDate:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                                endDate:
                                  | string
                                  | number
                                  | boolean
                                  | React.ReactElement<
                                      any,
                                      string | React.JSXElementConstructor<any>
                                    >
                                  | Iterable<React.ReactNode>
                                  | React.ReactPortal
                                  | null
                                  | undefined;
                              },
                              index: React.Key | null | undefined
                            ) => (
                              <tr
                                key={index}
                                className={
                                  typeof index === "number" && index % 2 === 0
                                    ? "even"
                                    : "odd"
                                }
                              >
                                <td>{campaign.name}</td>
                                <td>LKR {campaign.allocatedBudget}</td>
                                <td>
                                  {campaign.startDate &&
                                  typeof campaign.startDate === "string"
                                    ? new Date(campaign.startDate)
                                        .toISOString()
                                        .slice(0, 10)
                                    : "Invalid Date"}
                                </td>
                                <td>
                                  {campaign.endDate &&
                                  typeof campaign.endDate === "string"
                                    ? new Date(campaign.endDate)
                                        .toISOString()
                                        .slice(0, 10)
                                    : "Invalid Date"}
                                </td>
                                <td>
                                  <Link
                                    to={`${route.viewCampaign.replace(
                                      ":id",
                                      campaign.id.toString()
                                    )}`}
                                    className="text-info"
                                    title="View Details"
                                  >
                                    <i
                                      className="ti ti-eye"
                                      style={{
                                        fontSize: "1.3rem",
                                        marginLeft: "10px",
                                      }}
                                    ></i>
                                  </Link>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        className="modal fade"
        id="con-close-modal"
        tabIndex={-1}
        aria-labelledby="con-close-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="con-close-modal-label">
                Top Up {budgetData[0]?.year} Budget
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p-4">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="field-2" className="form-label">
                    Top Up Amount
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      LKR
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="field-2"
                      placeholder="50000.00"
                      aria-describedby="basic-addon1"
                      value={budget}
                      onChange={(e) => setBudget(e.target.value)}
                    />
                  </div>
                  {fieldErrors.budget !== "" ? (
                    <div className="text-danger">{fieldErrors.budget}</div>
                  ) : null}
                  <div className="col-md-12 mt-3">
                    <label htmlFor="note" className="form-label">
                      Note
                    </label>
                    <textarea
                      className="form-control"
                      id="note"
                      rows={3}
                      placeholder="Enter your note here"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                  </div>
                  {fieldErrors.note !== "" ? (
                    <div className="text-danger">{fieldErrors.note}</div>
                  ) : null}
                </div>
                <div className="modal-body">
                  <SingleFileUpload
                    onFileSelect={handleFileSelect}
                    clear={clearFileUploader}
                  />
                  {fieldErrors.file !== "" ? (
                    <div className="text-danger">{fieldErrors.file}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-cancel waves-effect"
                data-bs-dismiss="modal"
                onClick={handleClear}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={handleSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBudget;
