export const pipelineData = [
  {
    id: 1,
    si_no: "",
    name: "Sales",
    deal_value: "$4,50,664",
    no_deal: "315",
    stage: "Win",
    createdat: "25 Sep 2023",
    status: "Active",
    Action: "",
    key: "1"
  },
  {
    id: 2,
    si_no: "",
    name: "Marketing",
    deal_value: "$3,12,893",
    no_deal: "447",
    stage: "Win",
    createdat: "29 Sep 2023",
    status: "Active",
    Action: "",
    key: "2"
  },
  {
    id: 3,
    si_no: "",
    name: "Email",
    deal_value: "$2,89,274",
    no_deal: "654",
    stage: "In Pipeline",
    createdat: "15 Oct 2023",
    status: "Active",
    Action: "",
    key: "3"
  },
  {
    id: 4,
    si_no: "",
    name: "Chats",
    deal_value: "$1,59,326",
    no_deal: "768",
    stage: "Win",
    createdat: "29 Oct 2023",
    status: "Active",
    Action: "",
    key: "4"
  },
  {
    id: 5,
    si_no: "",
    name: "Operational",
    deal_value: "$2,90,173",
    no_deal: "142",
    stage: "Win",
    createdat: "03 Nov 2023",
    status: "Inactive",
    Action: "",
    key: "5"
  },
  {
    id: 6,
    si_no: "",
    name: "Collaborative",
    deal_value: "$4,51,417",
    no_deal: "315",
    stage: "Conversation",
    createdat: "17 Nov 2023",
    status: "Active",
    Action: "",
    key: "6"
  },
  {
    id: 7,
    si_no: "",
    name: "Differentiate",
    deal_value: "$3,17,589",
    no_deal: "478",
    stage: "Lost",
    createdat: "23 Nov 2023",
    status: "Active",
    Action: "",
    key: "7"
  },
  {
    id: 8,
    si_no: "",
    name: "Interact ",
    deal_value: "$1,69,146",
    no_deal: "664",
    stage: "Lost",
    createdat: "09 Dec 2023",
    status: "Active",
    Action: "",
    key: "8"
  },
];
