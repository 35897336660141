import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { SidebarData } from "../../data/json/sidebarData";
import ImageWithBasePath from "../imageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { setExpandMenu } from "../../data/redux/commonSlice";
import { jwtDecode } from "jwt-decode";
import apiDefinitions from "../../../api/apiDefinitions";
import { all_routes } from "../../../feature-module/router/all_routes";

interface DecodedToken {
  exp: number;
  serial_number: string;
  name: string;
  [key: string]: any;
}

const Sidebar: React.FC = () => {
  const route = all_routes;
  const location = useLocation();
  const expandMenu = useSelector((state: any) => state.expandMenu);
  const dispatch = useDispatch();

  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [profilePic, setProfilePic] = useState(
    "assets/img/profiles/avatar-14.jpg"
  );
  const [department, setDepartment] = useState("");
  const [subOpen, setSubOpen] = useState<string | null>(null);
  const [subsidebar, setSubSidebar] = useState<string | null>(null);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.serial_number);
      // setEmployeeName(decodedToken.name);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      apiDefinitions
        .getProfilePic(employeeId)
        .then((response: any) => {
          if (response.data.code === 200) {
            setProfilePic(response.data.data.profilePictureUrl);
            setEmployeeName(response.data.data.nameWithInitials);
            setDepartment(response.data.data.department);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch profile picture", error);
        });
    }
  }, [employeeId]);

  const handleSidebarToggle = (menu: string) => {
    setSubOpen((prev) => (prev === menu ? null : menu));
  };

  const handleSubSidebarToggle = (submenu: string) => {
    setSubSidebar((prev) => (prev === submenu ? null : submenu));
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div
      className="sidebar"
      id="sidebar"
      onMouseEnter={() => dispatch(setExpandMenu(true))}
      onMouseLeave={() => dispatch(setExpandMenu(false))}
    >
      <Scrollbars>
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="clinicdropdown theme">
                <Link to={route.profile}>
                  <img
                    src={profilePic}
                    className="img-fluid profile-image"
                    alt="Profile"
                  />
                  <div className="user-names">
                    <h5>{employeeName}</h5>
                    <h6>{department}</h6>
                  </div>
                </Link>
              </li>
            </ul>

            <ul>
              {SidebarData?.map((mainLabel, index) => (
                <li className="clinicdropdown" key={index}>
                  <h6 className="submenu-hdr">{mainLabel.label}</h6>
                  <ul>
                    {mainLabel.submenuItems?.map((submenu: any) => (
                      <li
                        className={`submenu ${
                          subOpen === submenu.label ? "active" : ""
                        }`}
                        key={submenu.label}
                      >
                        <Link
                          to={submenu.link || "#"}
                          onClick={() => handleSidebarToggle(submenu.label)}
                          className={`${
                            isActive(submenu.link) ? "active" : ""
                          }`}
                        >
                          <i className={submenu.icon}></i>
                          <span>{submenu.label}</span>
                          {submenu.submenuItems && (
                            <span className="menu-arrow" />
                          )}
                        </Link>
                        {submenu.submenuItems && (
                          <ul
                            style={{
                              display:
                                subOpen === submenu.label ? "block" : "none",
                            }}
                          >
                            {submenu.submenuItems.map((item: any) => (
                              <li key={item.label}>
                                <Link
                                  to={item.link}
                                  className={`${
                                    isActive(item.link) ? "active" : ""
                                  }`}
                                  onClick={() =>
                                    handleSubSidebarToggle(item.label)
                                  }
                                >
                                  {item.label}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Sidebar;
