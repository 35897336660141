import React, { useEffect, useState } from "react";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import Select, { MultiValue } from "react-select";

const channelSubTypeOptions = [
  { value: "Sub-A", label: "Sub Type A" },
  { value: "Sub-B", label: "Sub Type B" },
  { value: "Sub-C", label: "Sub Type C" },
];

const CampaignModal = ({
  refreshCampaigns,
  refreshWindow,
}: {
  refreshCampaigns: () => void;
  refreshWindow: () => void;
}) => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface Category {
    id: number;
    name: string;
  }

  interface OptionType {
    value: string;
    label: string;
  }

  interface Option {
    value: string;
    label: string;
  }

  interface TargetAudience {
    value: string;
    label: string;
  }

  interface Channel {
    id: number;
    selectedChannel: string;
    assignedBy: string[];
  }

  const route = all_routes;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [goal, setGoal] = useState("");
  const [targetAudience, setTargetAudience] = useState<string[]>([]);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [leadCount, setLeadCount] = useState("");
  const [isDigitalChecked, setIsDigitalChecked] = useState(false);
  const [isOfflineChecked, setIsOfflineChecked] = useState(false);

  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [categorySubList, setCategorySubList] = useState<Category[]>([]);
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [targetAudienceList, setTargetAudienceList] = useState<any[]>([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any[]>([]);
  const [campaignTypeAdded, setCampaignTypeAdded] = useState(false);
  const [allocatedBudget, setAllocatedBudget] = useState("");
  interface ChannelError {
    selectedCategory?: boolean;
    [key: string]: any;
  }

  const [channelErrors, setChannelErrors] = useState<ChannelError[]>([]);

  //Error Handling
  const [isNameValid, setIsNameValid] = useState(false);
  const [isCampaignTypeValid, setIsCampaignTypeValid] = useState(false);
  const [isDescriptionValid, setIsDescriptionValid] = useState(false);
  const [isGoalValid, setIsGoalValid] = useState(false);
  const [isTargetAudienceValid, setIsTargetAudienceValid] = useState(false);
  const [isStatusValid, setIsStatusValid] = useState(false);
  const [isStartDateValid, setIsStartDateValid] = useState(false);
  const [isStartEndValid, setIsStartEndValid] = useState(false);
  const [isChannelValid, setIsChannelValid] = useState(false);
  const [isSubCategoryValid, setIsSubCategoryValid] = useState(false);
  const [isLeadCountValid, setIsLeadCountValid] = useState(false);
  const [campaignCategory, setCampaignCategory] = useState("");
  const [isAllocatedBudgetValid, setIsAllocatedBudgetValid] = useState(false);

  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);

  const [channelTypeOptions, setChannelTypeOptions] = useState<{
    [campaignIndex: number]: { [channelIndex: number]: OptionType[] };
  }>({});

  const [channelSubTypeOptions, setChannelSubTypeOptions] = useState<{
    [campaignIndex: number]: { [channelIndex: number]: OptionType[] };
  }>({});

  useEffect(() => {
    const fetchChannelTypesForAllCampaigns = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      const updatedOptions = { ...channelTypeOptions };

      for (
        let campaignIndex = 0;
        campaignIndex < campaigns.length;
        campaignIndex++
      ) {
        const channelIds = campaigns[campaignIndex].channelIds;

        for (
          let channelIndex = 0;
          channelIndex < channelIds.length;
          channelIndex++
        ) {
          const channelId = channelIds[channelIndex];
          if (channelId && !updatedOptions[campaignIndex]?.[channelIndex]) {
            try {
              const response = await apiDefinitions.getChannelTypes(
                token,
                channelId
              );
              const fetchedOptions: OptionType[] = response.data.data.map(
                (item: any) => ({
                  value: item.id.toString(),
                  label: item.name,
                })
              );

              updatedOptions[campaignIndex] = {
                ...(updatedOptions[campaignIndex] || {}),
                [channelIndex]: fetchedOptions,
              };
            } catch (error) {
              console.error(
                `Failed to fetch channel types for channelId ${channelId}`,
                error
              );
            }
          }
        }
      }

      setChannelTypeOptions(updatedOptions);
    };

    fetchChannelTypesForAllCampaigns();
  }, [campaigns]);

  interface CampaignType {
    id: number;
    selectedCategory: string;
    channels: Channel[];
    assignedEmployees: string[];
    channelTypes: string[];
    channelSubTypes: string[][];
    channelIds: number[]; // Add this to store channel IDs
  }

  const [campaignTypes, setCampaignTypes] = useState<CampaignType[]>([]);
  const targeyAudience = [
    { value: "small_business", label: "Small Business" },
    { value: "corporate_companies", label: "Corporate Companies" },
    { value: "urban_apartment", label: "Urban Apartment" },
    { value: "business", label: "Business" },
  ];

  const options: Option[] = employeeList.map((employee) => ({
    value: employee.serial_number,
    label: employee.name_in_full,
  }));

  const targetAudiences: TargetAudience[] = targetAudienceList.map(
    (target) => ({
      value: target.id,
      label: target.name,
    })
  );

  const addCampaignType = () => {
    setCampaigns([
      ...campaigns,
      {
        id: campaigns.length,
        selectedCategory: "",
        channels: [],
        assignedEmployees: [],
        channelTypes: [],
        channelSubTypes: [],
        channelIds: [], // Add this to store channel IDs
      },
    ]);
    setChannelErrors([...channelErrors, {}]);
  };

  interface HandleCategoryChange {
    (campaignIndex: number, value: string): void;
  }

  const handleCategoryChange: HandleCategoryChange = async (
    campaignIndex,
    value
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].selectedCategory = value;

    // Mock or real channelId based on the selected category
    const channelId = getChannelIdFromCategory(value); // Mock function
    updatedCampaigns[campaignIndex].channelIds[0] = channelId; // Set for the first channel
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (value) {
      updatedErrors[campaignIndex].selectedCategory = false;
    }
    setChannelErrors(updatedErrors);

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      // Fetch channel types for the selected channel ID
      const response = await apiDefinitions.getChannelTypes(token, channelId);
      const fetchedOptions: OptionType[] = response.data.data.map(
        (item: any) => ({
          value: item.id.toString(),
          label: item.name,
        })
      );

      // Update the channel type options dynamically
      setChannelTypeOptions((prevOptions) => ({
        ...prevOptions,
        [campaignIndex]: {
          0: fetchedOptions, // Assuming this is the first channel
        },
      }));
    } catch (error) {
      console.error(
        `Failed to fetch channel types for channelId ${channelId}`,
        error
      );
    }
  };

  const getChannelIdFromCategory = (category: string): number => {
    switch (category) {
      case "1":
        return 1; // Digital Channels
      case "2":
        return 2; // Offline Channels
      case "3":
        return 3;
      case "4":
        return 4;
      case "5":
        return 5;
      case "6":
        return 6;
      case "7":
        return 7; // Direct Communication Channels
      default:
        return 0; // Default or invalid category
    }
  };

  interface EmployeeOption {
    value: string;
    label: string;
  }

  interface HandleEmployeeChange {
    (campaignIndex: number, selectedOptions: MultiValue<EmployeeOption>): void;
  }

  const handleEmployeeChange: HandleEmployeeChange = (
    campaignIndex,
    selectedOptions
  ) => {
    const updatedCampaigns = [...campaigns];
    const employeeValues = selectedOptions.map((option) => option.value);
    updatedCampaigns[campaignIndex].assignedEmployees = employeeValues;
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (selectedOptions.length > 0) {
      if (!updatedErrors[campaignIndex]) updatedErrors[campaignIndex] = {};
      updatedErrors[campaignIndex].assignedEmployees = false;
    }
    setChannelErrors(updatedErrors);
  };

  const handleAddChannelType = async (campaignIndex: number) => {
    const updatedCampaigns = [...campaigns];
    const newChannelIndex = updatedCampaigns[campaignIndex].channelTypes.length;

    // Add new empty channel type and subtype
    updatedCampaigns[campaignIndex].channelTypes.push("");
    updatedCampaigns[campaignIndex].channelSubTypes.push([]);

    setCampaigns(updatedCampaigns);

    // Fetch options for the new channel type
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    const channelId = updatedCampaigns[campaignIndex].channelIds[0]; // Use first channelId as reference
    if (channelId) {
      try {
        const response = await apiDefinitions.getChannelTypes(token, channelId);
        const fetchedOptions: OptionType[] = response.data.data.map(
          (item: any) => ({
            value: item.id.toString(),
            label: item.name,
          })
        );

        // Update the channelTypeOptions dynamically
        setChannelTypeOptions((prevOptions) => ({
          ...prevOptions,
          [campaignIndex]: {
            ...(prevOptions[campaignIndex] || {}),
            [newChannelIndex]: fetchedOptions,
          },
        }));
      } catch (error) {
        console.error(
          `Failed to fetch channel types for channelId ${channelId}`,
          error
        );
      }
    }
  };

  const handleChannelTypeChange = async (
    campaignIndex: number,
    channelIndex: number,
    value: string
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].channelTypes[channelIndex] = value;
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (value) {
      if (!updatedErrors[campaignIndex]) updatedErrors[campaignIndex] = {};
      if (!updatedErrors[campaignIndex].channelTypes)
        updatedErrors[campaignIndex].channelTypes = {};
      updatedErrors[campaignIndex].channelTypes[channelIndex] = false;
    }
    setChannelErrors(updatedErrors);

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      // Fetch subtypes based on the selected channel type
      const response = await apiDefinitions.getChannelSubTypes(token, value);
      const fetchedSubTypes: OptionType[] = response.data.data.map(
        (item: any) => ({
          value: item.id.toString(),
          label: item.name,
        })
      );

      // Update the subType options dynamically
      setChannelSubTypeOptions((prevOptions) => ({
        ...prevOptions,
        [campaignIndex]: {
          ...(prevOptions[campaignIndex] || {}),
          [channelIndex]: fetchedSubTypes,
        },
      }));
    } catch (error) {
      console.error(
        `Failed to fetch channel subtypes for type ${value}`,
        error
      );
    }
  };

  const handleChannelSubTypeChange = (
    campaignIndex: number,
    channelIndex: number,
    selectedOptions: MultiValue<{ value: string; label: string }>
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].channelSubTypes[channelIndex] =
      selectedOptions.map((option) => option.value);
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (selectedOptions.length > 0) {
      if (!updatedErrors[campaignIndex]) updatedErrors[campaignIndex] = {};
      if (!updatedErrors[campaignIndex].channelSubTypes)
        updatedErrors[campaignIndex].channelSubTypes = {};
      updatedErrors[campaignIndex].channelSubTypes[channelIndex] = false;
    }
    setChannelErrors(updatedErrors);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllCampaignTypes(token).then((response: any) => {
          console.log("Campaign Categories: ", response.data.data);
          setCategoryList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedCategory) {
          apiDefinitions
            .getCampaignSubTypesById(token, selectedCategory)
            .then((response: any) => {
              console.log("Categories Sub Types: ", response.data.data);
              setCategorySubList(response.data.data);
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getMarketingEmployeeList(token).then((response: any) => {
          console.log("Course Categories: ", response.data.data);
          setEmployeeList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getTargetAudience(token).then((response: any) => {
          console.log("Course Categories: ", response.data.data);
          setTargetAudienceList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  interface OptionType {
    value: string;
    label: string;
  }

  const handleTargetAudienceChange = (
    selectedOptions: MultiValue<OptionType>
  ) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setTargetAudience(selectedValues);
  };

  const handleCreate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();

    let isValid = true;
    console.log("Form is valid");

    // Validation logic
    if (name === "") {
      isValid = false;
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }

    if (selectedCategory === "") {
      isValid = false;
      setIsCampaignTypeValid(true);
    } else {
      setIsCampaignTypeValid(false);
    }

    if (selectedCategory && selectedSubCategory === "") {
      isValid = false;
      setIsSubCategoryValid(true);
    } else {
      setIsSubCategoryValid(false);
    }

    // if (selectedChannel === "") {
    //   isValid = false;
    //   setIsChannelValid(true);
    // } else {
    //   setIsChannelValid(false);
    // }

    if (goal === "") {
      isValid = false;
      setIsGoalValid(true);
    } else {
      setIsGoalValid(false);
    }

    if (targetAudience.length === 0) {
      isValid = false;
      toast.error("Please select target audience");
    }

    if (
      allocatedBudget === "" ||
      !/^\d+$/.test(allocatedBudget) ||
      parseInt(allocatedBudget, 10) === 0
    ) {
      isValid = false;
      setIsAllocatedBudgetValid(true);
    } else {
      setIsAllocatedBudgetValid(false);
    }

    if (
      leadCount === "" ||
      !/^\d+$/.test(leadCount) ||
      parseInt(leadCount, 10) === 0
    ) {
      isValid = false;
      setIsLeadCountValid(true);
    } else {
      setIsLeadCountValid(false);
    }

    if (description === "") {
      isValid = false;
      setIsDescriptionValid(true);
    } else {
      setIsDescriptionValid(false);
    }

    if (startDate === "") {
      isValid = false;
      setIsStartDateValid(true);
    } else {
      setIsStartDateValid(false);
    }

    if (endDate === "") {
      isValid = false;
      setIsStartEndValid(true);
    } else {
      setIsStartEndValid(false);
    }

    const updatedErrors = campaigns.map((campaign, campaignIndex) => {
      const errors: any = {};

      // Check for selected category
      if (!campaign.selectedCategory) {
        isValid = false;
        errors.selectedCategory = true;
      }

      // Check for channel types
      if (!campaign.channelTypes || campaign.channelTypes.length === 0) {
        isValid = false;
        errors.channelTypes = [true]; // Ensure this is an array for consistency
      } else {
        errors.channelTypes = campaign.channelTypes.map((type) => !type);
      }

      // Check for channel subtypes
      errors.channelSubTypes = [];

      // Check for assigned employees
      if (
        !campaign.assignedEmployees ||
        campaign.assignedEmployees.length === 0
      ) {
        isValid = false;
        errors.assignedEmployees = true;
      }

      // Validate the arrays for errors
      if (
        Array.isArray(errors.channelTypes) &&
        errors.channelTypes.some((err: boolean) => err)
      ) {
        isValid = false;
      }

      if (
        Array.isArray(errors.channelSubTypes) &&
        errors.channelSubTypes.some((err: boolean) => err)
      ) {
        isValid = false;
      }

      return errors;
    });

    setChannelErrors(updatedErrors);

    const channels = campaigns.map((campaign) => ({
      id: parseInt(campaign.selectedCategory), // Ensure ID is a number
      people: campaign.assignedEmployees.map((emp) => ({
        serial_number: parseInt(emp), // Ensure employee IDs are numbers
      })),
      channelTypes: campaign.channelTypes.map((type, index) => ({
        id: parseInt(type), // Ensure ID is a number
        channelSubTypes: (campaign.channelSubTypes[index] || []).map(
          (subType) => ({
            id: parseInt(subType), // Ensure ID is a number
          })
        ),
      })),
    }));

    const payload = {
      name: name,
      description: description,
      allocatedBudget: parseFloat(allocatedBudget), // Ensure it's a number
      goal: goal,
      leadCount: parseInt(leadCount), // Ensure it's a number
      status: "active",
      startDate: startDate,
      endDate: endDate,
      createdBy: employeeId.toString(), // Convert to string if needed
      channels: channels,
      campaigns_types: { id: parseInt(selectedCategory) }, // Ensure ID is a number
      campaign_sub_types: { id: parseInt(selectedSubCategory) }, // Ensure ID is a number
      targetAudience: targetAudience.map((audience) => ({
        id: parseInt(audience),
      })),
    };

    console.log(payload);

    console.log("payload", payload);
    console.log("payload", isValid);

    if (isValid) {
      console.log("Form is valid");
      Swal.fire({
        title: "Do You Want To Save The Changes?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }
          const loading = toast.loading("Adding Campaign...");

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              apiDefinitions
                .createCampaign(token, employeeId, payload)
                .then((response: any) => {
                  if (response.data.code === 201) {
                    toast.dismiss(loading);
                    console.log("Campaign Added Successfully");
                    toast.success("Campaign Added Successfully");
                    refreshCampaigns();
                    refreshWindow();
                    // window.location.reload();
                    // const modal = document.getElementById("offcanvas_add");
                    // if (modal) {
                    //   modal.classList.remove("show");
                    //   modal.style.display = "none";
                    //   document.body.classList.remove("offcanvas-open");
                    //   const backdrop = document.querySelector(
                    //     ".offcanvas-backdrop"
                    //   );
                    //   if (backdrop) {
                    //     backdrop.remove();
                    //   }
                    // }
                  } else if (response.data.code === 409) {
                    toast.error("Campaign Already Exists");
                    toast.dismiss(loading);
                  } else {
                    toast.error("Failed to add campaign");
                    toast.dismiss(loading);
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
              toast.dismiss(loading);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          toast("Changes Were Not Saved", { icon: "⚠️" });
        }
      });
    }
  };

  interface HandleRemoveChannelType {
    (campaignIndex: number, channelIndex: number): void;
  }

  const handleRemoveChannelType: HandleRemoveChannelType = (
    campaignIndex,
    channelIndex
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].channelTypes.splice(channelIndex, 1);
    updatedCampaigns[campaignIndex].channelSubTypes.splice(channelIndex, 1);
    setCampaigns(updatedCampaigns);
  };

  // Add a function to handle removing channels
  interface HandleRemoveChannel {
    (campaignIndex: number): void;
  }

  const handleRemoveChannel: HandleRemoveChannel = (campaignIndex) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns.splice(campaignIndex, 1);
    setCampaigns(updatedCampaigns);
  };

  return (
    <>
      {/* Add New Campaign */}
      <div
        className="offcanvas offcanvas-end offcanvas-large"
        tabIndex={-1}
        id="offcanvas_add"
      >
        <div className="offcanvas-header border-bottom">
          <h4>Add New Campaign</h4>
          <button
            type="button"
            className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="ti ti-x" />
          </button>
        </div>
        <div className="offcanvas-body">
          <form>
            <div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="col-form-label">
                    Campaign Name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={`form-control ${
                        isNameValid ? "is-invalid" : ""
                      }`}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Campaign Name"
                    />
                    {isNameValid && (
                      <div className="invalid-feedback">
                        Please enter the campaign name.
                      </div>
                    )}
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3 mt-3">
                      <label
                        className="form-label"
                        htmlFor="validationCustomContactMethod"
                      >
                        Campaign Type&nbsp;
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        className={`form-select ${
                          isCampaignTypeValid ? "is-invalid" : ""
                        }`}
                        id="validationCustomContactMethod"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                        required
                      >
                        <option>Select an option</option>
                        {categoryList.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                      {isCampaignTypeValid && (
                        <div className="invalid-feedback">
                          Please select a campaign type.
                        </div>
                      )}
                    </div>

                    {selectedCategory && (
                      <div className="col-md-6 mb-3 mt-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustomContactMethod"
                        >
                          Campaign Sub-Type&nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className={`form-select ${
                            isSubCategoryValid ? "is-invalid" : ""
                          }`}
                          id="validationCustomContactMethod"
                          value={selectedSubCategory}
                          onChange={(e) =>
                            setSelectedSubCategory(e.target.value)
                          }
                          required
                        >
                          <option>Select an option</option>
                          {categorySubList.map((subtype) => (
                            <option key={subtype.id} value={subtype.id}>
                              {subtype.name}
                            </option>
                          ))}
                        </select>
                        {isSubCategoryValid && (
                          <div className="invalid-feedback">
                            Please select a campaign sub-type.
                          </div>
                        )}
                      </div>
                    )}

                    <div className="row">
                      <div className="divider"></div>

                      <div className="row">
                        <div className="col-md-12 mb-3 mt-3">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={addCampaignType}
                          >
                            <i className="fa fa-plus"></i>&nbsp; Add Channel
                          </button>
                        </div>

                        {campaigns.map(
                          (campaign: CampaignType, index: number) => (
                            <div key={campaign.id} className="campaign-section">
                              <div className="row">
                                <div className="col-md-6 mb-3 mt-3">
                                  <label
                                    className="form-label"
                                    htmlFor={`validationCustomContactMethod${campaign.id}`}
                                  >
                                    Channel&nbsp;
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className={`form-select ${
                                      channelErrors[index]?.assignedEmployees
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id={`validationCustomContactMethod${campaign.id}`}
                                    value={campaign.selectedCategory}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLSelectElement>
                                    ) =>
                                      handleCategoryChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    required
                                  >
                                    <option value="">Select an option</option>
                                    <option value="1">Digital Channels</option>
                                    <option value="2">Offline Channels</option>
                                    <option value="3">
                                      Direct Communication Channels
                                    </option>
                                    <option value="4">
                                      Events and Networking Channels
                                    </option>
                                    <option value="5">
                                      Partner or Collaborative Channels
                                    </option>
                                    <option value="6">
                                      Digital Engagement Channels
                                    </option>
                                    <option value="7">
                                      Internal Communication Channels
                                    </option>
                                  </select>
                                  {channelErrors[index]?.selectedCategory && (
                                    <div className="invalid-feedback">
                                      Please select a channel.
                                    </div>
                                  )}
                                </div>

                                <div className="col-md-6 mb-3 mt-3">
                                  <label
                                    className="form-label"
                                    htmlFor={`assignedBy${campaign.id}`}
                                  >
                                    Assigned To
                                  </label>
                                  <Select
                                    classNamePrefix="react-select"
                                    className="js-example-placeholder-multiple select2 js-states"
                                    isMulti={true}
                                    placeholder="Select"
                                    options={options}
                                    value={options.filter((option: Option) =>
                                      campaign.assignedEmployees.includes(
                                        option.value
                                      )
                                    )}
                                    onChange={(
                                      selectedOptions: MultiValue<Option>
                                    ) =>
                                      handleEmployeeChange(
                                        index,
                                        selectedOptions
                                      )
                                    }
                                    styles={{
                                      control: (base, state) => ({
                                        ...base,
                                        minHeight: "40px",
                                        height: "auto",
                                      }),
                                      valueContainer: (base) => ({
                                        ...base,
                                        padding: "5px 10px",
                                        flexWrap: "wrap",
                                      }),
                                      multiValue: (base) => ({
                                        ...base,
                                        margin: "2px",
                                      }),
                                    }}
                                  />
                                  {channelErrors[index]?.assignedEmployees && (
                                    <div className="text-danger">
                                      Please assign at least one employee.
                                    </div>
                                  )}
                                </div>

                                <div className="col-md-1 ms-2">
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    onClick={() => handleRemoveChannel(index)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>

                                <div className="col-md-12 mb-3 mt-3">
                                  <button
                                    className="btn btn-secondary"
                                    type="button"
                                    onClick={() => handleAddChannelType(index)}
                                  >
                                    <i className="fa fa-plus"></i>&nbsp; Add
                                    Channel Type
                                  </button>
                                </div>

                                <div className="row mb-3 mt-3">
                                  {campaign.channelTypes.map(
                                    (channelType, channelIndex) => (
                                      <div
                                        key={channelIndex}
                                        className="d-flex align-items-start mb-3"
                                      >
                                        {/* Channel Type Field */}
                                        <div className="col-md-6 pe-2">
                                          <label
                                            className="form-label"
                                            htmlFor={`channelType${campaign.id}-${channelIndex}`}
                                          >
                                            Channel Type
                                          </label>
                                          <select
                                            className={`form-select ${
                                              channelErrors[index]
                                                ?.channelTypes?.[channelIndex]
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            id={`channelType${campaign.id}-${channelIndex}`}
                                            value={channelType}
                                            onChange={(
                                              e: React.ChangeEvent<HTMLSelectElement>
                                            ) =>
                                              handleChannelTypeChange(
                                                index,
                                                channelIndex,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              Select a Channel Type
                                            </option>
                                            {channelTypeOptions[index]?.[
                                              channelIndex
                                            ]?.map((option) => (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                          </select>
                                          {channelErrors[index]?.channelTypes?.[
                                            channelIndex
                                          ] && (
                                            <div className="invalid-feedback">
                                              Please select a channel type.
                                            </div>
                                          )}
                                        </div>

                                        {/* Channel Sub Type Field */}
                                        <div className="col-md-6 ps-2">
                                          <label
                                            className="form-label"
                                            htmlFor={`channelSubType${campaign.id}-${channelIndex}`}
                                          >
                                            Channel Sub-Type
                                          </label>
                                          <Select
                                            classNamePrefix="react-select"
                                            isMulti
                                            placeholder="Select Subtypes"
                                            options={
                                              channelSubTypeOptions[index]?.[
                                                channelIndex
                                              ] || []
                                            }
                                            value={
                                              channelSubTypeOptions[index]?.[
                                                channelIndex
                                              ]?.filter((option) =>
                                                campaign.channelSubTypes[
                                                  channelIndex
                                                ]?.includes(option.value)
                                              ) || []
                                            }
                                            onChange={(selectedOptions) =>
                                              handleChannelSubTypeChange(
                                                index,
                                                channelIndex,
                                                selectedOptions
                                              )
                                            }
                                            styles={{
                                              control: (base) => ({
                                                ...base,
                                                minHeight: "40px",
                                              }),
                                              valueContainer: (base) => ({
                                                ...base,
                                                padding: "5px 10px",
                                              }),
                                              multiValue: (base) => ({
                                                ...base,
                                                margin: "2px",
                                              }),
                                            }}
                                          />
                                        </div>

                                        <div className="col-md-2 ms-2 mt-1">
                                          <button
                                            className="btn btn-secondary mt-4"
                                            type="button"
                                            onClick={() =>
                                              handleRemoveChannelType(
                                                index,
                                                channelIndex
                                              )
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <div className="divider"></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="form-label" htmlFor="validationCustom01">
                    Campaign Goal&nbsp;<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={`form-control ${
                        isGoalValid ? "is-invalid" : ""
                      }`}
                      id="validationCustom01"
                      placeholder="Campaign Goal"
                      value={goal}
                      onChange={(e) => setGoal(e.target.value)}
                      required
                    />
                    {isGoalValid && (
                      <div className="invalid-feedback">
                        Please enter the campaign goal.
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Target Audience&nbsp;
                      <span className="text-danger"> *</span>
                    </label>
                    <Select
                      isMulti
                      name="colors"
                      options={targetAudiences}
                      className="select"
                      onChange={handleTargetAudienceChange}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          minHeight: "40px",
                          height: "auto",
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          padding: "5px 10px",
                          flexWrap: "wrap",
                        }),
                        multiValue: (base) => ({
                          ...base,
                          margin: "2px",
                        }),
                      }}
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">
                        Est. Allocated Budget{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control ${
                            isAllocatedBudgetValid ? "is-invalid" : ""
                          }`}
                          onChange={(e) => setAllocatedBudget(e.target.value)}
                          placeholder="Est. Allocated Budget"
                        />
                        {isAllocatedBudgetValid && (
                          <div className="invalid-feedback">
                            Please enter a valid estimated allocated budget.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">
                        Expected Lead Count{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control ${
                            isLeadCountValid ? "is-invalid" : ""
                          }`}
                          onChange={(e) => setLeadCount(e.target.value)}
                          placeholder="Expected Lead Count"
                        />
                        {isLeadCountValid && (
                          <div className="invalid-feedback">
                            Please enter a valid expected lead count.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label" htmlFor="validationCustom01">
                      Description&nbsp;
                      <span className="text-danger">*</span>
                    </label>

                    <div className="input-group">
                      <textarea
                        rows={4}
                        className={`form-control ${
                          isDescriptionValid ? "is-invalid" : ""
                        }`}
                        id="validationCustom01"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                      {isDescriptionValid && (
                        <div className="invalid-feedback">
                          Please enter the description.
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-md-6 mt-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustomFollowUpDate"
                        >
                          Campaign Start Date&nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className={`form-control ${
                            isStartDateValid ? "is-invalid" : ""
                          }`}
                          id="validationCustomFollowUpDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          // min={new Date().toISOString().split("T")[0]}
                          min="2025-01-01"
                          required
                        />
                        {isStartDateValid && (
                          <div className="invalid-feedback">
                            Please select a start date.
                          </div>
                        )}
                      </div>

                      <div className="col-md-6 mt-3">
                        <label
                          className="form-label"
                          htmlFor="validationCustomFollowUpDate"
                        >
                          Campaign End Date&nbsp;
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className={`form-control ${
                            isStartEndValid ? "is-invalid" : ""
                          }`}
                          id="validationCustomFollowUpDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required
                          disabled={startDate === ""}
                          min={startDate}
                        />
                        {isStartEndValid && (
                          <div className="invalid-feedback">
                            Please select an end date.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <button
                type="button"
                data-bs-dismiss="offcanvas"
                className="btn btn-light me-2"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => handleCreate(e)}
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CampaignModal;
