export const proposallistData = [
  {
    id: "1",
    proposalsID: "#1493024",
    subject: "SEO Proposal",
    sendTo: "NovaWave LLC",
    pro_img: "assets/img/priority/truellysel.svg",
    client_img: "assets/img/icons/company-icon-01.svg",
    totalValue: "$2,05,426",
    Date: "15 May 2024",
    openTill: "15 Aug 2023",
    Project: "Truelysell",
    createdDate: "21 May 2024",
    status: "Accepted",
    key: "1"
  },

  {
    id: "2",
    proposalsID: "#1493023",
    subject: "Web Design",
    sendTo: "Redwood Inc",
    pro_img: "assets/img/priority/dreamchat.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-02.svg",

    totalValue: "$2,105",
    Date: "16 Jan 2024",
    openTill: "15 Sep 2024",
    Project: "Dreamsports",
    createdDate: "15 Apr 2024",
    status: "Declined",
    key: "2"
  },
  {
    id: "3",
    proposalsID: "#1493022",
    subject: "Logo & Branding",
    sendTo: "HarborView",
    pro_img: "assets/img/priority/truellysell.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-03.svg",
    totalValue: "$4,05,656",
    Date: "17 Sep 2024",
    openTill: "15 Nov 2024",
    Project: 'Best"@"laundry',
    createdDate: "12 Mar 2024",
    status: "Deleted",
    key: "3"
  },
  {
    id: "4",
    proposalsID: "#1493021",
    subject: "Development",
    sendTo: "CoastalStar Co.",
    pro_img: "assets/img/priority/servbook.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-04.svg",
    totalValue: "$2,05,426",
    Date: "18 May 2024",
    openTill: "15 Jun 2024",
    Project: "Doccure",
    createdDate: "15 Feb 2024",
    status: "Draft",
    key: "4"
  },
  {
    id: "5",
    proposalsID: "#1493020",
    subject: "SEO Proposal",
    sendTo: "RiverStone Ventur",
    pro_img: "assets/img/priority/dream-pos.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-05.svg",
    totalValue: "$3,15,145",
    Date: "19 Aug 2024",
    openTill: "15 Oct 2024",
    Project: "Kofejob",
    createdDate: "15 Jan 2024",
    status: "Sent",
    key: "5"
  },
  {
    id: "6",
    proposalsID: "#1493019",
    subject: "Web Design",
    sendTo: "Summit Peak",
    pro_img: "assets/img/priority/project-01.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-06.svg",
    totalValue: "$6,154",
    Date: "20 May 2024",
    openTill: "08 Aug 2024",
    Project: "DreamPOS",
    createdDate: "15 Dec 2023",
    status: "Draft",
    key: "6"
  },
  {
    id: "7",
    proposalsID: "#1493018",
    subject: "Logo",
    sendTo: "Silver Hawk",
    pro_img: "assets/img/priority/project-02.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-07.svg",
    totalValue: "$1,457",
    Date: "22 Aug 2024",
    openTill: "25 Jan 2025",
    Project: "Servbook",
    createdDate: "15 Nov 2023",
    status: "Paused",
    key: "7"
  },

  {
    id: "8",
    proposalsID: "#1493017",
    subject: "Branding",
    sendTo: "BlueSky Industries",
    pro_img: "assets/img/priority/best.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-08.svg",
    totalValue: "$2,01,464",
    Date: "15 May 2024",
    openTill: "12 Aug 2024",
    Project: "Truelysell",
    createdDate: "15 Sep 2023",
    status: "Accepted",
    key: "8"
  },
  {
    id: "9",
    proposalsID: "#1493018",
    subject: "Development",
    sendTo: "Golden Gate Ltd",
    pro_img: "assets/img/priority/dream-pos.svg",
    send_img: "assets/img/icons/company-icon-01.svg",
    client_img: "assets/img/icons/company-icon-06.svg",
    totalValue: "$1,10,145",
    Date: "14 Aug 2024",
    openTill: "07 Dec 2024",
    Project: "Dreamschat",
    createdDate: "15 Aug 2023",
    status: "Declined",
    key: "9"
  },
];
