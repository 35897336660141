import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../../core/common/dataTable/index";
import apiDefinitions from "../../../../api/apiDefinitions";

import { all_routes } from "../../../router/all_routes";
import CollapseHeader from "../../../../core/common/collapse-header";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

const CampaignSubTypes = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [fieldErrors, setFieldErrors] = useState<any>({
    name: "",
    editCampaignTypeName: "",
  });
  const [initalErrorState, setInitalErrorState] = useState(false);
  const [rowDataID, setRowDataID] = useState<any>({});
  const [editCampaignSubTypeName, setEditCampaignSubTypeName] = useState(""); 
  const hasListLoaded = useRef(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [campaignSubTypes, setCampaignSubTypes] = useState("");
  

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date in YYYY-MM-DD format
  };

  interface Campaign {
    id: string;
    year: string;
    targetAudience: string;
    requestBudget: string;
    leadCount: number;
    status: string;
    startDate: string;
    endDate: string;
  }

  interface Column {
    title: string;
    dataIndex: string;
    render?: (text: any, record: Campaign) => JSX.Element;
    width?: number;
  }

  const columns: Column[] = [
    {
      title: "ID",
      dataIndex: "year",
    },
    {
      title: "Campaign Sub Type",
      dataIndex: "requestBudget",
    },
    {
      title: "Created Date",
      dataIndex: "startDate",
      width: 300,
      render: (startDate: string) => <span>{formatDate(startDate)}</span>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: 200,
      render: (_, record: Campaign) => (
        <div className="action-icons d-flex gap-2">
         <Link
            to="#"
            className="text-warning"
            title="Edit Budget"
            data-bs-toggle="modal"
            data-bs-target="#con-close-modal-edit"
            onClick={() => {
              setRowDataID(record.id);
              setEditCampaignSubTypeName(record.requestBudget);
            }}
          >
            <i
              className="ti ti-edit"
              style={{ fontSize: "1.8rem", marginLeft: "10px" }}
            ></i>
          </Link>

          {/* Delete Icon */}
          <span
            className="text-danger"
            title="Delete Budget"
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(record.id)}
          >
            <i
              className="ti ti-trash"
              style={{ fontSize: "1.8rem", marginLeft: "10px" }}
            ></i>
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    refreshCampaigns();
  }, []);

  const refreshCampaigns = () => {
    if (!hasListLoaded.current) {
      hasListLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions.getAllPendingBudgets(token, 2).then((response: any) => {
          if (response.data.code === 200) {
            toast.dismiss(toastId);
            setCampaignList(response.data.data);
          } else {
            console.error("Failed to load campaigns", response.data.message);
            toast.dismiss(toastId);
          }
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  const handleSubmit = () => {
    if (fieldErrors.name === "") {
      // Collect all form data
      const formData = new FormData();

      const payload = {
        name: campaignSubTypes,
      };

      Swal.fire({
        title: "Are you sure do you want to add new campaign sub type?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Adding Campaign sub Type...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .createBudget(token, payload)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Sub Type added Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to add campaign sub type");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  const handleEdit = () => {
    if (fieldErrors.editCampaignTypeName === "") {
      // Collect all form data

      const payload = {
        id: rowDataID,
        name: editCampaignSubTypeName,
      };

      Swal.fire({
        title: "Are you sure do you want to edit campaign sub type?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);
            console.log("Token", token);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const toastId = toast.loading("Adding Campaign Sub Type...");
              // Token is valid, proceed with the API call
              apiDefinitions
                .createBudget(token, payload)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Sub Type edited Successfully", {
                      duration: 5000,
                    });
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to edit campaign sub type");
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        }
      });
    } else {
      console.error(
        "Form validation failed. Please check the form for errors."
      );
    }
  };

  const handleDelete = (id: any) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        apiDefinitions.deleteBudget(token, id).then((response) => {
          if (response.data.code === 200) {
            toast.success("Sub Type Deleted Successfully");
            refreshCampaigns();
          } else {
            toast.error("Failed to Delete Sub Type");
          }
        });
      }
    });
  };

  const handleClear = () => {
    setCampaignSubTypes("");
    setInitalErrorState(false);
    // Clear errors
    setFieldErrors({ name: "", editCampaignTypeName: "" });
  };

  const handleClearEdit = () => {
    setEditCampaignSubTypeName("");
    setInitalErrorState(false);
    // Clear errors
    setFieldErrors({ name: "",editCampaignTypeName: "" });
  }

  useEffect(() => {
    if (initalErrorState) {
      console.log(campaignSubTypes);
      if (campaignSubTypes === "") {
        handleErrors({ name: "Campaign Sub Type Name is required" });
      } else {
        handleErrors({ name: "" });
      }
    } else setInitalErrorState(true);
  }, [campaignSubTypes]);

  useEffect(() => {
    if (initalErrorState) {
      if (editCampaignSubTypeName === "") {
        handleErrors({ editCampaignTypeName: "Campaign Sub Type Name is required" });
      } else {
        handleErrors({ editCampaignTypeName: "" });
      }
    } else {
      handleErrors({ editCampaignTypeName: "" });
    }
  }, [editCampaignSubTypeName]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Campaign Sub Types</h4>
                  </div>
                  <div className="col-md-8 float-end ms-auto">
                    <div className="d-flex title-head">
                      {/* Back Button */}
                      <div className="col-2 text-end me-3">
                        {" "}
                        {/* Added 'me-3' for spacing */}
                        <Link
                          to="/crmSetting/campaignChannels"
                          className="btn btn-primary"
                        >
                          <i
                            className="feather-arrow-left"
                            style={{ fontSize: "17px", marginRight: "5px" }}
                          />
                          Back to list
                        </Link>
                      </div>
                      <div className="daterange-picker d-flex align-items-center justify-content-center">
                        <div className="head-icons mb-0">
                          <CollapseHeader />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Campaign Tab */}
              <div className="card">
                <div className="card-header">
                  {/* Search */}
                  <div className="row align-items-center">
                    <div className="row align-items-center justify-content-between">
                      {/* Search Bar aligned to the Left */}
                      <div className="col-sm-6">
                        {/* <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Campaign"
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                        </div> */}
                      </div>

                                            {/* add New Type Dialog box */}
                                            <div
                        className="modal fade"
                        id="con-close-modal"
                        tabIndex={-1}
                        aria-labelledby="con-close-modal-label"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="con-close-modal-label"
                              >
                                Add New Campaign Type
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body p-4">
                              <div className="row">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="field-2"
                                    className="form-label"
                                  >
                                    Campaign Sub Type Name
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder="Enter Campaign Sub Type Name"
                                      aria-describedby="basic-addon1"
                                      value={campaignSubTypes}
                                      onChange={(e) =>
                                        setCampaignSubTypes(e.target.value)
                                      }
                                    />
                                  </div>
                                  {fieldErrors.name !== "" ? (
                                    <div className="text-danger">
                                      {fieldErrors.name}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-cancel waves-effect"
                                data-bs-dismiss="modal"
                                onClick={handleClear}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleSubmit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Edit Dialog box */}
                      <div
                        className="modal fade"
                        id="con-close-modal-edit"
                        tabIndex={-1}
                        aria-labelledby="con-close-modal-label"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="con-close-modal-label"
                              >
                                Edit Campaign Sub Type
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body p-4">
                              <div className="row">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="field-2"
                                    className="form-label"
                                  >
                                    Campaign Sub Type Name
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder="Enter Campaign Sub Type Name"
                                      aria-describedby="basic-addon1"
                                      value={editCampaignSubTypeName}
                                      onChange={(e) =>
                                        setEditCampaignSubTypeName(e.target.value)
                                      }
                                    />
                                  </div>
                                  {fieldErrors.editCampaignTypeName !== "" ? (
                                    <div className="text-danger">
                                      {fieldErrors.editCampaignTypeName}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-cancel waves-effect"
                                data-bs-dismiss="modal"
                                onClick={handleClearEdit}
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={handleEdit}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-3 text-end">
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light mt-1 me-1"
                          data-bs-toggle="modal"
                          data-bs-target="#con-close-modal"
                          onClick={() => setCheckValidation(!checkValidation)}
                        >
                          Add Campaign Sub Type
                        </button>
                      </div>
                      {/* Button aligned to the Right */}
                    </div>

                    <div className="row">
                      <div className="col-xl-12 mt-3">
                        <div className="table-responsive custom-table">
                          <Table dataSource={campaignList} columns={columns} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignSubTypes;
