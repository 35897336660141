export const dealsData = [
  {
    id: 1,
    dealName: "Collins",
    stage: "Qualify To Buy",
    dealValue: "$04,51,000",
    tag1: "Collab",
    closeDate: "25 Sep 2023",
    crearedDate: "25 Sep 2023, 01:22 pm",
    owner: "Hendry",
    source: "Paid Social",
    probability: "90%",
    status: "Won",
    key: "1"
  },
  {
    id: 2,
    dealName: "Konopelski",
    stage: "Proposal Made",
    dealValue: "$03,12,500",
    tag1: "Rated",
    closeDate: "29 Sep 2023",
    crearedDate: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    probability: "15 %",
    status: "Lost",
    key: "2"
  },
  {
    id: 3,
    dealName: "Adams",
    stage: "Contact Made",
    dealValue: "$04,14,800",
    tag1: "Rejected",
    closeDate: "04 Oct 2023",
    crearedDate: "04 Oct 2023, 10:18 am",
    owner: "Jami",
    source: "Campaigns",
    probability: "95 %",
    status: "Won",
    key: "3"
  },
  {
    id: 4,
    dealName: "Schumm",
    stage: "Qualify To Buy",
    dealValue: "$11,14,400",
    tag1: "Collab",
    closeDate: "15 Oct 2023",
    crearedDate: "17 Oct 2023, 03:31 pm",
    owner: "Theresa",
    source: "Google",
    probability: "99 %",
    status: "Won",
    key: "4"
  },
  {
    id: 5,
    dealName: "Wisozk",
    stage: "Presentation",
    dealValue: "$16,11,400",
    tag1: "Rated",
    closeDate: "27 Oct 2023",
    crearedDate: "24 Oct 2023, 09:14 pm",
    owner: "Espinosa",
    source: "Paid Social",
    probability: "10 %",
    status: "Open",
    key: "5"
  },
  {
    id: 6,
    dealName: "Heller",
    stage: "Appointment",
    dealValue: "$78,11,800",
    tag1: "Rated",
    closeDate: "07 Nov 2023",
    crearedDate: "08 Nov 2023, 09:56 am",
    owner: "Martin",
    source: "Referrals",
    probability: "70 %",
    status: "Won",
    key: "6"
  },
  {
    id: 7,
    dealName: "Gutkowski",
    stage: "Proposal Made",
    dealValue: "$09,05,947",
    tag1: "Promotion",
    closeDate: "12 Nov 2023",
    crearedDate: "14 Nov 2023, 04:19 pm",
    owner: "Newell",
    source: "Campaigns",
    probability: "10 %",
    status: "Open",
    key: "7"
  },
  {
    id: 8,
    dealName: "Walter",
    stage: "Qualify To Buy",
    dealValue: "$04,51,000",
    tag1: "Rejected",
    closeDate: "23 Nov 2023",
    crearedDate: "23 Nov 2023, 11:14 pm",
    owner: "Janet",
    source: "Google",
    probability: "90 %",
    status: "Won",
    key: "8"
  },
  {
    id: 9,
    dealName: "Hansen",
    stage: "Appointment",
    dealValue: "$72,14,078",
    tag1: "Collab",
    closeDate: "11 Dec 2023",
    crearedDate: "10 Dec 2023, 06:43 am",
    owner: "Craig",
    source: "Paid Social",
    probability: "40 %",
    status: "Won",
    key: "9"
  },
  {
    id: 10,
    dealName: "Leuschke",
    stage: "Proposal Made",
    dealValue: "$09,05,947",
    tag1: "Rated",
    closeDate: "17 Dec 2023",
    crearedDate: "25 Dec 2023, 08:17 pm",
    owner: "Daniel",
    source: "Referrals",
    probability: "47 %",
    status: "Lost",
    key: "10"
  },
];
