import React, { useEffect, useState } from "react";
import apiDefinitions from "../../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import { all_routes } from "../../../router/all_routes";
import Select, { MultiValue } from "react-select";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const UpdateCampaign = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  interface Category {
    id: number;
    name: string;
  }

  interface OptionType {
    value: string;
    label: string;
  }

  interface Option {
    value: string;
    label: string;
  }

  interface TargetAudience {
    value: string;
    label: string;
  }

  interface Channel {
    id: number;
    selectedChannel: string;
    assignedBy: string[];
    channelTypes: {
      id: number;
      name: string;
      channelSubTypes: {
        id: number;
        name: string;
      }[];
    }[];
  }

  const route = all_routes;
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [goal, setGoal] = useState("");
  interface ChannelError {
    selectedCategory?: boolean;
    [key: string]: any;
  }
  const [channelErrors, setChannelErrors] = useState<ChannelError[]>([]);
  interface FormattedTargetAudience {
    label: string;
    value: string;
  }

  const [targetAudience, setTargetAudience] = useState<
    FormattedTargetAudience[]
  >([]);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [leadCount, setLeadCount] = useState("");
  const [isDigitalChecked, setIsDigitalChecked] = useState(false);
  const [isOfflineChecked, setIsOfflineChecked] = useState(false);

  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [categorySubList, setCategorySubList] = useState<Category[]>([]);
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const [targetAudienceList, setTargetAudienceList] = useState<any[]>([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState<any[]>([]);
  const [campaignTypeAdded, setCampaignTypeAdded] = useState(false);
  const [allocatedBudget, setAllocatedBudget] = useState("");
  const { id } = useParams<{ id: string }>();

  //Error Handling
  const [isNameValid, setIsNameValid] = useState(false);
  const [isCampaignTypeValid, setIsCampaignTypeValid] = useState(false);
  const [isDescriptionValid, setIsDescriptionValid] = useState(false);
  const [isGoalValid, setIsGoalValid] = useState(false);
  const [isTargetAudienceValid, setIsTargetAudienceValid] = useState(false);
  const [isStatusValid, setIsStatusValid] = useState(false);
  const [isStartDateValid, setIsStartDateValid] = useState(false);
  const [isStartEndValid, setIsStartEndValid] = useState(false);
  const [isChannelValid, setIsChannelValid] = useState(false);
  const [isSubCategoryValid, setIsSubCategoryValid] = useState(false);
  const [isLeadCountValid, setIsLeadCountValid] = useState(false);
  const [campaignCategory, setCampaignCategory] = useState("");
  const [isAllocatedBudgetValid, setIsAllocatedBudgetValid] = useState(false);

  const [campaignData, setCampaignData] = useState<any>(null);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [channelTypeOptions, setChannelTypeOptions] = useState<{
    [campaignIndex: number]: {
      [channelIndex: number]: { value: string; label: string }[];
    };
  }>({});
  const [channelSubTypeOptions, setChannelSubTypeOptions] = useState<{
    [campaignIndex: number]: {
      [channelIndex: number]: { value: string; label: string }[];
    };
  }>({});

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === "logout") {
        // Perform the logout action for this tab
        alert("You have been logged out in another tab!");
        window.location.href = "/login"; // Redirect to the login page
      }
    });

    const fetchCampaignDetails = async () => {
      const token = sessionStorage.getItem("token");
      // const campaignId = 40;

      try {
        const response = await apiDefinitions.getCampaignDetailsById(token, id);
        const { data } = response.data;

        console.log("Campaign Details:", data); // Debug log for API response

        // Set individual campaign details
        setName(data.name);
        setSelectedCategory(data.campaigns_types[0].id);
        setGoal(data.goal);
        setAllocatedBudget(data.allocatedBudget);
        setLeadCount(data.leadCount);
        setDescription(data.description);
        setStartDate(data.startDate);
        setEndDate(data.endDate);

        // Format target audience
        const formattedTargetAudiences = data.targetAudience.map(
          (audience: any) => ({
            label: audience.name,
            value: audience.id.toString(),
          })
        );
        setTargetAudience(formattedTargetAudiences);

        // Map and set channel data
        const mappedChannels: Channel[] = data.channels.map(
          (channel: ApiChannel) => ({
            id: channel.id,
            name: channel.name, // Ensure channel name is mapped correctly
            selectedChannel: channel.name, // Use the channel's name for selectedChannel
            assignedBy:
              channel.people.map((person: ApiPerson) => person.serial_number) ||
              [],
            channelTypes:
              channel.channelTypes.map((type: ApiChannelType) => ({
                id: type.id,
                name: type.name,
                channelSubTypes: type.channelSubTypes.map(
                  (subType: ApiChannelSubType) => ({
                    id: subType.id,
                    name: subType.name,
                  })
                ),
              })) || [],
          })
        );

        console.log("Mapped Channels:", mappedChannels); // Debug log for mapped channels

        // Update the campaign data state with mapped channels
        setCampaignData((prevData: ApiData) => ({
          ...prevData,
          channels: mappedChannels,
        }));

        console.log("Updated Campaign Data State:", {
          ...data,
          channels: mappedChannels,
        }); // Debug log for the final state
      } catch (error) {
        console.error("Error fetching campaign details:", error); // Log any errors
      }
    };

    fetchCampaignDetails();
  }, []);

  useEffect(() => {
    console.log("Target Audience Data:", targetAudience);
  }, [targetAudience]);

  interface CampaignType {
    id: number;
    selectedCategory: string;
    channels: Channel[];
    assignedEmployees: string[];
    channelTypes: string[];
    channelSubTypes: string[][];
    channelIds: number[]; // Add this to store channel IDs
  }

  const [campaignTypes, setCampaignTypes] = useState<CampaignType[]>([]);
  const targeyAudience = [
    { value: "small_business", label: "Small Business" },
    { value: "corporate_companies", label: "Corporate Companies" },
    { value: "urban_apartment", label: "Urban Apartment" },
    { value: "business", label: "Business" },
  ];

  const options: Option[] = employeeList.map((employee) => ({
    value: employee.serial_number,
    label: employee.name_in_full,
  }));

  const targetAudiences: TargetAudience[] = targetAudienceList.map(
    (target) => ({
      value: target.id,
      label: target.name,
    })
  );

  const addCampaignType = () => {
    setCampaigns([
      ...campaigns,
      {
        id: campaigns.length,
        selectedCategory: "",
        channels: [],
        assignedEmployees: [],
        channelTypes: [],
        channelSubTypes: [],
        channelIds: [], // Add this to store channel IDs
      },
    ]);
    setChannelErrors([...channelErrors, {}]);
  };

  const addNewChannel = () => {
    const newChannel = {
      id: campaignData.channels.length + 1,
      selectedChannel: "",
      assignedBy: [],
      channelTypes: [],
      channelSubTypes: [],
    };

    setCampaignData((prevData: { channels: Channel[] }) => ({
      ...prevData,
      channels: [...prevData.channels, newChannel],
    }));
  };

  interface HandleCategoryChange {
    (campaignIndex: number, value: string): void;
  }

  const handleCategoryChange: HandleCategoryChange = async (
    campaignIndex,
    value
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].selectedCategory = value;

    // Mock or real channelId based on the selected category
    const channelId = getChannelIdFromCategory(value); // Mock function
    updatedCampaigns[campaignIndex].channelIds[0] = channelId; // Set for the first channel
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (value) {
      updatedErrors[campaignIndex].selectedCategory = false;
    }
    setChannelErrors(updatedErrors);

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      // Fetch channel types for the selected channel ID
      const response = await apiDefinitions.getChannelTypes(token, channelId);
      const fetchedOptions: OptionType[] = response.data.data.map(
        (item: any) => ({
          value: item.id.toString(),
          label: item.name,
        })
      );

      // Update the channel type options dynamically
      setChannelTypeOptions((prevOptions) => ({
        ...prevOptions,
        [campaignIndex]: {
          0: fetchedOptions, // Assuming this is the first channel
        },
      }));
    } catch (error) {
      console.error(
        `Failed to fetch channel types for channelId ${channelId}`,
        error
      );
    }
  };

  const getChannelIdFromCategory = (category: string): number => {
    switch (category) {
      case "1":
        return 1;
      case "2":
        return 2;
      case "3":
        return 3;
      case "4":
        return 4;
      case "5":
        return 5;
      case "6":
        return 6;
      case "7":
        return 7;
      default:
        return 0;
    }
  };

  interface EmployeeOption {
    value: string;
    label: string;
  }

  interface HandleEmployeeChange {
    (campaignIndex: number, selectedOptions: MultiValue<EmployeeOption>): void;
  }

  const handleEmployeeChange: HandleEmployeeChange = (
    channelIndex,
    selectedOptions
  ) => {
    const updatedCampaigns = [...campaigns];
    const employeeValues = selectedOptions.map((option) => option.value);
    updatedCampaigns[channelIndex].assignedEmployees = employeeValues;
    setCampaigns(updatedCampaigns);
  };

  interface HandleRemoveChannelType {
    (campaignIndex: number, channelIndex: number): void;
  }

  const handleRemoveChannelType: HandleRemoveChannelType = (
    campaignIndex,
    channelIndex
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].channelTypes.splice(channelIndex, 1);
    updatedCampaigns[campaignIndex].channelSubTypes.splice(channelIndex, 1);
    setCampaigns(updatedCampaigns);
  };

  const handleChannelChange = async (
    campaignIndex: number,
    channelIndex: number,
    selectedChannelId: string
  ) => {
    const updatedChannels = [...campaignData.channels];
    updatedChannels[channelIndex].selectedChannel = selectedChannelId;
    setCampaignData({
      ...campaignData,
      channels: updatedChannels,
    });

    // Fetch channel types for the selected channel
    const token = sessionStorage.getItem("token");
    if (token) {
      try {
        const response = await apiDefinitions.getChannelTypes(
          token,
          selectedChannelId
        );
        const fetchedChannelTypes = response.data.data.map((item: any) => ({
          value: item.id.toString(),
          label: item.name,
        }));

        setChannelTypeOptions((prevOptions) => ({
          ...prevOptions,
          [campaignIndex]: {
            ...prevOptions[campaignIndex],
            [channelIndex]: fetchedChannelTypes,
          },
        }));
      } catch (error) {
        console.error("Failed to fetch channel types", error);
      }
    }
  };

  useEffect(() => {
    const fetchChannelTypesForAllCampaigns = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found. Please log in again.");
        return;
      }

      const updatedOptions = { ...channelTypeOptions };

      for (
        let campaignIndex = 0;
        campaignIndex < campaigns.length;
        campaignIndex++
      ) {
        const channelIds = campaigns[campaignIndex].channelIds;

        for (
          let channelIndex = 0;
          channelIndex < channelIds.length;
          channelIndex++
        ) {
          const channelId = channelIds[channelIndex];
          if (channelId && !updatedOptions[campaignIndex]?.[channelIndex]) {
            try {
              const response = await apiDefinitions.getChannelTypes(
                token,
                channelId
              );
              const fetchedOptions: OptionType[] = response.data.data.map(
                (item: any) => ({
                  value: item.id.toString(),
                  label: item.name,
                })
              );

              updatedOptions[campaignIndex] = {
                ...(updatedOptions[campaignIndex] || {}),
                [channelIndex]: fetchedOptions,
              };
            } catch (error) {
              console.error(
                `Failed to fetch channel types for channelId ${channelId}`,
                error
              );
            }
          }
        }
      }

      setChannelTypeOptions(updatedOptions);
    };

    fetchChannelTypesForAllCampaigns();
  }, [campaigns]);

  const addNewChannelType = (channelIndex: number) => {
    setCampaignData((prevData: { channels: Channel[] }) => {
      const updatedChannels = prevData.channels.map((channel, index) => {
        if (index === channelIndex) {
          return {
            ...channel,
            channelTypes: [
              ...channel.channelTypes,
              {
                id: channel.channelTypes.length + 1,
                name: "",
                channelSubTypes: [],
              },
            ],
          };
        }
        return channel;
      });

      return {
        ...prevData,
        channels: updatedChannels,
      };
    });
  };

  const handleChannelTypeChange = async (
    campaignIndex: number,
    channelIndex: number,
    value: string
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].channelTypes[channelIndex] = value;
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (value) {
      if (!updatedErrors[campaignIndex]) updatedErrors[campaignIndex] = {};
      if (!updatedErrors[campaignIndex].channelTypes)
        updatedErrors[campaignIndex].channelTypes = {};
      updatedErrors[campaignIndex].channelTypes[channelIndex] = false;
    }
    setChannelErrors(updatedErrors);

    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      // Fetch subtypes based on the selected channel type
      const response = await apiDefinitions.getChannelSubTypes(token, value);
      const fetchedSubTypes: OptionType[] = response.data.data.map(
        (item: any) => ({
          value: item.id.toString(),
          label: item.name,
        })
      );

      // Update the subType options dynamically
      setChannelSubTypeOptions((prevOptions) => ({
        ...prevOptions,
        [campaignIndex]: {
          ...(prevOptions[campaignIndex] || {}),
          [channelIndex]: fetchedSubTypes,
        },
      }));
    } catch (error) {
      console.error(
        `Failed to fetch channel subtypes for type ${value}`,
        error
      );
    }
  };

  const handleChannelSubTypeChange = (
    campaignIndex: number,
    channelIndex: number,
    selectedOptions: MultiValue<{ value: string; label: string }>
  ) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[campaignIndex].channelSubTypes[channelIndex] =
      selectedOptions.map((option) => option.value);
    setCampaigns(updatedCampaigns);

    const updatedErrors = [...channelErrors];
    if (selectedOptions.length > 0) {
      if (!updatedErrors[campaignIndex]) updatedErrors[campaignIndex] = {};
      if (!updatedErrors[campaignIndex].channelSubTypes)
        updatedErrors[campaignIndex].channelSubTypes = {};
      updatedErrors[campaignIndex].channelSubTypes[channelIndex] = false;
    }
    setChannelErrors(updatedErrors);
  };

  const [channelFieldVisibility, setChannelFieldVisibility] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleChannelFields = (channelId: number) => {
    setChannelFieldVisibility((prevState) => ({
      ...prevState,
      [channelId]: !prevState[channelId], // Toggle visibility
    }));
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllCampaignTypes(token).then((response: any) => {
          console.log("Campaign Categories: ", response.data.data);
          setCategoryList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        if (selectedCategory) {
          apiDefinitions
            .getCampaignSubTypesById(token, selectedCategory)
            .then((response: any) => {
              console.log("Categories Sub Types: ", response.data.data);
              setCategorySubList(response.data.data);
            });
        }
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getMarketingEmployeeList(token).then((response: any) => {
          const employees = response.data.data.map((employee: any) => ({
            id: employee.serial_number,
            name: employee.name_in_full || `Employee ${employee.serial_number}`,
          }));

          setEmployeeList(employees); // Store employees with names
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getTargetAudience(token).then((response: any) => {
          console.log("Course Categories: ", response.data.data);
          setTargetAudienceList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  interface OptionType {
    value: string;
    label: string;
  }

  const handleTargetAudienceChange = (
    selectedOptions: MultiValue<{ label: string; value: string }>
  ) => {
    const selectedValues = selectedOptions.map((option) => ({
      label: option.label,
      value: option.value,
    }));
    setTargetAudience(selectedValues);
  };

  const removeChannelType = (
    campaignIndex: number,
    channelIndex: number,
    channelTypeIndex: number
  ) => {
    setCampaignData((prevData: { channels: Channel[] }) => {
      const updatedChannels: Channel[] = [...prevData.channels];
      const channel: Channel = updatedChannels[channelIndex];

      // Remove the channel type and corresponding subtypes
      channel.channelTypes.splice(channelTypeIndex, 1);
      channel.channelTypes[channelTypeIndex].channelSubTypes.splice(
        channelTypeIndex,
        1
      ); // Ensure to remove the corresponding subtypes

      return {
        ...prevData,
        channels: updatedChannels,
      };
    });
  };

  // Remove the entire channel (including assigned employees, channel types, and subtypes)
  const removeChannel = (campaignIndex: number, channelIndex: number) => {
    setCampaignData((prevData: any) => {
      const updatedChannels: Channel[] = [...prevData.channels];
      updatedChannels.splice(channelIndex, 1); // Remove the entire channel

      return {
        ...prevData,
        channels: updatedChannels,
      };
    });
  };

  interface ApiChannel {
    id: number;
    name: string;
    channelTypes: {
      id: number;
      name: string;
      channelSubTypes: {
        id: number;
        name: string;
      }[];
    }[];
  }

  interface ApiPerson {
    serial_number: string;
  }

  interface ApiChannelSubType {
    id: number;
    name: string;
  }

  interface ApiChannelType {
    id: number;
    name: string;
    channelSubTypes: ApiChannelSubType[];
  }

  interface ApiChannel {
    id: number;
    name: string;
    people: ApiPerson[];
    channelTypes: ApiChannelType[];
  }

  interface ApiData {
    channels: ApiChannel[];
    [key: string]: any;
  }

  const initializeCampaignData = (apiData: ApiData) => {
    if (!apiData) {
      console.error("initializeCampaignData: No API data provided");
      return;
    }

    const mappedChannels: Channel[] = apiData.channels.map(
      (channel: ApiChannel) => ({
        id: channel.id,
        name: channel.name,
        selectedChannel: channel.id.toString(), // Convert selectedChannel to string
        assignedBy: channel.people.map(
          (person: ApiPerson) => person.serial_number
        ),
        channelTypes: channel.channelTypes.map((type: ApiChannelType) => ({
          id: type.id,
          name: type.name,
          channelSubTypes: type.channelSubTypes.map(
            (subType: ApiChannelSubType) => ({
              id: subType.id,
              name: subType.name,
            })
          ),
        })),
      })
    );

    console.log("Mapped Channels:", mappedChannels); // Debug log
    setCampaignData({
      ...apiData,
      channels: mappedChannels,
    });
  };

  useEffect(() => {
    if (campaignData) {
      initializeCampaignData(campaignData);
    }
  }, []);

  interface ChannelSubType {
    id: number;
    name: string;
  }

  interface ChannelType {
    id: number;
    name: string;
    channelSubTypes: ChannelSubType[];
  }

  interface Channel {
    id: number;
    name: string;
    channelTypes: ChannelType[];
  }

  interface Audience {
    id: string;
    name: string;
  }

  const handleUpdate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    const payload = {
      name: name,
      description: description,
      allocatedBudget: allocatedBudget,
      goal: goal,
      leadCount: leadCount,
      status: status,
      startDate: startDate,
      endDate: endDate,
      createdBy: employeeId,
      updatedBy: employeeId,
      updatedDate: new Date().toISOString().split("T")[0], // Current date in YYYY-MM-DD format
      channels: campaignData.channels.map((channel: Channel) => ({
        id: channel.id,
        people: channel.assignedBy.map((personId: string) => ({
          id: personId,
        })),
        channelTypes: channel.channelTypes.map((channelType: ChannelType) => ({
          id: channelType.id,
          channelSubTypes: channelType.channelSubTypes.map(
            (subType: ChannelSubType) => ({
              id: subType.id,
            })
          ),
        })),
      })),
      campaigns_types: { id: selectedCategory },
      campaign_sub_types: { id: selectedSubCategory },
      targetAudience: targetAudience.map(
        (audience: FormattedTargetAudience) => ({
          id: audience.value,
          name: audience.label,
        })
      ),
    };
    console.log("Payload:", payload);
  };

  const handleAddChannelType = async (campaignIndex: number) => {
    const updatedCampaigns = [...campaigns];
    const newChannelIndex = updatedCampaigns[campaignIndex].channelTypes.length;

    // Add new empty channel type and subtype
    updatedCampaigns[campaignIndex].channelTypes.push("");
    updatedCampaigns[campaignIndex].channelSubTypes.push([]);

    setCampaigns(updatedCampaigns);

    // Fetch options for the new channel type
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    const channelId = updatedCampaigns[campaignIndex].channelIds[0]; // Use first channelId as reference
    if (channelId) {
      try {
        const response = await apiDefinitions.getChannelTypes(token, channelId);
        const fetchedOptions: OptionType[] = response.data.data.map(
          (item: any) => ({
            value: item.id.toString(),
            label: item.name,
          })
        );

        // Update the channelTypeOptions dynamically
        setChannelTypeOptions((prevOptions) => ({
          ...prevOptions,
          [campaignIndex]: {
            ...(prevOptions[campaignIndex] || {}),
            [newChannelIndex]: fetchedOptions,
          },
        }));
      } catch (error) {
        console.error(
          `Failed to fetch channel types for channelId ${channelId}`,
          error
        );
      }
    }
  };

  interface HandleRemoveChannel {
    (campaignIndex: number): void;
  }

  const handleRemoveChannel: HandleRemoveChannel = (campaignIndex) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns.splice(campaignIndex, 1);
    setCampaigns(updatedCampaigns);
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            {/* Page Header */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col-10">
                  <h4 className="page-title">Update Campaign Details</h4>
                </div>
                <div className="col-2 text-end">
                  <button
                    className="btn btn-primary"
                    // onClick={handleBack}
                  >
                    <i
                      className="feather-arrow-left"
                      style={{ fontSize: "17px", marginRight: "5px" }}
                    />{" "}
                    Back to list
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm">
                      <form className="needs-validation" noValidate>
                        <div className="form-row row">
                          <div className="col-md-6 mb-3 mt-3">
                            <label className="col-form-label">
                              Campaign Name{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                value={name}
                                type="text"
                                className={`form-control ${
                                  isNameValid ? "is-invalid" : ""
                                }`}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Campaign Name"
                              />
                              {isNameValid && (
                                <div className="invalid-feedback">
                                  Please enter the campaign name.
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 mb-3 mt-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustomContactMethod"
                            >
                              Campaign Type&nbsp;
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              className={`form-select ${
                                isCampaignTypeValid ? "is-invalid" : ""
                              }`}
                              id="validationCustomContactMethod"
                              value={selectedCategory}
                              onChange={(e) =>
                                setSelectedCategory(e.target.value)
                              }
                              required
                            >
                              <option>Select an option</option>
                              {categoryList.map((category) => (
                                <option key={category.id} value={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                            {isCampaignTypeValid && (
                              <div className="invalid-feedback">
                                Please select a campaign type.
                              </div>
                            )}
                          </div>

                          <div className="col-md-4 mb-3 mt-3">
                            <label
                              className="form-label"
                              htmlFor="validationCustomContactMethod"
                            >
                              Source of Inquiry
                            </label>
                            <select
                              className={`form-select 
                                ${isCampaignTypeValid ? "is-invalid" : ""}
                              `}
                              id="validationCustomContactMethod"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              required
                            >
                              <option value="">Select an option</option>
                              <option value="active">Active</option>
                              <option value="inactive">Inactive</option>
                            </select>
                            {isCampaignTypeValid && (
                              <div className="invalid-feedback">
                                Please select campaign status.
                              </div>
                            )}
                          </div>

                          <div className="divider"></div>
                          <div className="col-md-12 mb-4">
                            <h5 className="mb-3">Campaign Channel Details</h5>
                            {campaignData &&
                            campaignData.channels.length > 0 ? (
                              campaignData.channels.map(
                                (channel: Channel, channelIndex: number) => (
                                  <div key={channel.id} className="card mb-3">
                                    <div className="card-header">
                                      <h6 className="mb-0">
                                        Channel: <strong>{channel.name}</strong>
                                      </h6>
                                    </div>
                                    <div className="card-body">
                                      <h6 className="text-muted">
                                        Assigned Employees:
                                      </h6>
                                      <ul>
                                        {channel.assignedBy.map(
                                          (employeeId, empIndex) => {
                                            const employee = employeeList.find(
                                              (emp) => emp.id === employeeId
                                            );
                                            return (
                                              <li key={empIndex}>
                                                {employee
                                                  ? employee.name
                                                  : `Employee ID: ${employeeId}`}
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                      <h6 className="text-muted">
                                        Channel Types:
                                      </h6>
                                      {channel.channelTypes.length > 0 ? (
                                        channel.channelTypes.map(
                                          (type, typeIndex) => (
                                            <div key={type.id} className="mb-2">
                                              <strong>{type.name}</strong>
                                              {type.channelSubTypes.length >
                                                0 && (
                                                <ul>
                                                  {type.channelSubTypes.map(
                                                    (subType) => (
                                                      <li key={subType.id}>
                                                        {subType.name}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              )}
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <p>No Channel Types</p>
                                      )}
                                    </div>
                                  </div>
                                )
                              )
                            ) : (
                              <p>No Channels Available</p>
                            )}
                          </div>

                          <div className="divider"></div>
                          <div className="row">
                            <div className="row">
                              {/* <div className="divider"></div> */}

                              <div className="row">
                                <div className="col-md-12 mb-3 mt-3">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={addCampaignType}
                                  >
                                    <i className="fa fa-plus"></i>&nbsp; Add
                                    Channel
                                  </button>
                                </div>

                                {campaigns.map(
                                  (campaign: CampaignType, index: number) => (
                                    <div
                                      key={campaign.id}
                                      className="campaign-section"
                                    >
                                      <div className="row">
                                        <div className="col-md-6 mb-3 mt-3">
                                          <label
                                            className="form-label"
                                            htmlFor={`validationCustomContactMethod${campaign.id}`}
                                          >
                                            Channel&nbsp;
                                            <span className="text-danger">
                                              *
                                            </span>
                                          </label>
                                          <select
                                            className={`form-select ${
                                              channelErrors[index]
                                                ?.assignedEmployees
                                                ? "is-invalid"
                                                : ""
                                            }`}
                                            id={`validationCustomContactMethod${campaign.id}`}
                                            value={campaign.selectedCategory}
                                            onChange={(
                                              e: React.ChangeEvent<HTMLSelectElement>
                                            ) =>
                                              handleCategoryChange(
                                                index,
                                                e.target.value
                                              )
                                            }
                                            required
                                          >
                                            <option value="">
                                              Select an option
                                            </option>
                                            <option value="1">
                                              Digital Channels
                                            </option>
                                            <option value="2">
                                              Offline Channels
                                            </option>
                                            <option value="3">
                                              Direct Communication Channels
                                            </option>
                                            <option value="4">
                                              Events and Networking Channels
                                            </option>
                                            <option value="5">
                                              Partner or Collaborative Channels
                                            </option>
                                            <option value="6">
                                              Digital Engagement Channels
                                            </option>
                                            <option value="7">
                                              Internal Communication Channels
                                            </option>
                                          </select>
                                          {channelErrors[index]
                                            ?.selectedCategory && (
                                            <div className="invalid-feedback">
                                              Please select a channel.
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-md-6 mb-3 mt-3">
                                          <label
                                            className="form-label"
                                            htmlFor={`assignedBy${campaign.id}`}
                                          >
                                            Assigned To
                                          </label>
                                          <Select
                                            classNamePrefix="react-select"
                                            className="js-example-placeholder-multiple select2 js-states"
                                            isMulti={true}
                                            placeholder="Select"
                                            options={employeeList.map(
                                              (employee) => ({
                                                value: employee.id,
                                                label: employee.name,
                                              })
                                            )}
                                            value={employeeList
                                              .filter((employee) =>
                                                campaign.assignedEmployees.includes(
                                                  employee.id
                                                )
                                              )
                                              .map((employee) => ({
                                                value: employee.id,
                                                label: employee.name,
                                              }))}
                                            onChange={(
                                              selectedOptions: MultiValue<{
                                                value: string;
                                                label: string;
                                              }>
                                            ) =>
                                              handleEmployeeChange(
                                                index,
                                                selectedOptions.map(
                                                  (option) => ({
                                                    value: option.value,
                                                    label: option.label,
                                                  })
                                                )
                                              )
                                            }
                                            styles={{
                                              control: (base) => ({
                                                ...base,
                                                minHeight: "40px",
                                                height: "auto",
                                              }),
                                              valueContainer: (base) => ({
                                                ...base,
                                                padding: "5px 10px",
                                                flexWrap: "wrap",
                                              }),
                                              multiValue: (base) => ({
                                                ...base,
                                                margin: "2px",
                                              }),
                                            }}
                                          />
                                          {channelErrors[index]
                                            ?.assignedEmployees && (
                                            <div className="text-danger">
                                              Please assign at least one
                                              employee.
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-md-1 ms-2">
                                          <button
                                            className="btn btn-danger"
                                            type="button"
                                            onClick={() =>
                                              handleRemoveChannel(index)
                                            }
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>

                                        <div className="col-md-12 mb-3 mt-3">
                                          <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={() =>
                                              handleAddChannelType(index)
                                            }
                                          >
                                            <i className="fa fa-plus"></i>&nbsp;
                                            Add Channel Type
                                          </button>
                                        </div>

                                        <div className="row mb-3 mt-3">
                                          {campaign.channelTypes.map(
                                            (channelType, channelIndex) => (
                                              <div
                                                key={channelIndex}
                                                className="d-flex align-items-start mb-3"
                                              >
                                                {/* Channel Type Field */}
                                                <div className="col-md-6 pe-2">
                                                  <label
                                                    className="form-label"
                                                    htmlFor={`channelType${campaign.id}-${channelIndex}`}
                                                  >
                                                    Channel Type
                                                  </label>
                                                  <select
                                                    className={`form-select ${
                                                      channelErrors[index]
                                                        ?.channelTypes?.[
                                                        channelIndex
                                                      ]
                                                        ? "is-invalid"
                                                        : ""
                                                    }`}
                                                    id={`channelType${campaign.id}-${channelIndex}`}
                                                    value={channelType}
                                                    onChange={(
                                                      e: React.ChangeEvent<HTMLSelectElement>
                                                    ) =>
                                                      handleChannelTypeChange(
                                                        index,
                                                        channelIndex,
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    <option value="">
                                                      Select a Channel Type
                                                    </option>
                                                    {channelTypeOptions[
                                                      index
                                                    ]?.[channelIndex]?.map(
                                                      (option) => (
                                                        <option
                                                          key={option.value}
                                                          value={option.value}
                                                        >
                                                          {option.label}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  {channelErrors[index]
                                                    ?.channelTypes?.[
                                                    channelIndex
                                                  ] && (
                                                    <div className="invalid-feedback">
                                                      Please select a channel
                                                      type.
                                                    </div>
                                                  )}
                                                </div>

                                                {/* Channel Sub Type Field */}
                                                <div className="col-md-6 ps-2">
                                                  <label
                                                    className="form-label"
                                                    htmlFor={`channelSubType${campaign.id}-${channelIndex}`}
                                                  >
                                                    Channel Sub-Type
                                                  </label>
                                                  <Select
                                                    classNamePrefix="react-select"
                                                    isMulti={true}
                                                    placeholder="Select Subtypes"
                                                    options={
                                                      channelSubTypeOptions[
                                                        index
                                                      ]?.[channelIndex] || []
                                                    } // Use fetched options
                                                    value={channelSubTypeOptions[
                                                      index
                                                    ]?.[channelIndex]?.filter(
                                                      (option) =>
                                                        campaign.channelSubTypes[
                                                          channelIndex
                                                        ]?.includes(
                                                          option.value
                                                        )
                                                    )}
                                                    onChange={(
                                                      selectedOptions: MultiValue<{
                                                        value: string;
                                                        label: string;
                                                      }>
                                                    ) =>
                                                      handleChannelSubTypeChange(
                                                        index,
                                                        channelIndex,
                                                        selectedOptions
                                                      )
                                                    }
                                                    styles={{
                                                      control: (base) => ({
                                                        ...base,
                                                        minHeight: "40px",
                                                      }),
                                                      valueContainer: (
                                                        base
                                                      ) => ({
                                                        ...base,
                                                        padding: "5px 10px",
                                                      }),
                                                      multiValue: (base) => ({
                                                        ...base,
                                                        margin: "2px",
                                                      }),
                                                    }}
                                                  />
                                                  {/* {channelErrors[index]
                                            ?.channelSubTypes?.[
                                            channelIndex
                                          ] && (
                                            <div className="text-danger">
                                              Please select at least one
                                              sub-type.
                                            </div>
                                          )} */}
                                                </div>

                                                <div className="col-md-2 ms-2 mt-1">
                                                  <button
                                                    className="btn btn-secondary mt-4"
                                                    type="button"
                                                    onClick={() =>
                                                      handleRemoveChannelType(
                                                        index,
                                                        channelIndex
                                                      )
                                                    }
                                                  >
                                                    <i className="fa fa-trash"></i>
                                                  </button>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                                {/* <div className="divider"></div> */}
                              </div>
                            </div>
                            <div className="divider"></div>

                            <div className="col-md-6 mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustom01"
                              >
                                Campaign Goal&nbsp;
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  className={`form-control ${
                                    isGoalValid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustom01"
                                  placeholder="Campaign Goal"
                                  value={goal}
                                  onChange={(e) => setGoal(e.target.value)}
                                  required
                                />
                                {isGoalValid && (
                                  <div className="invalid-feedback">
                                    Please enter the campaign goal.
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6 mb-3">
                              <label className="col-form-label">
                                Target Audience&nbsp;
                                <span className="text-danger"> *</span>
                              </label>
                              <Select
                                isMulti
                                name="targetAudience"
                                options={targetAudienceList.map((audience) => ({
                                  label: audience.name,
                                  value: audience.id.toString(),
                                }))}
                                value={targetAudience}
                                className="select"
                                onChange={handleTargetAudienceChange}
                                styles={{
                                  control: (base) => ({
                                    ...base,
                                    minHeight: "40px",
                                    height: "auto",
                                  }),
                                  valueContainer: (base) => ({
                                    ...base,
                                    padding: "5px 10px",
                                    flexWrap: "wrap",
                                  }),
                                  multiValue: (base) => ({
                                    ...base,
                                    margin: "2px",
                                  }),
                                }}
                              />
                            </div>

                            <div className="col-md-6 mb-3">
                              <label className="col-form-label">
                                Est. Allocated Budget{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  value={allocatedBudget}
                                  type="text"
                                  className={`form-control ${
                                    isAllocatedBudgetValid ? "is-invalid" : ""
                                  }`}
                                  onChange={(e) =>
                                    setAllocatedBudget(e.target.value)
                                  }
                                  placeholder="Campaign Name"
                                />
                                {isAllocatedBudgetValid && (
                                  <div className="invalid-feedback">
                                    Please enter the estimated allocated budget.
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6 mb-3">
                              <label className="col-form-label">
                                Excpected Lead Count{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <input
                                  value={leadCount}
                                  type="text"
                                  className={`form-control ${
                                    isLeadCountValid ? "is-invalid" : ""
                                  }`}
                                  onChange={(e) => setLeadCount(e.target.value)}
                                  placeholder="Campaign Name"
                                />
                                {isLeadCountValid && (
                                  <div className="invalid-feedback">
                                    Please enter the expected lead count.
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom01"
                                >
                                  Description&nbsp;
                                  <span className="text-danger">*</span>
                                </label>

                                <div className="input-group">
                                  <textarea
                                    rows={4}
                                    className={`form-control ${
                                      isDescriptionValid ? "is-invalid" : ""
                                    }`}
                                    id="validationCustom01"
                                    placeholder="Description"
                                    value={description}
                                    onChange={(e) =>
                                      setDescription(e.target.value)
                                    }
                                    required
                                  />
                                  {isDescriptionValid && (
                                    <div className="invalid-feedback">
                                      Please enter the description.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomFollowUpDate"
                                >
                                  Campaign Start Date&nbsp;
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="date"
                                  className={`form-control ${
                                    isStartDateValid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomFollowUpDate"
                                  value={startDate}
                                  onChange={(e) => setStartDate(e.target.value)}
                                  required
                                />
                                {isStartDateValid && (
                                  <div className="invalid-feedback">
                                    Please select a start date.
                                  </div>
                                )}
                              </div>

                              <div className="col-md-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomFollowUpDate"
                                >
                                  Campaign End Date&nbsp;
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="date"
                                  className={`form-control ${
                                    isStartEndValid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomFollowUpDate"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                  required
                                />
                                {isStartEndValid && (
                                  <div className="invalid-feedback">
                                    Please select a end date.
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-12 mt-3">
                              <button
                                className="btn btn-primary"
                                onClick={(e) => {
                                  handleUpdate(e);
                                  // Add API call here to send the payload
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCampaign;
