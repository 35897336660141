import ApexCharts from "apexcharts";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import CollapseHeader from "../../../core/common/collapse-header";
import { all_routes } from "../../router/all_routes";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import Dropdown from "../leadsDashboard/yearDropDown";

const currentYear = new Date().getFullYear();
const route = all_routes;
const defultMonth = 30;

const BadgetDashboard = () => {
  const chartRef = useRef(null);
  const navigate = useNavigate();

  const [selectedStatusMonth, setSelectedStatusMonth] = useState(defultMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedChanelDistribution, setSelectedChanelDistribution] =
    useState(defultMonth);

  const handleSelectYear = (year: number) => {
    setSelectedYear(year);
    console.log("Selected year:", year); // You can use the selected year value as needed
  };

  const [chartOptions, setChartOptions] = useState<any>({
    series: [20, 50, 100],
    chart: {
      width: 400,
      height: 300,
      type: "pie",
    },
    legend: {
      position: "bottom",
    },
    colors: ["#4A00E5", "#FFA201", "#0092E4"],
    labels: ["Active", "Inactive", "Completed"],
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  const [chartOptions1, setChartOptions1] = useState<any>({
    series: [
      {
        name: "Reports",
        data: [20, 30, 20, 40, 50, 20, 30, 7, 30, 40, 10, 40],
      },
    ],
    colors: ["#4A00E5"],
    chart: {
      height: 273,
      type: "area",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      min: 0,
      max: 60,
      tickAmount: 5,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  });

  useEffect(() => {
    if (chartRef.current) {
      // let maxStatusValue = 10;
      // if(statusData.length === 0) {
      //    maxStatusValue = Math.max(
      //     statusData.New,
      //     statusData.Contacted,
      //     statusData.Interested,
      //     statusData.Application_in_Progress,
      //     statusData.Enrolled,
      //     statusData.Disqualified,
      //     statusData.Follow_Up_Needed,
      //     statusData.Lost
      //   );
      // }

      const options = {
        series: [
          {
            name: "sales",
            colors: ["#FFC38F"],
            data: [
              {
                x: "Digital",
                y: 135,
              },
              {
                x: "Offline",
                y: 112,
              },
              {
                x: "Direct",
                y: 324,
              },
              {
                x: "Events and Networking",
                y: 432,
              },
              {
                x: "Collaborative",
                y: 534,
              },
              {
                x: "Digital Engagement",
                y: 193,
              },
              {
                x: "Internal Communication",
                y: 323,
              },
            ],
          },
        ],
        chart: {
          type: "bar",
          height: 300,
        },
        plotOptions: {
          bar: {
            borderRadiusApplication: "around",
            columnWidth: "40%",
          },
        },
        colors: ["#00918E"],
        xaxis: {
          type: "category",
          group: {
            style: {
              fontSize: "7px",
              fontWeight: 700,
            },
          },
        },
        yaxis: {
          min: 0,
          // max: maxStatusValue+5,
          max: 500,
          tickAmount: 5,
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      // Cleanup on unmount
      return () => {
        chart.destroy();
      };
    }
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header">
              <div className="row align-items-center ">
                <div className="col-md-4">
                  <h3 className="page-title">Budget Dashboard</h3>
                </div>
                <div className="col-md-8 float-end ms-auto">
                  <div className="d-flex title-head">
                    <div className="daterange-picker d-flex align-items-center justify-content-center">
                      <div className="head-icons mb-0">
                        <CollapseHeader />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="card w-100">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Campaigns By Months
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <Dropdown onSelectYear={handleSelectYear} />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="contact-report">
                  <Chart
                    options={chartOptions1}
                    series={chartOptions1.series}
                    type="area"
                    height={chartOptions1.chart.height}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 d-flex">
            <div className="card w-100">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Campaign Status
                  </h4>
                  <div className="dropdown">
                    <Link
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                      to="#"
                    >
                      Last {selectedStatusMonth} Days
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">
                      <Link
                        to="#"
                        className="dropdown-item"
                        onClick={() => setSelectedStatusMonth(30)}
                      >
                        Last 30 Days
                      </Link>
                      <Link
                        to="#"
                        className="dropdown-item"
                        onClick={() => setSelectedStatusMonth(15)}
                      >
                        Last 15 Days
                      </Link>
                      <Link
                        to="#"
                        className="dropdown-item"
                        onClick={() => setSelectedStatusMonth(7)}
                      >
                        Last 7 Days
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="leadpiechart">
                  <Chart
                    options={chartOptions}
                    series={chartOptions.series}
                    type={chartOptions.chart.type}
                    width={chartOptions.chart.width}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 d-flex">
            <div className="card flex-fill">
              <div className="card-header border-0 pb-0">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                  <h4>
                    <i className="ti ti-grip-vertical me-1" />
                    Channel Distribution
                  </h4>
                  <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <div className="dropdown">
                      <Link
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                        to="#"
                      >
                        Last {selectedChanelDistribution} Days
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedChanelDistribution(30)}
                        >
                          Last 30 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedChanelDistribution(15)}
                        >
                          Last 15 Days
                        </Link>
                        <Link
                          to="#"
                          className="dropdown-item"
                          onClick={() => setSelectedChanelDistribution(7)}
                        >
                          Last 7 Days
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="deals-chart" ref={chartRef} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadgetDashboard;
