export const activitiesMail = [
  {
    id: 1,
    checkbox: "",
    title: "Analysing latest time estimation for new project",
    due_date: "11 Oct 2023, 05:00 pm",
    owner: "Bradtke",
    created_date: "03 Oct 2023, 03:53 pm",
    status: "1",
    action: "",
  },
  {
    id: 2,
    checkbox: "",
    title: "Regarding latest updates in project",
    due_date: "30 Nov 2023, 09:20 pm",
    owner: " Lynch",
    created_date: "25 Nov 2023, 07:20 pm",
    status: "1",
    action: "",
  },
  {
    id: 3,
    checkbox: "",
    title: "Attach final proposal for upcoming project",
    due_date: "19 Dec 2023, 02:20 pm",
    owner: " Andrew",
    created_date: "10 Dec 2023, 06:30 pm",
    status: "1",
    action: "",
  },
];
