export const companiesData = [
  {
    id: 1,
    name: "NovaWaveLLC",
    phone: "+1 875455453",
    email: "Robertson@example.com",
    tags: "Promotion",
    location: "Germany",
    rating: 4.2,
    owner: "Hendry",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star filled",
    image: "assets/img/icons/company-icon-01.svg",
    key: "1"
  },
  {
    id: 2,
    name: "BlueSky Industries",
    phone: "+1 989757485",
    email: "sharon@example.com",
    tags: "Rated",
    location: "USA",
    rating: 5.0,
    owner: "Guillory",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-02.svg",
    key: "2"
  },
  {
    id: 3,
    name: "SilverHawk",
    phone: "+1 546555455",
    email: "Vaughan12@example.com",
    tags: "Promotion",
    location: "Canada",
    rating: 3.5,
    owner: "Jami",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Inactive",
    stars: "fa fa-star filled",
    image: "assets/img/icons/company-icon-03.svg",
    key: "3"
  },
  {
    id: 4,
    name: "SummitPeak",
    phone: "+1 454478787",
    email: "Jessica13@example.com",
    tags: "Promotion",
    location: "India",
    rating: 4.5,
    owner: "Theresa",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-04.svg",
    key: "4"
  },
  {
    id: 5,
    name: "RiverStone Ventur",
    phone: "+1 124547845",
    email: "CarolTho3@example.com",
    tags: "Promotion",
    location: "China",
    rating: 4.7,
    owner: "Espinosa",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-05.svg",
    key: "5"
  },
  {
    id: 6,
    name: "Bright Bridge Grp",
    phone: "+1 478845447",
    email: "Dawnmercha@example.com",
    tags: "Promotion",
    location: "Japan",
    rating: 5.0,
    owner: "Martin",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star filled",
    image: "assets/img/icons/company-icon-06.svg",
    key: "6"
  },
  {
    id: 7,
    name: "CoastalStar Co.",
    phone: "+1 215544845",
    email: "Rachel@example.com",
    tags: "Promotion",
    location: "Indonesia",
    rating: 3.1,
    owner: "Newell",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-07.svg",
    key: "7"
  },
  {
    id: 8,
    name: "HarborView",
    phone: "+1 121145471",
    email: "Jonelle@example.com",
    tags: "Promotion",
    location: "Cuba",
    rating: 5.0,
    owner: "Janet",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-08.svg",
    key: "8"
  },
  {
    id: 9,
    name: "Golden Gate Ltd",
    phone: "+1 321454789",
    email: "Jonathan@example.com",
    tags: "Promotion",
    location: "Isreal",
    rating: 2.7,
    owner: "Craig",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-09.svg",
    key: "9"
  },
  {
    id: 10,
    name: "Redwood Inc",
    phone: "+1 278907145",
    email: "brook@example.com",
    tags: "Promotion",
    location: "Colombia",
    rating: 3.0,
    owner: "Daniel",
    social_links: [
      "la la-envelope",
      "la la-phone-volume",
      "lab la-facebook-messenger",
      "la la-skype",
      "la la-facebook",
    ],
    status: "Active",
    stars: "fa fa-star",
    image: "assets/img/icons/company-icon-10.svg",
    key: "10"
  },
];
