import { label } from "yet-another-react-lightbox/*";
import { all_routes } from "../../../feature-module/router/all_routes";

const route = all_routes;
export const SidebarData = [
  {
    label: "MAIN MENU",
    submenuOpen: true,
    showSubRoute: false,
    submenuHdr: "Main",
    submenuItems: [
      {
        label: "Leads Management",
        icon: "ti ti-chart-arcs",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Leads Dashboard", link: route.leadsDashboard },
          { label: "Quick Leads", link: route.addQuickLeads },
          { label: "All Leads", link: route.viewAllLeads },
          { label: "My Leads", link: route.myLeads },
        ],
      },
      {
        label: "Contact Management",
        icon: "ti ti-user-up",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Call Dashboard", link: route.CallDashBoard },
          { label: "Call History", link: route.callHistory },
          // { label: "My Call History", link: route.myCallHistory },
        ],
      },
      {
        label: "Campaign Mangement",
        icon: "ti ti-brand-campaignmonitor",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Campaign Dashboard", link: route.CampaignDashboard },
          { label: "Campaigns", link: route.campaign },
        ],
      },
      {
        label: "Budget Management",
        icon: "ti ti-file-invoice",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          // { label: "Budget Dashboard", link: route.BudgetDashboard },
          { label: "Annual Budget", link: route.createBudget },
        ],
      },
      // {
      //   label: "Expenses Management",
      //   icon: "ti ti-report-money",
      //   submenu: true,
      //   showSubRoute: false,

      //   submenuItems: [
      //     { label: "Expance Dashboard", link: route.BudgetDashboard },
      //     { label: "Expances", link: route.createBudget },

      //   ],
      // },
      {
        label: "Settings ",
        icon: "ti ti-settings-cog",
        submenu: true,
        showSubRoute: false,

        submenuItems: [
          { label: "Lead Assign", link: route.leadAssign },
          { label: "Budget Approval", link: route.ApproveBudget },
          { label: "Campaign Approval", link: route.campaignApproval },
          // { label: "Expenses Approval", link: route.expensesApproval },
          // { label: "Expenses Approval", link: route.expensesApproval },
          { label: "Campaign Types", link: route.campaignTypes },
          {
            label: "Campaign Top-Up Approval",
            link: route.campaignTopupApproval,
          },
        ],
      },
    ],
  },
];
