import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Table from "../../../core/common/dataTable/index";
import apiDefinitions from "../../../api/apiDefinitions";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";

import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

const TopUpRequest = () => {
  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const route = all_routes;
  const navigate = useNavigate();
  const [campaignList, setCampaignList] = useState([]);
  const [fieldErrors, setFieldErrors] = useState<any>({
    year: "",
    budget: "",
    file: "",
  });
  const [rowData, setRowData] = useState<any>({});
  const [budget, setBudget] = useState("");
  const [note, setNote] = useState("");
  const [uploadAttendaceDocuments, setUploadAttendaceDocuments] =
    useState<any>(null);
  const [attendaceDocuments, setAttendaceDocuments] = useState<any>([]);
  const [initalErrors, setInitalErrors] = useState(false);
  const hasListLoaded = useRef(false);

  const [checkValidation, setCheckValidation] = useState(false);

  const handleErrors = (error: any) => {
    setFieldErrors((previouseErrors: any) => {
      return { ...previouseErrors, ...error };
    });
  };

  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract only the date in YYYY-MM-DD format
  };

  interface Campaign {
    topUpFileJustifications(topUpFileJustifications: any): unknown;
    id: string;
    year: string;
    targetAudience: string;
    leadCount: number;
    status: string;
    startDate: string;
    endDate: string;
  }

  interface Column {
    title: string;
    dataIndex: string;
    render?: (text: any, record: Campaign) => JSX.Element;
  }

  const columns: Column[] = [
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Budget",
      dataIndex: "topUpAmount",
    },
    {
      title: "Requested Date",
      dataIndex: "topUpDate",
      render: (startDate: string) => <span>{formatDate(startDate)}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: { id: number; name: string; color: string }[]) => {
        if (status.length > 0) {
          const { name, color } = status[0]; // Extract the first status
          return (
            <div>
              <span className={`badge badge-pill badge-status bg-${color}`}>
                {name}
              </span>
            </div>
          );
        }
        return <span>No Status</span>; // Handle case where status is empty
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record: Campaign) => (
        <div className="action-icons d-flex gap-2">
          {/* Eye Icon */}
          <Link
            to="#"
            className="text-info"
            title="View Details"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalScrollable3"
            onClick={() => {
              setRowData(record);
              setUploadAttendaceDocuments(record.topUpFileJustifications);
            }}
          >
            <i className="ti ti-eye" style={{ fontSize: "1.8rem" }}></i>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    refreshCampaigns();
  }, []);

  const refreshCampaigns = () => {
    if (!hasListLoaded.current) {
      hasListLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        const toastId = toast.loading("Loading Data...");
        apiDefinitions
          .getAllPendingTopUpBudgets(token)
          .then((response: any) => {
            if (response.data.code === 200) {
              console.log("Campaign Categories: ", response.data.data);
              toast.dismiss(toastId);
              setCampaignList(response.data.data);
            } else {
              toast.dismiss(toastId);
              toast.error("Failed to Load Data");
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  useEffect(() => {
    if (uploadAttendaceDocuments !== null) {
      const transformedData = uploadAttendaceDocuments.map(
        (url: string, index: number) => {
          const filePath = url || "";
          const decodedFilePath = decodeURIComponent(filePath); // Decode URL-encoded characters
          const fileName = decodedFilePath.split("/").pop() || "";
          const fileExtension = filePath.split(".").pop()?.toLowerCase() || "";

          let fileType = "unknown";

          if (fileExtension === "pdf") {
            fileType = "pdf";
          } else if (fileExtension.match(/(jpg|jpeg|png|gif|bmp|svg|webp)$/)) {
            fileType = "image";
          } else if (fileExtension.match(/(doc|docx)$/)) {
            fileType = "doc";
          } else if (fileExtension.match(/(xls|xlsx)$/)) {
            fileType = "xls";
          }

          return {
            id: index, // Use index as a unique identifier
            filePath: filePath,
            fileName: fileName,
            fileType: fileType,
            fileExtension: fileExtension,
          };
        }
      );

      setAttendaceDocuments(transformedData);
    } else {
      // Handle the case where uploadAttendaceDocuments is null
      // You might want to set some default value or handle it based on your requirements
      setAttendaceDocuments([]);
    }
  }, [uploadAttendaceDocuments]);

  const handleApprove = () => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Approve it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          const decodedToken: DecodedToken = jwtDecode(token);
          const currentTime = Math.floor(Date.now() / 1000);

          if (decodedToken.exp && decodedToken.exp > currentTime) {
            const payload = {
              topUpBudgetId: rowData.id,
              approvedBy: decodedToken.serial_number,
              action: "approved",
            };

            const toastId = toast.loading("Approving Top-UP Budget...");
            apiDefinitions
              .approveTopUpBudget(token, payload)
              .then((response: any) => {
                if (response.data.code === 200) {
                  toast.dismiss(toastId);
                  toast.success("Top-UP Budget Approved Successfully", {
                    duration: 5000,
                  });
                  refreshCampaigns();
                  window.location.reload();
                } else {
                  toast.dismiss(toastId);
                  toast.error("Failed to Approve Top-UP Budget");
                }
              });
          } else {
            console.log("Session expired. Please log in again.");
            navigate(route.login);
          }
        } catch (error) {
          console.error("Invalid token or unable to decode", error);
          navigate(route.login);
        }
      }
    });
  };

  const handleReject = () => {
    setCheckValidation(!checkValidation);

    let isValid = true;

    if (note === "") {
      handleErrors({ note: "Note Required for reject" });
      isValid = false;
    }

    if (fieldErrors.note === "" && isValid) {
      const token = sessionStorage.getItem("token");

      if (!token) {
        console.error("No token found. Please log in again.");
        navigate(route.login);
        return;
      }

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Reject it!",
        cancelButtonText: "No, cancel!",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          try {
            const decodedToken: DecodedToken = jwtDecode(token);
            const currentTime = Math.floor(Date.now() / 1000);

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              const payload = {
                topUpBudgetId: rowData.id,
                rejectionNote: note,
                approvedBy: decodedToken.serial_number,
                action: "rejected",
              };

              const toastId = toast.loading("Rejecting Budget Top up...");
              apiDefinitions
                .approveTopUpBudget(token, payload)
                .then((response: any) => {
                  if (response.data.code === 200) {
                    toast.dismiss(toastId);
                    toast.success("Budget Top Up Rejected Successfully", {
                      duration: 5000,
                    });
                    refreshCampaigns();
                    window.location.reload();
                  } else {
                    toast.dismiss(toastId);
                    toast.error("Failed to reject Budget Top Up");
                  }
                })
                .catch((error: any) => {
                  toast.dismiss(toastId);
                  toast.error("An error occurred");
                  console.error("API call failed:", error);
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
            navigate(route.login);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (note === "") {
      if (initalErrors) {
        handleErrors({ note: "Note Required for reject" });
      } else {
        setInitalErrors(true);
      }
    } else {
      handleErrors({ note: "" });
    }
  }, [note]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">
                      Budget Top-Up Request Approval
                    </h4>
                  </div>
                  <div className="col-8 text-end">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              <div className="campaign-tab">
                <ul className="nav">
                  <li>
                    <Link
                      to={route.ApproveBudget}
                      className={
                        isLinkActive(route.ApproveBudget) ? "active" : ""
                      }
                    >
                      Annual Budgets Requests
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={route.TopUpRequest}
                      className={
                        isLinkActive(route.TopUpRequest) ? "active" : ""
                      }
                    >
                      Top-Up Requests
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Campaign Tab */}
              <div className="card">
                <div className="card-header">
                  {/* Search */}
                  <div className="row align-items-center">
                    <div className="row align-items-center justify-content-between">
                      {/* Search Bar aligned to the Left */}
                      <div className="col-sm-6">
                        {/* <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Campaign"
                            onChange={(e) => setSearchKey(e.target.value)}
                          />
                        </div> */}
                      </div>

                      {/* Button aligned to the Right */}
                    </div>
                    <div className="row">
                      <div className="col-xl-12 mt-3">
                        <div className="table-responsive custom-table">
                          <Table dataSource={campaignList} columns={columns} />
                        </div>
                      </div>
                    </div>

                    <div
                      className="modal fade"
                      id="exampleModalScrollable3"
                      tabIndex={-1}
                      aria-labelledby="exampleModalScrollable3"
                      data-bs-keyboard="false"
                      aria-hidden="true"
                    >
                      {/* Scrollable modal */}
                      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4
                              className="modal-title"
                              id="staticBackdropLabel3"
                            >
                              Approve Budget Top Up Request
                            </h4>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            />
                          </div>
                          <div className="modal-body">
                            <div className="row mb-3">
                              <p>
                                <strong>Year:</strong> {rowData.year}
                              </p>
                            </div>
                            <div className="row mb-3">
                              <p>
                                <strong>Requested Budget:</strong>{" "}
                                {rowData.topUpAmount}
                              </p>
                            </div>
                            <div className="row mb-3">
                              <p>
                                <strong>Requested Date:</strong>{" "}
                                {rowData.topUpDate}
                              </p>
                            </div>
                            <div
                              className="card"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%", // Ensure it takes full height of the row
                              }}
                            >
                              <div className="card-header">
                                <h5 className="card-title">
                                  Justification Document
                                </h5>
                              </div>
                              <div
                                className="card-body"
                                style={{
                                  overflowY: "auto", // Make scrollable if content overflows
                                }}
                              >
                                <div className="row">
                                  {attendaceDocuments.map(
                                    (data: {
                                      id: React.Key | null | undefined;
                                      fileType: string;
                                      fileName: any;
                                      filePath: string | URL | undefined;
                                    }) => (
                                      <div
                                        className="col-md-6 mb-4"
                                        key={data.id}
                                      >
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                          {/* File Type Icon */}
                                          <div className="mb-3">
                                            {data.fileType === "pdf" ? (
                                              <ImageWithBasePath
                                                src="assets/img/pdf.png"
                                                className="img-fluid"
                                                alt="Logo"
                                                style={{
                                                  width: "35px",
                                                  height: "auto",
                                                }} // Adjust the width and height as needed
                                              />
                                            ) : data.fileType === "image" ? (
                                              <ImageWithBasePath
                                                src="assets/img/picture.png"
                                                className="img-fluid"
                                                alt="Logo"
                                                style={{
                                                  width: "35px",
                                                  height: "auto",
                                                }} // Adjust the width and height as needed
                                              />
                                            ) : data.fileType === "doc" ? (
                                              <ImageWithBasePath
                                                src="/assets/img/doc.png"
                                                className="img-fluid"
                                                alt="Logo"
                                                style={{
                                                  width: "35px",
                                                  height: "auto",
                                                }} // Adjust the width and height as needed
                                              />
                                            ) : data.fileType === "xls" ? (
                                              <ImageWithBasePath
                                                src="assets/img/xls.png"
                                                className="img-fluid"
                                                alt="Logo"
                                                style={{
                                                  width: "35px",
                                                  height: "auto",
                                                }} // Adjust the width and height as needed
                                              />
                                            ) : null}
                                          </div>
                                          {/* File Details */}
                                          <div className="text-center">
                                            <h6
                                              className="mb-2 text-truncate"
                                              style={{
                                                maxWidth: "150px", // Adjust width as per your layout
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                              }}
                                              title={data.fileName} // Show full name on hover
                                            >
                                              {data.fileName ||
                                                "Placeholder Name"}
                                            </h6>
                                          </div>
                                          {/* Action Buttons */}
                                          <div className="d-flex justify-content-center">
                                            <a
                                              href={data.filePath?.toString()} // File URL
                                              download={data.fileName} // Download file with its name
                                              className="btn btn-outline-primary me-2 btn-sm"
                                            >
                                              Download File
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* Justification document view */}
                            <div className="col-md-12 mt-3">
                              <label htmlFor="note" className="form-label">
                                Note
                              </label>
                              <textarea
                                className="form-control"
                                id="note"
                                rows={3}
                                placeholder="Enter your note here"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                              ></textarea>
                            </div>
                            {fieldErrors.note !== "" ? (
                              <div className="text-danger">
                                {fieldErrors.note}
                              </div>
                            ) : null}
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-cancel"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setNote("");
                                setFieldErrors({ note: "" });
                              }}
                            >
                              Close
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={handleReject}
                            >
                              Reject
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={handleApprove}
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUpRequest;
