export const leadsData = [
  {
    id: 1,
    si_no: "",
    select: "",
    lead_name: "Collins",
    company_name: "NovaWave LLC",
    company_image: "assets/img/icons/company-icon-01.svg",
    company_address: "Newyork, USA",
    phone: "+1 875455453",
    email: "robertson@example.com",
    created_date: "25 Sep 2023, 01:22 pm",
    owner: "Hendry",
    source: "Paid Social",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-01.svg",
    key: "1",
  },
  {
    id: 2,
    si_no: "",
    select: "",
    lead_name: "Konopelski",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "Not connected",
    Action: "",
    image: "assets/img/icons/company-icon-02.svg",
    key: "2",
  },
  {
    id: 3,
    si_no: "",
    select: "",
    lead_name: "Adams",
    company_name: "SilverHawk",
    company_image: "assets/img/icons/company-icon-03.svg",
    company_address: "Jametown, NY",
    phone: "+1 546555455",
    email: "vaughan12@example.com",
    created_date: "04 Oct 2023, 10:18 am",
    owner: "Jami",
    source: "Campaigns",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-03.svg",
    key: "3",
  },
  {
    id: 4,
    si_no: "",
    select: "",
    lead_name: "Schumm",
    company_name: "SummitPeak",
    company_image: "assets/img/icons/company-icon-04.svg",
    company_address: "Compton, RI",
    phone: "+1 454478787",
    email: "jessica13@example.com",
    created_date: "17 Oct 2023, 03:31 pm",
    owner: "Theresa",
    source: "Google",
    status: "Connected",
    Action: "",
    image: "assets/img/icons/company-icon-04.svg",
    key: "4",
  },
  {
    id: 5,
    si_no: "",
    select: "",
    lead_name: "Wisozk",
    company_name: "RiverStone Ventur",
    company_image: "assets/img/icons/company-icon-05.svg",
    company_address: "Dayton, OH",
    phone: "+1 124547845",
    email: "caroltho3@example.com",
    created_date: "24 Oct 2023, 09:14 pm",
    owner: "Espinosa",
    source: "Paid Social",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-05.svg",
    key: "5",
  },
  {
    id: 6,
    si_no: "",
    select: "",
    lead_name: "Heller",
    company_name: "Bright Bridge Grp",
    company_image: "assets/img/icons/company-icon-06.svg",
    company_address: "Lafayette, LA",
    phone: "+1 478845447",
    email: "dawnmercha@example.com",
    created_date: "08 Nov 2023, 09:56 am",
    owner: "Martin",
    source: "Referrals",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-06.svg",
    key: "6",
  },
  {
    id: 7,
    si_no: "",
    select: "",
    lead_name: "Gutkowski",
    company_name: "CoastalStar Co.",
    company_image: "assets/img/icons/company-icon-07.svg",
    company_address: "Centerville, VA",
    phone: "+1 215544845",
    email: "rachel@example.com",
    created_date: "14 Nov 2023, 04:19 pm",
    owner: "Newell",
    source: "Campaigns",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-07.svg",
    key: "7",
  },
  {
    id: 8,
    si_no: "",
    select: "",
    lead_name: "Walter",
    company_name: "HarborView",
    company_image: "assets/img/icons/company-icon-08.svg",
    company_address: "Providence, RI",
    phone: "+1 121145471",
    email: "jonelle@example.com",
    created_date: "23 Nov 2023, 11:14 pm",
    owner: "Janet",
    source: "Google",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-08.svg",
    key: "8",
  },
  {
    id: 9,
    si_no: "",
    select: "",
    lead_name: "Hansen",
    company_name: "Golden Gate Ltd",
    company_image: "assets/img/icons/company-icon-09.svg",
    company_address: "Swayzee, IN",
    phone: "+1 321454789",
    email: "jonathan@example.com",
    created_date: "10 Dec 2023, 06:43 am",
    owner: "Craig",
    source: "Paid Social",
    status: "Closed",
    Action: "",
    image: "assets/img/icons/company-icon-09.svg",
    key: "9",
  },
  {
    id: 10,
    si_no: "",
    select: "",
    lead_name: "Leuschke",
    company_name: "Redwood Inc",
    company_image: "assets/img/icons/company-icon-10.svg",
    company_address: "Florida City, FL",
    phone: "+1 278907145",
    email: "brook@example.com",
    created_date: "25 Dec 2023, 08:17 pm",
    owner: "Daniel",
    source: "Referrals",
    status: "Lost",
    Action: "",
    image: "assets/img/icons/company-icon-10.svg",
    key: "10",
  },
];
