import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { activities_data } from "../../core/data/json/activities";
import Table from "../../core/common/dataTable/index";
import ActivitiesModal from "../../core/modals/activities_modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import apiDefinitions from "../../api/apiDefinitions";
import { Pagination, Form } from "react-bootstrap";

import {
  setActivityTogglePopup,
  setActivityTogglePopupTwo,
} from "../../core/data/redux/commonSlice";
import { all_routes } from "../router/all_routes";
import CollapseHeader from "../../core/common/collapse-header";
import { s } from "@fullcalendar/core/internal-common";

const NotificationsList = () => {
  const data = activities_data;

  const route = all_routes;
  const navigate = useNavigate();
  const hasListLoaded = useRef(false);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [rowData, setRowData] = useState<any>({});
  const hasCountLoaded = useRef(false);
  const [count, setCount] = useState(0);

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const dispatch = useDispatch();
  const activityToggle = useSelector(
    (state: any) => state?.activityTogglePopup
  );
  const activityToggleTwo = useSelector(
    (state: any) => state?.activityToggleTwo
  );

  useEffect(() => {
    if (!hasListLoaded.current) {
      hasListLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      const offset = currentPage - 1;

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getAllNotifications(
            token,
            decodedToken.serial_number,
            offset,
            itemsPerPage
          )
          .then((response: any) => {
            setNotifications(response.data.content);
            setTotalPages(response.data.totalPages);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (!hasCountLoaded.current) {
      hasCountLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getUnreadNotificationsCount(token, decodedToken.serial_number)
          .then((response: any) => {
            if (response.data.code === 200) {
              setCount(response.data.data);
            } else {
              setCount(0);
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  function formatSendDate(dateString: string | number | Date) {
    // Parse the input string into a Date object
    const dateObj = new Date(dateString);

    // Format the date components
    const day = dateObj.toDateString().split(" ")[0]; // e.g., "Wed"
    const month = dateObj.toDateString().split(" ")[1]; // e.g., "Jan"
    const date = dateObj.getDate(); // e.g., 15
    const year = dateObj.getFullYear(); // e.g., 2025
    const time = dateObj.toTimeString().split(" ")[0]; // e.g., "09:36:19"

    // Return the formatted string
    return `${day} ${month} ${date} ${year} ${time}`;
  }

  const handleMarkAsRead = (notificationId: any) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .markTheNotificationAsRead(
            token,
            notificationId,
            decodedToken.serial_number
          )
          .then((response: any) => {
            if (response.status === 200) {
              const updatedNotifications = notifications.map((item) => {
                if (item.id === notificationId) {
                  return {
                    ...item,
                    readStatus: "read",
                  };
                }

                return item;
              });
              setCount(count - 1);

              setNotifications(updatedNotifications);
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">
                      Notifications<span className="count-title">{count}</span>
                    </h4>
                  </div>
                  <div className="col-8 text-end">
                    <div className="head-icons">
                      <CollapseHeader />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="card">
                <div className="card-body">
                  <>
                    {/* Filter */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap mb-4 row-gap-2">
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="d-flex align-items-center flex-wrap row-gap-2">
                          <h4 className="mb-0 me-3">All Notifications</h4>
                        </div>
                      </div>
                    </div>
                    {/* /Filter */}
                  </>

                  {/* Activity List */}
                  <div className="table-responsive">
                    <table className="table text-nowrap table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Title</th>
                          <th scope="col">Page</th>
                          <th scope="col">Priority</th>
                          <th scope="col">Date</th>
                          <th scope="col">Read Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notifications.map(
                          (
                            notification: {
                              title: string;
                              type: keyof typeof route;
                              color: string;
                              icon: string;
                              priority: string;
                              sendDate: string;
                              readStatus: string;
                            },
                            index
                          ) => (
                            <tr key={index}>
                              <td>{notification.title}</td>
                              <td>
                                <Link to={route[notification.type]}>
                                  Redirect
                                </Link>
                              </td>
                              <td>
                                <span
                                  className={`badge bg-soft-${
                                    notification.priority === "Low"
                                      ? "success"
                                      : notification.priority === "Medium"
                                      ? "warning"
                                      : notification.priority === "High"
                                      ? "danger"
                                      : "secondary"
                                  }`}
                                >
                                  {notification.priority}
                                </span>
                              </td>
                              <td>{formatSendDate(notification.sendDate)}</td>
                              <td>
                                <span
                                  className={`badge bg-soft-${
                                    notification.readStatus === "read"
                                      ? "success"
                                      : notification.readStatus === "unread"
                                      ? "warning"
                                      : "secondary"
                                  }`}
                                >
                                  {notification.readStatus === "read"
                                    ? "Read"
                                    : notification.readStatus === "unread"
                                    ? "Not Read"
                                    : "N/A"}
                                </span>
                              </td>
                              <td>
                                <div className="dropdown table-action">
                                  <Link
                                    to="#"
                                    className="action-icon "
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="fa fa-ellipsis-v"></i>
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#view_activity"
                                      onClick={() => setRowData(notification)}
                                    >
                                      <i className="ti ti-eye  text-blue" />{" "}
                                      View
                                    </Link>
                                    <Link
                                      className="dropdown-item"
                                      to="#"
                                      data-bs-toggle="modal"
                                      data-bs-target="#make_as_read_activity"
                                      onClick={() => setRowData(notification)}
                                    >
                                      <i className="ti ti-check text-success" />{" "}
                                      Marks As Read
                                    </Link>
                                    {/* <Link
                                    className="dropdown-item"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_activity"
                                  >
                                    <i className="ti ti-trash text-danger" />{" "}
                                    Delete
                                  </Link> */}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination className="mt-3 justify-content-center">
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, index) => (
                      <Pagination.Item
                        key={index + 1}
                        active={index + 1 === currentPage}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="datatable-length" />
                    </div>
                    <div className="col-md-6">
                      <div className="datatable-paginate" />
                    </div>
                  </div>
                  {/* /Activity List */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end offcanvas-large"
          tabIndex={-1}
          id="offcanvas_edit"
        >
          <div className="offcanvas-header border-bottom">
            <div>
              <h4 className="mb-2">Title</h4>
              <p>Date and time : 15 Sep 2023, 11:15 pm</p>
            </div>
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i className="ti ti-x" />
            </button>
          </div>
          <div className="offcanvas-body">
            <form>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  data-bs-dismiss="offcanvas"
                  className="btn btn-light me-2"
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Mark As Read
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="modal fade" id="delete_activity" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                    <i className="ti ti-trash-x fs-36 text-danger" />
                  </div>
                  <h4 className="mb-2">Remove Activity?</h4>
                  <p className="mb-0">
                    Are you sure you want to remove <br /> the notification you
                    selected.
                  </p>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <Link
                      to="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-danger"
                    >
                      Yes, Delete it
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="make_as_read_activity" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                    <i className="ti ti-checks fs-36 text-success" />
                  </div>
                  <h4 className="mb-2">Mark as Read?</h4>
                  <p className="mb-0">
                    Are you sure you want to mark as read <br /> the
                    notification you selected.
                  </p>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <Link
                      to="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </Link>
                    <Link
                      to="#"
                      data-bs-dismiss="modal"
                      className="btn btn-success"
                      onClick={() => {
                        handleMarkAsRead(rowData.id);
                      }}
                    >
                      Mark as Read
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="view_activity" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                    <i className="ti ti-bell fs-36 text-warning" />
                  </div>
                  <h4 className="mb-3">{rowData.title}</h4>
                  <p className="d-flex justify-content-between align-items-center">
                    <span>{formatSendDate(rowData.sendDate)}</span>
                  </p>
                  <p className="mb-0">{rowData.message}</p>
                  <p></p>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <Link
                      to="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default NotificationsList;
