import React, { useState, useRef, useEffect } from "react";

interface FileUploadProps {
  onFileSelect: (files: File[] | null) => void; // Callback to send multiple files to the parent
  clear?: boolean; // Optional clear trigger
}

const SingleFileUpload: React.FC<FileUploadProps> = ({ onFileSelect, clear }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]); // State for single file
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Handle file input change
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ? [e.target.files[0]] : []; // Allow only one file
    setSelectedFiles(files);
    onFileSelect(files); // Pass files to the parent
  };

  useEffect(() => {
    if (clear && fileInputRef.current) {
      fileInputRef.current.value = "";
      setSelectedFiles([]); // Clear the file state

      console.log("FileUpload: Clearing files");
    }
  }, [clear]);

  return (
    <div className="mb-3">
      <label className="form-label">Upload Justification of Budget</label>
      <div className="input-group">
        <input
          type="file"
          className="form-control"
          id="fileUpload"
          accept=".pdf, image/*, .doc, .docx, .xls, .xlsx, .csv"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        <label className="input-group-text" htmlFor="fileUpload">
          Choose File
        </label>
      </div>

      {/* Display Selected File Details */}
      {selectedFiles.length > 0 && (
        <div className="mt-2">
          <div className="border rounded p-2 bg-light">
            <p className="mb-1">
              <strong>Selected File:</strong>
            </p>
            <ul className="mb-0">
              {selectedFiles.map((file, index) => (
                <li key={index}>
                  {file.name} - {(file.size / 1024).toFixed(2)} KB
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleFileUpload;
