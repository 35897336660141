export const activities_data = [
  {
    id: 1,
    checkbox: "",
    title: "We scheduled a meeting for next week",
    icon: "ti ti-user-share",
    activity_type: "Meeting",
    color: "bg-purple",

    due_date: "25 Sep 2023, 12:12 pm",
    owner: "Hendry",
    created_date: "22 Sep 2023, 10:14 am",
    status: "0",
    action: "",
    key: "1"
  },
  {
    id: 2,
    checkbox: "",
    title: "Had conversation with Fred regarding task",
    activity_type: "Calls",
    color: "bg-green",
    icon: "ti ti-phone",
    due_date: "29 Sep 2023, 04:12 pm",
    owner: "Monty Beer",
    created_date: "27 Sep 2023, 03:26 pm",
    status: "3",
    action: "",
    key: "2"
  },
  {
    id: 3,
    checkbox: "",
    title: "Analysing latest time estimation for new project",
    activity_type: "Email",
    color: "bg-warning",
    icon: "ti ti-mail",
    due_date: "11 Oct 2023, 05:00 pm",
    owner: "Bradtke",
    created_date: "03 Oct 2023, 03:53 pm",
    status: "1",
    action: "",
    key: "3"
  },
  {
    id: 4,
    checkbox: "",
    title: "Store and manage contact data",
    activity_type: "Task",
    icon: "ti ti-subtask",
    color: "bg-blue",
    due_date: "19 Oct 2023, 02:35 pm",
    owner: "Swaniawski",
    created_date: "14 Oct 2023, 01:25 pm",
    status: "2",
    action: "",
    key: "4"
  },
  {
    id: 5,
    checkbox: "",
    title: "Will have a meeting before project start",
    icon: "ti ti-user-share",
    activity_type: "Meeting",
    color: "bg-purple",
    due_date: "27 Oct 2023, 12:30 pm",
    owner: "Sally",
    created_date: "21 Oct 2023, 03:00 pm",
    status: "0",
    action: "",
    key: "5"
  },
  {
    id: 6,
    checkbox: "",
    title: "Call John and discuss about project",
    activity_type: "Calls",
    color: "bg-green",
    icon: "ti ti-phone",
    due_date: "12 Nov 2023, 10:20 pm",
    owner: "Itzel",
    created_date: "02 Nov 2023, 05:35 pm",
    status: "3",
    action: "",
    key: "6"
  },
  {
    id: 7,
    checkbox: "",
    title: "Built landing pages",
    activity_type: "Task",
    icon: "ti ti-subtask",
    color: "bg-blue",
    due_date: "25 Nov 2023, 01:40 pm",
    owner: "Danny",
    created_date: "20 Nov 2023, 08:20 am",
    status: "2",
    action: "",
    key: "7"
  },
  {
    id: 8,
    checkbox: "",
    title: "Regarding latest updates in project",
    activity_type: "Email",
    color: "bg-warning",
    icon: "ti ti-mail",
    due_date: "30 Nov 2023, 09:20 pm",
    owner: " Lynch",
    created_date: "25 Nov 2023, 07:20 pm",
    status: "1",
    action: "",
    key: "8"
  },
  {
    id: 9,
    checkbox: "",
    title: "Discussed budget proposal with Edwin",
    activity_type: "Calls",
    color: "bg-green",
    icon: "ti ti-phone",
    due_date: "08 Dec 2023, 04:35 pm",
    owner: "Merwin",
    created_date: "01 Dec 2023, 10:45 am",
    status: "3",
    action: "",
    key: "9"
  },
  {
    id: 10,
    checkbox: "",
    title: "Attach final proposal for upcoming project",
    activity_type: "Email",
    color: "bg-warning",
    icon: "ti ti-mail",
    due_date: "19 Dec 2023, 02:20 pm",
    owner: " Andrew",
    created_date: "10 Dec 2023, 06:30 pm",
    status: "1",
    action: "",
    key: "10"
  },
  {
    id: 11,
    checkbox: "",
    title: "Discussed budget proposal with Edwin",
    icon: "ti ti-user-share",
    activity_type: "Meeting",
    color: "bg-purple",
    due_date: "26 Dec 2023, 08:30 am",
    owner: "Clausen",
    created_date: "18 Dec 2023, 05:00 pm",
    status: "3",
    action: "",
    key: "11"
  },
];
