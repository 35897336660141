import axios from "axios";

/* UAT Backend */
// const BaseURL = "http://uat.kiu.lk:5001/api";

/* Production HR Backend */
const BaseURL2 = "https://hr-backend.kiu.lk/people";

/* Development Backend */
// const BaseURL = "http://192.168.220.156:8080/api";

/* Production Backend */
const BaseURL = "https://crm-backend.kiu.lk/api";

export const api = axios.create({
  baseURL: BaseURL,
});

export const hrApi = axios.create({
  baseURL: BaseURL2,
});
