import { api } from "./api";
import { hrApi } from "./api";

const apiDefinitions = {
  login: async function (payload) {
    return await api.post(`/auth/login`, payload);
  },

  //Leads API Definitions Start
  createLead: async function (token, employeeId, payload) {
    return await api.post(`leads/create_new_lead/${employeeId}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllLeads: async function (token, offSet, count) {
    const url = `leads/get_all_leads?off_set=${offSet}&count=${count}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getMyLeads: async function (
    token,
    offSet,
    count,
    serialNumber,
    searchKey,
    priority,
    status
  ) {
    const url = `leads/get_all_leads_by_user_id/${serialNumber}?keyword=${searchKey}&leadPriorityLevelId=${priority}&leadStatus=${status}&offset=${offSet}&pageSize=${count}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getAllLeadsAndSearch: async function (
    token,
    offSet,
    count,
    searchKey,
    priority,
    status
  ) {
    const url = `leads/search/${offSet}/${count}?keyword=${searchKey}&leadPriorityLevelId=${priority}&leadStatus=${status}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getLeadById: async function (token, leadId) {
    return await api.get(`/leads/get_lead/${leadId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  leadAproval: async function (token, leadId, action, rejectBy, note) {
    return await api.post(
      `/leads/assign_leads/${leadId}?action=${action}&approveRejectBy=${rejectBy}&Note=${note}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getAllPendingLeadsAndSearch: async function (
    token,
    offSet,
    count,
    searchKey,
    priority,
    status
  ) {
    const url = `leads/getAll/assign-pending?keyword=${searchKey}&leadPriorityLevelId=${priority}&leadStatus=${status}&offset=${offSet}&pageSize=${count}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getCoursesByCategoryId: async function (token, categoryId) {
    return await api.get(
      `/leads/courses/get_all_courses/category/${categoryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getAllCoursesCategories: async function (token) {
    return await api.get(`/leads/course_categories/get_all_course_categories`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  updateLead: async function (token, employeeId, leadId, payload) {
    return await api.put(
      `/leads/update_lead/${employeeId}/${leadId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getMarketingEmployeeList: async function (token) {
    return await hrApi.get(`/get-marketing-list-for-crm`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  // getNewMarketingEmployeeList: async function () {
  //   return await api.get(`/get-marketing-list-for-crm`);
  // },

  validateNic: async function (token, nic) {
    return await api.get(`/leads/exists_by_nic?nic=${nic}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  //Leads API Definitions End

  //Call Management API Definitions Start
  getCallHistory: async function (token, offSet, count) {
    const url = `contact_history/get_all?off_set=${offSet}&count=${count}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getAllCampaignTypes: async function (token) {
    const url = `campaigns/GetAllCampaignTypes`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getCampaignSubTypesById: async function (token, categoryId) {
    const url = `campaigns/GetCampaignSubTypes/${categoryId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getAllCampaign: async function (token, searchKey, campaignTypeId, status) {
    const url = `campaigns/SearchCampaigns?keyword=${searchKey}&campaignTypeId=${campaignTypeId}&status=${status}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  createCampaign: async function (token, employeeId, payload) {
    return await api.post(`/campaigns/CreateCampaign/${employeeId}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllCampaignCount: async function (token) {
    const url = `campaigns/CountAllCampaigns`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getAactiveInactiveCount: async function (token) {
    const url = `campaigns/CountActiveAndInactiveCampaigns`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  //Call Management API Definitions End

  //Dashboard API Definitions Start
  getLeadCountByYear: async function (token, year) {
    const url = `leads/get_lead_count_by_year?year=${year}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getLeadPercentageBySource: async function (token, days) {
    const url = `/leads/get_lead_percentage_by_source?days=${days}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getLeadStatusCount: async function (token, days) {
    const url = `/leads/get_lead_count_by_status_and_date_range?days=${days}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getLeadsBySocialMedia: async function (token, days) {
    const url = `/leads/get_lead_count_by_social_media_type?days=${days}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getTopFiveCourses: async function (token, days) {
    const url = `/leads/top-five-courses?days=${days}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getLeadCount: async function (token) {
    const url = `/leads/count_all_leads`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getTodayLeadCount: async function (token) {
    const url = `/leads/count_leads_added_today`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getEnrolledLeadCount: async function (token) {
    const url = `/leads/count_leads_by_enrolled_status`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getIncompletedLeadCount: async function (token) {
    const url = `/leads/count_incomplete_leads`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getCampaignStatusCount: async function (token) {
    const url = `/campaigns/GetCampaignStatusCounts`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getActiveCampaigns: async function (token) {
    const url = `/campaigns/GetAllCampaignNamesAndLeadCounts`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getCampaignsByChannel: async function (token) {
    const url = `/campaigns/get_campaigns_by_channel`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getCampainsByMonth: async function (token, year) {
    const url = `/campaigns/get_campaigns_by_month/${year}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },
  getLeadVariationByYear: async function (token, year) {
    const url = `/leads/countByCampaignAndMonth?year=${year}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  //Dashboard API Definitions End

  //Budget API Definitions Start
  createBudget: async function (token, payload) {
    return await api.post(`/budgets/request_new_budget`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getApprovedBudgets: async function (token) {
    return await api.get(`/budgets/approved_getAll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getNotApprovedBudgets: async function (token) {
    return await api.get(`/budgets/not-approved_getAll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getJustificationByBudgetID: async function (token, budgetId) {
    return await api.get(`/budgets/get_file_justifications/${budgetId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  requestTopUpBudget: async function (token, id, payload) {
    return await api.post(
      `/budget/top_up/request_topUp_budget/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getTopUpBudgets: async function (token, id) {
    return await api.get(`/budget/top_up/get_all_topUp_budgets/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getCampaignsByAnualBudget: async function (token, year) {
    return await api.get(`/campaigns/get_all_campaigns/byYear?year=${year}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getBudgetByBudgetId: async function (token, budgetId) {
    return await api.get(`/budgets/get_all_budgets/${budgetId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  deleteBudget: async function (token, budgetId) {
    return await api.delete(`/budgets/delete_Budget/${budgetId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getAllPendingBudgets: async function (token, status) {
    return await api.get(
      `/budgets/get_all_budgets/pending?statusId=${status}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  approveBudget: async function (token, payload) {
    return await api.post(`/budgets/approve_reject_year_budget`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  getAllPendingTopUpBudgets: async function (token) {
    return await api.get(`/budget/top_up/get_all_topUp_budgets_pending`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
  approveTopUpBudget: async function (token, payload) {
    return await api.post(
      `/budget/top_up/approve_reject_TopUp_budget`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getBudgetDifference: async function (token, year) {
    return await api.get(`/budgets/campaign_total/difference?year=${year}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  //Campaign API Definitions Start
  getChannelTypes: async function (token, channelId) {
    const url = `/campaigns/GetChannelTypes/${channelId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getChannelSubTypes: async function (token, channelSubTypeId) {
    const url = `/campaigns/GetChannelSubTypes/${channelSubTypeId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getTargetAudience: async function (token) {
    const url = `/campaigns/target-audience/getAll`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  getCampaignDetailsById: async function (token, campaignId) {
    const url = `campaigns/GetCampaign/${campaignId}`;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return await api.get(url, { headers });
  },

  createExpenses: async function (token, campaignId, payload) {
    return await api.post(
      `/campaigns/apply_campaign_expenses/${campaignId}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  topUpCampaign: async function (token, campaignId, payload) {
    return await api.post(`/campaignsTopUp/create/${campaignId}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getExpensesTypes: async function (token) {
    return await api.get(`/campaigns/get_all_campaigns/expensesTypes`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getPendingCampaignApprovals: async function (token) {
    return await api.get(`/campaigns/get_all_campaigns/pendingStatus`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  campaignApprovals: async function (
    token,
    campaignId,
    action,
    approveRejectBy,
    note
  ) {
    return await api.post(
      `/campaigns/assign_campaigns/${campaignId}?action=${action}&approveRejectBy=${approveRejectBy}&Note=${note}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  expensesApproval: async function (token, expensesId, approvedBy) {
    return await api.post(
      `/campaigns/expenses/approve/expensesId?approvedBy=${approvedBy}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  expensesRejection: async function (token, expensesId, approvedBy) {
    return await api.post(
      `/campaigns/expenses/reject/expensesId?approvedBy=${approvedBy}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getTopUpList: async function (token, campaignId) {
    return await api.get(`/campaignsTopUp/get_all/${campaignId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getExpensesListById: async function (token, campaignId) {
    return await api.get(`/campaigns/get_campaign_expenses/${campaignId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  deleteExpensesById: async function (token, campaignId) {
    return await api.delete(
      `/campaigns/delete_campaign_expenses/${campaignId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getAllPendingExpenses: async function (token) {
    return await api.get(`/campaigns/get_campaign_expenses/pendingStatus`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllActiveCampaigns: async function (token) {
    return await api.get(`/campaigns/active/getAll`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllCompletedCampaigns: async function (token) {
    return await api.get(`/campaigns/getAllStatus/completed`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getCampaignSummary: async function (token, campaignId) {
    return await api.get(
      `/campaigns/get_campaign_expenses/summary/${campaignId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getJustificationForCampaignById: async function (token, expensesId) {
    return await api.get(
      `/campaigns/expenses/file-justifications/${expensesId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  // getAllPendingTopUp: async function (token, expensesId) {
  //   return await api.get(
  //     `/campaigns/expenses/file-justifications/${expensesId}`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }
  //   );
  // },

  campaignExpensesApproval: async function (
    token,
    expenseId,
    action,
    approveBy,
    note
  ) {
    return await api.post(
      `/campaigns/expenses/approveReject/${expenseId}?action=${action}&approveRejectBy=${approveBy}&Note=${note}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  deleteTopUp: async function (token, topUpId) {
    return await api.delete(`/campaignsTopUp/delete/${topUpId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  viewTopUpJustification: async function (token, topUpId) {
    return await api.get(`/campaignsTopUp/topUpFileJustifications/${topUpId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  topUpPendingGetAll: async function (token, pendingStatus) {
    return await api.get(`/campaignsTopUp/get_all_pending/${pendingStatus}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  campaignTopUpApproval: async function (
    token,
    topUpId,
    action,
    topUpApproveRejectBy,
    Note
  ) {
    return await api.post(
      `/campaignsTopUp/campaign_budget/${topUpId}?action=${action}&topUpApproveRejectBy=${topUpApproveRejectBy}&Note=${Note}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  //Campaign API Definitions End

  //Notification API Definitions Start
  getNotifications: async function (token, userId, page, size) {
    return await api.get(
      `/notifications/unread?userId=${userId}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getAllNotifications: async function (token, userID, page, size) {
    return await api.get(
      `/notifications/get_all?userId=${userID}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  markTheNotificationAsRead: async function (token, notificationId, userId) {
    return await api.post(
      `/notifications/markAsRead?notificationId=${notificationId}&userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },
  getUnreadNotificationsCount: async function (token, userId) {
    return await api.get(`/notifications/count_unread/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getProfilePic: async function (userId) {
    return await hrApi.get(
      `/get-all-employee-detail-by-serial-number/${userId}`,
      {}
    );
  },

  getMarketingList: async function () {
    return await hrApi.get(`/get-marketing-list-for-crm`, {});
  },

  getAssignedCampaignInformation: async function (token, employeeId) {
    return await api.get(`/campaigns/get_campaigns/${employeeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getAllSubBudgetsByBudgetId: async function (token, budgetId, page, size) {
    return await api.get(`/subBudgets/${budgetId}?page=${page}&size=${size}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default apiDefinitions;
