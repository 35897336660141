import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ImageWithBasePath from "../imageWithBasePath";
import { all_routes } from "../../../feature-module/router/all_routes";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
} from "../../data/redux/commonSlice";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import apiDefinitions from "../../../api/apiDefinitions";

const Header = () => {
  const route = all_routes;
  const location = useLocation();
  const dispatch = useDispatch();
  interface Notification {
    id: number;
    title: string;
    message: string;
    sendDate: string;
    type: string;
    // Add other properties as needed
  }

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [count, setCount] = useState<number>(0);
  const hasListLoaded = React.useRef(false);
  const hasCountLoaded = React.useRef(false);
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState("");
  const [profilePic, setProfilePic] = useState("");

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const mobileSidebar = useSelector((state: any) => state.mobileSidebar);
  const miniSidebar = useSelector((state: any) => state.miniSidebar);

  const toggleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const toggleMiniSidebar = () => {
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const toggleExpandMenu = () => {
    dispatch(setExpandMenu(true));
  };
  const toggleExpandMenu2 = () => {
    dispatch(setExpandMenu(false));
  };

  useEffect(() => {
    if (!hasListLoaded.current) {
      hasListLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getNotifications(token, decodedToken.serial_number, 0, 3)
          .then((response: any) => {
            setNotifications(response.data.content);
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    if (!hasCountLoaded.current) {
      hasCountLoaded.current = true;
      return;
    }

    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      navigate(route.login);
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions
          .getUnreadNotificationsCount(token, decodedToken.serial_number)
          .then((response: any) => {
            if (response.data.code === 200) {
              setCount(response.data.data);
            } else {
              setCount(0);
            }
          });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
      navigate(route.login);
    }
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      setEmployeeId(decodedToken.serial_number);
      // setEmployeeName(decodedToken.name);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    if (employeeId) {
      apiDefinitions
        .getProfilePic(employeeId)
        .then((response: any) => {
          if (response.data.code === 200) {
            setProfilePic(response.data.data.profilePictureUrl);
          }
        })
        .catch((error) => {
          console.error("Failed to fetch profile picture", error);
        });
    }
  }, [employeeId]);

  useEffect(() => {
    console.log("notifications", notifications[0]?.message);
  }, [notifications]);

  const [layoutBs, setLayoutBs] = useState(localStorage.getItem("dataTheme"));
  const isLockScreen = location.pathname === "/lock-screen";

  if (isLockScreen) {
    return null;
  }
  const LayoutDark = () => {
    localStorage.setItem("dataTheme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
    setLayoutBs("dark");
  };
  const LayoutLight = () => {
    localStorage.setItem("dataTheme", "light");
    document.documentElement.setAttribute("data-theme", "light");
    setLayoutBs("light");
  };

  const handleLogout = () => {
    localStorage.setItem("logout", Date.now().toString());

    // Redirect to the login page or show logged-out state
    window.location.href = "/login";
    sessionStorage.removeItem("token");
  };

  return (
    <>
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div
          className="header-left active"
          onMouseEnter={toggleExpandMenu}
          onMouseLeave={toggleExpandMenu2}
          style={{ marginTop: "1px" }}
        >
          <Link to={route.leadsDashboard} className="logo logo-normal">
            {/* {layoutBs === "dark" ? (
              <>
                <ImageWithBasePath
                  src="assets/img/white-logo.svg"
                  className="white-logo"
                  alt="Logo"
                />
              </>
            ) : (
              <ImageWithBasePath src="assets/img/logo.svg" alt="Logo" />
            )} */}
            <ImageWithBasePath
              src="/assets/img/NEW_KIU_LOGO_2.png"
              alt="Logo"
              className="new-logo"
            />
            <ImageWithBasePath
              src="/assets/img/NEW_KIU_LOGO_2.png"
              className="white-logo"
              alt="Logo"
            />
          </Link>
          <Link to={route.leadsDashboard} className="logo-small">
            <ImageWithBasePath src="/assets/img/KIU_FAVICON.png" alt="Logo" />
          </Link>
          <Link id="toggle_btn" to="#" onClick={toggleMiniSidebar}>
            <i className="ti ti-arrow-bar-to-left" />
          </Link>
        </div>
        {/* /Logo */}
        <Link
          id="mobile_btn"
          className="mobile_btn"
          to="#sidebar"
          onClick={toggleMobileSidebar}
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <ul className="nav user-menu" style={{ justifyContent: "flex-end" }}>
            {/* Notifications */}
            <li className="nav-item dropdown nav-item-box">
              <Link to="#" className="nav-link" data-bs-toggle="dropdown">
                <i className="ti ti-bell" />
                <span className="badge rounded-pill">{count}</span>
              </Link>
              <div className="dropdown-menu dropdown-menu-end notification-dropdown">
                <div className="topnav-dropdown-header">
                  <h4 className="notification-title">Notifications</h4>
                </div>
                <div className="noti-content">
                  <ul className="notification-list">
                    {notifications && notifications.length >= 1 ? (
                      notifications.slice(0, 3).map((notification, index) => (
                        <li className="notification-message" key={index}>
                          <Link to={route.Notification}>
                            <div className="media d-flex">
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  {notification.title}
                                </p>
                                <p className="noti-sub-details">
                                  {notification.message}
                                </p>
                                <p className="noti-time">
                                  {notification.sendDate}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      ))
                    ) : (
                      <li className="notification-message">
                        <div className="media d-flex">
                          <div className="media-body flex-grow-1">
                            <p className="noti-details">No new notifications</p>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="topnav-dropdown-footer">
                  <Link to={route.Notification} className="view-link">
                    View all
                  </Link>
                  {/* <Link to="#" className="clear-link">
                    Clear all
                  </Link> */}
                </div>
              </div>
            </li>
            {/* Profile Dropdown */}
            <li
              className="nav-item dropdown has-arrow main-drop"
              style={{ marginRight: "20px" }}
            >
              <Link
                to="#"
                className="nav-link userset"
                data-bs-toggle="dropdown"
              >
                <span className="user-info">
                  <span className="user-letter">
                    <img
                      src={profilePic}
                      alt="Prof"
                      className="profile-image-rect"
                      style={{
                        width: "50px",
                        height: "35px",
                        objectFit: "cover",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                      }}
                    />
                  </span>

                  <span className="badge badge-success rounded-pill" />
                </span>
              </Link>
              <div className={`dropdown-menu menu-drop-user`}>
                <div className="profilename">
                  <Link className="dropdown-item" to={route.leadsDashboard}>
                    <i className="ti ti-layout-2" /> Dashboard
                  </Link>
                  <Link className="dropdown-item" to={route.profile}>
                    <i className="ti ti-user-pin" /> My Profile
                  </Link>
                  <Link
                    className="dropdown-item"
                    to={route.login}
                    onClick={handleLogout}
                  >
                    <i className="ti ti-lock" /> Logout
                  </Link>
                </div>
              </div>
            </li>
          </ul>
        </div>

        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className={` dropdown-menu `}>
            <Link className="dropdown-item" to={route.leadsDashboard}>
              <i className="ti ti-layout-2" /> Dashboard
            </Link>
            <Link className="dropdown-item" to={route.profile}>
              <i className="ti ti-user-pin" /> My Profile
            </Link>
            <Link className="dropdown-item" to={route.login}>
              <i className="ti ti-lock" /> Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
    </>
  );
};

export default Header;
