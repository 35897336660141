import React, { useEffect, useState } from "react";
import apiDefinitions from "../../../api/apiDefinitions";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import Select, { MultiValue } from "react-select";
import Swal from "sweetalert2";
import toast from "react-hot-toast";

const Analytics = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [nic, setNic] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [sourceOfInquiry, setSourceOfInquiry] = useState("");
  const [preferredDegree, setPreferredDegree] = useState("");
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [representativeName, setRepresentativeName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [leadStatus, setLeadStatus] = useState("1");
  const [priority, setPriority] = useState("3");
  const [followUpDate, setFollowUpDate] = useState("");
  const [note, setNote] = useState("");
  const [campaignList, setCampaignList] = useState<Category[]>([]);
  const [employeeId, setEmployeeId] = useState("");
  const [socialMediaType, setSocialMediaType] = useState("");

  const [isFullNameInvalid, setIsFullNameInvalid] = useState(false);
  const [isNicInvalid, setIsNicInvalid] = useState(false);
  const [nicError, setNicError] = useState("");

  const [isCampaignInvalid, setIsCampaignInValid] = useState(false);
  const [isContactNumberInvalid, setIsContactNumberInvalid] = useState(false);
  const [isEmailAddressInvalid, setIsEmailAddressInvalid] = useState(false);
  const [isSourceOfInquiryInvalid, setIsSourceOfInquiryInvalid] =
    useState(false);
  const [isPreferredDegreeInvalid, setIsPreferredDegreeInvalid] =
    useState(false);
  const [isPreferredCourseInvalid, setIsPreferredCourseInvalid] =
    useState(false);
  const [isLeadStatusInvalid, setIsLeadStatusInvalid] = useState(false);
  const [isPriorityInvalid, setIsPriorityInvalid] = useState(false);
  const [isFollowUpDateInvalid, setIsFollowUpDateInvalid] = useState(false);
  const [isRepresentativeNameInvalid, setIsRepresentativeNameInvalid] =
    useState(false);
  const [isSocialMediaTypeInvalid, setIsSocialMediaTypeInvalid] =
    useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [coursesList, setCoursesList] = useState<any[]>([]);
  const [coursesCategoryList, setCoursesCategoryList] = useState<any[]>([]);
  const [prorityLevelStatus, setPriorityLevelStatus] = useState(false);

  const [isNicAvailable, setIsNicAvailable] = useState(false);
  const [leadNumber, setLeadNumber] = useState("");

  interface FormValues {
    fullName: string;
    contactNumber: string;
    emailAddress: string;
    sourceOfInquiry: string;
    preferredDegree: string;
    preferredCourse: string;
    leadStatus: string;
    priority: string;
    followUpDate: string;
    note: string;
    nic: string;
    representativeName: string;
    socialMediaType: string;
  }

  interface Category {
    id: number;
    name: string;
  }

  interface FormValidation {
    isFullNameInvalid: boolean;
    isContactNumberInvalid: boolean;
    isEmailAddressInvalid: boolean;
    isSourceOfInquiryInvalid: boolean;
    isPreferredDegreeInvalid: boolean;
    isPreferredCourseInvalid: boolean;
    isLeadStatusInvalid: boolean;
    isPriorityInvalid: boolean;
    isFollowUpDateInvalid: boolean;
    isNicInvalid: boolean;
    isRepresentativeNameInvalid: boolean;
    prorityLevelStatus: boolean;
    isSocialMediaTypeInvalid: boolean;
  }

  interface Option {
    value: number;
    label: string;
  }

  interface DecodedToken {
    exp: number;
    [key: string]: any;
  }

  const options: Option[] = coursesList.map((course) => ({
    value: course.course_id,
    label: course.course_name,
  }));

  const validateNic = (value: string) => {
    if (value.length === 12) {
      setIsNicInvalid(false);
    } else if (
      value.length === 10 &&
      (value.endsWith("v") || value.endsWith("V"))
    ) {
      setIsNicInvalid(false);
    } else {
      setIsNicInvalid(true);
    }
  };

  const handleNicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNic(value);
    validateNic(value);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllActiveCampaigns(token).then((response: any) => {
          console.log("Campaign Categories: ", response.data.data);
          setCampaignList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    const today = new Date();
    const followUp = new Date(today);
    followUp.setDate(today.getDate() + 3);
    const formattedDate = followUp.toISOString().split("T")[0];
    setFollowUpDate(formattedDate);
  }, []);

  const handleBack = () => {
    setIsNicAvailable(false);
    setNic("");
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const onlyLetters = /^[a-zA-Z\s]*$/;

    if (onlyLetters.test(input)) {
      setFullName(input); // Update the state only if the input contains valid characters
    }
  };

  const handleContactNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    const onlyNumbers = /^[0-9]*$/;

    if (onlyNumbers.test(input)) {
      setContactNumber(input); // Update the state only if the input contains valid numbers
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let isValid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (fullName === "") {
      setIsFullNameInvalid(true);
      isValid = false;
    } else {
      setIsFullNameInvalid(false);
    }

    if (contactNumber === "") {
      isValid = false;
      setIsContactNumberInvalid(true);
    } else if (contactNumber.length < 10) {
      isValid = false;
      setIsContactNumberInvalid(true);
    } else if (contactNumber.length > 10) {
      isValid = false;
      setIsContactNumberInvalid(true);
    } else {
      setIsContactNumberInvalid(false);
    }

    if (!emailRegex.test(emailAddress)) {
      isValid = false;
      setIsEmailAddressInvalid(true);
    } else {
      setIsEmailAddressInvalid(false);
    }

    if (sourceOfInquiry === "" || sourceOfInquiry === "Select an option") {
      setIsSourceOfInquiryInvalid(true);
      isValid = false;
    } else {
      setIsSourceOfInquiryInvalid(false);
    }

    if (preferredDegree === "" || preferredDegree === "Select an option") {
      setIsPreferredDegreeInvalid(true);
      isValid = false;
    } else {
      setIsPreferredDegreeInvalid(false);
    }

    if (nic === "") {
      setIsNicInvalid(true);
      isValid = false;
    } else {
      setIsNicInvalid(false);
    }

    if (preferredDegree !== "" && selectedCourses.length === 0) {
      setIsPreferredCourseInvalid(true);
      toast.error("Please select a preferred course");
      isValid = false;
    } else {
      setIsPreferredCourseInvalid(false);
    }

    if (leadStatus === "" || leadStatus === "Select an option") {
      setIsLeadStatusInvalid(true);
      isValid = false;
    } else {
      setIsLeadStatusInvalid(false);
    }

    if (priority === "" || priority === "Select an option") {
      setIsPriorityInvalid(true);
      isValid = false;
    } else {
      setIsPriorityInvalid(false);
    }

    if (followUpDate === "") {
      setIsFollowUpDateInvalid(true);
      isValid = false;
    } else {
      setIsFollowUpDateInvalid(false);
    }

    if (sourceOfInquiry === "2" && representativeName === "") {
      setIsRepresentativeNameInvalid(true);
      console.log("Representative Name is invalid");
      isValid = false;
    } else {
      setIsRepresentativeNameInvalid(false);
      console.log(representativeName, "Representative Name is valid");
    }

    if (sourceOfInquiry === "5" && selectedCampaign === "") {
      setIsCampaignInValid(true);
      isValid = false;
    } else {
      setIsCampaignInValid(false);
    }

    if (sourceOfInquiry === "4" && socialMediaType === "") {
      setIsSocialMediaTypeInvalid(true);
      isValid = false;
    } else {
      setIsSocialMediaTypeInvalid(false);
    }

    if (isValid) {
      const payload = {
        fullname: fullName.toUpperCase(),
        contactNumber: contactNumber,
        email: emailAddress.toLowerCase(),
        sourceOfInquiry: {
          id: sourceOfInquiry,
        },
        social_media_type: socialMediaType,
        courseCategoryId: preferredDegree,
        courseId: selectedCourses,
        leadStatus: {
          id: leadStatus,
        },
        leadPriorityLevelId: priority,
        assignedBy: employeeId,
        followUpDate: followUpDate,
        campaign: {
          id: selectedCampaign,
        },
        note: note,
        nic: nic,
      };

      console.log("Form is valid", payload);

      Swal.fire({
        title: "Do You Want To Add This Lead?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Add",
        denyButtonText: `Do Not Add`,
      }).then((result) => {
        if (result.isConfirmed) {
          const token = sessionStorage.getItem("token");
          if (!token) {
            console.error("No token found. Please log in again.");
            return;
          }

          // Decode token and check expiration
          try {
            const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
            const currentTime = Math.floor(Date.now() / 1000);

            console.log("currentTime", currentTime);

            const loading = toast.loading("Adding Lead...");

            if (decodedToken.exp && decodedToken.exp > currentTime) {
              // Token is valid, proceed with the API call
              apiDefinitions
                .createLead(token, employeeId, payload)
                .then((response: any) => {
                  console.log("Response", response);
                  if (response.data.code === 201) {
                    toast.success("Lead Added Successfully");
                    toast.dismiss(loading);
                    setFullName("");
                    setContactNumber("");
                    setEmailAddress("");
                    setSourceOfInquiry("");
                    setPreferredDegree("");
                    setSelectedCourses([]);
                    setLeadStatus("1");
                    setPriority("3");
                    setNote("");
                    setNic("");
                    setIsNicAvailable(false);
                    setSocialMediaType("");
                  } else if (response.data.code === 409) {
                    toast.error("Lead Already Exists");
                    toast.dismiss(loading);
                  } else {
                    toast.error("Failed to add lead");
                    toast.dismiss(loading);
                  }
                });
            } else {
              console.log("Session expired. Please log in again.");
              navigate(route.login);
            }
          } catch (error) {
            console.error("Invalid token or unable to decode", error);
          }
        } else {
          toast("Lead Was Not Added", {
            icon: "⚠️",
          });
        }
      });

      // Retrieve the token from sessionStorage
    }
  };

  const handleValidate = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const token = sessionStorage.getItem("token");
    let isValid = true;

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    // Start loading
    setIsLoading(true);

    // NIC Validation
    if (nic === "") {
      setIsNicInvalid(true);
      setNicError("Please enter an NIC.");
      isValid = false;
    } else if (!/^\d{12}$/.test(nic) && !/^\d{9}[VvXx]$/.test(nic)) {
      setIsNicInvalid(true);
      setNicError(
        "NIC must be either 12 digits or 10 characters ending with 'V' or 'X'."
      );
      isValid = false;
    } else {
      setIsNicInvalid(false);
      setNicError("");
    }

    if (isValid) {
      try {
        const response = await apiDefinitions.validateNic(token, nic);
        if (response.data.code === 200) {
          setIsNicAvailable(true);
          toast.success("NIC is Not Available");
        } else {
          setIsNicAvailable(false);
          setLeadNumber(response.data.data.id);
          toast.success("NIC is Available");
          console.log("Lead Number", leadNumber);
        }
      } catch (error) {
        console.error("Error validating NIC:", error);
        toast.error("An error occurred while validating NIC.");
      }
    }

    // Stop loading
    setIsLoading(false);
  };

  useEffect(() => {
    if (leadNumber) {
      navigate(route.updateLeads.replace(":id", leadNumber));
    }
  }, [leadNumber]);

  const handleFillFields = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Filling fields");
  };

  useEffect(() => {
    if (leadStatus === "1") {
      setPriority("3");
      setPriorityLevelStatus(true);
    } else {
      setPriority("");
      setPriorityLevelStatus(false);
    }
  }, [leadStatus]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      console.log("Decoded Token", decodedToken);

      setEmployeeId(decodedToken.serial_number);
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  useEffect(() => {
    console.log("Employee ID", employeeId);
  }, [employeeId]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);
      console.log("Decoded Token", decodedToken);

      if (preferredDegree) {
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          apiDefinitions
            .getCoursesByCategoryId(token, preferredDegree)
            .then((response: any) => {
              console.log("Response", response.data.data);
              setCoursesList(response.data.data);
            });
        } else {
          console.log("Session expired. Please log in again.");
          navigate(route.login);
        }
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, [preferredDegree]);

  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      console.error("No token found. Please log in again.");
      return;
    }

    try {
      const decodedToken: DecodedToken = jwtDecode(token) as DecodedToken;
      const currentTime = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp > currentTime) {
        apiDefinitions.getAllCoursesCategories(token).then((response: any) => {
          console.log("Course Categories: ", response.data.data);
          setCoursesCategoryList(response.data.data);
        });
      } else {
        console.log("Session expired. Please log in again.");
        navigate(route.login);
      }
    } catch (error) {
      console.error("Invalid token or unable to decode", error);
    }
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-4">
                    <h4 className="page-title">Quick Leads</h4>
                  </div>
                  <div className="col-8 text-end">
                    <button className="btn btn-primary" onClick={handleBack}>
                      <i
                        className="feather-arrow-left"
                        style={{ fontSize: "17px", marginRight: "5px" }}
                      />
                      Back
                    </button>
                  </div>
                </div>
              </div>

              {isNicAvailable ? (
                <div className="row">
                  <div className="card">
                    <div className="card-header card-buttons">
                      <h5 className="card-title">1) Basic Information</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm">
                          <form className="needs-validation" noValidate>
                            <div className="form-row row">
                              <div className="col-md-4 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom01"
                                >
                                  Full Name
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      isFullNameInvalid ? "is-invalid" : ""
                                    }`}
                                    id="validationCustom01"
                                    placeholder="Full Name"
                                    value={fullName}
                                    onChange={(e) => handleFullNameChange(e)}
                                    style={{ textTransform: "uppercase" }}
                                    required
                                  />
                                  {isFullNameInvalid && (
                                    <div className="invalid-feedback">
                                      Please enter the full name.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom02"
                                >
                                  Contact Number
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      isContactNumberInvalid ? "is-invalid" : ""
                                    }`}
                                    id="validationCustom02"
                                    placeholder="Contact Number"
                                    value={contactNumber}
                                    onChange={(e) =>
                                      handleContactNumberChange(e)
                                    }
                                    required
                                  />
                                  {isContactNumberInvalid && (
                                    <div className="invalid-feedback">
                                      Please enter a valid contact number.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustom01"
                                >
                                  NIC
                                </label>
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      isNicInvalid ? "is-invalid" : ""
                                    }`}
                                    id="validationCustom01"
                                    placeholder="NIC"
                                    value={nic}
                                    disabled={true}
                                    onChange={handleNicChange}
                                    required
                                  />
                                  {isNicInvalid && (
                                    <div className="invalid-feedback">
                                      Please enter a valid NIC.
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-4 mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomUsername"
                                >
                                  Email Address
                                </label>
                                <div className="input-group">
                                  <span
                                    className="input-group-text"
                                    id="inputGroupPrepend"
                                  >
                                    {" "}
                                    @{" "}
                                  </span>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      isEmailAddressInvalid ? "is-invalid" : ""
                                    }`}
                                    id="validationCustomUsername"
                                    placeholder="Email Address"
                                    aria-describedby="inputGroupPrepend"
                                    value={emailAddress}
                                    onChange={(e) =>
                                      setEmailAddress(e.target.value)
                                    }
                                    style={{ textTransform: "lowercase" }}
                                    required
                                  />
                                  {isEmailAddressInvalid && (
                                    <div className="invalid-feedback">
                                      Please enter a valid email address.
                                    </div>
                                  )}
                                </div>
                              </div>

                              {/* <div className="col-md-4 mb-3">
                              <label
                                className="form-label"
                                htmlFor="validationCustomContactMethod"
                              >
                                Contact Method
                              </label>
                              <select
                                className={`form-select ${
                                  isContactMethodInvalid ? "is-invalid" : ""
                                }`}
                                id="validationCustomContactMethod"
                                value={contactMethod}
                                onChange={(e) =>
                                  setContactMethod(e.target.value)
                                }
                                required
                              >
                                <option>Select an option</option>
                                <option value="1">Call</option>
                                <option value="2">Email</option>
                                <option value="3">Text</option>
                              </select>
                              {isContactMethodInvalid && (
                                <div className="invalid-feedback">
                                  Please select a contact method.
                                </div>
                              )}
                            </div> */}

                              {/* <div className="col-xxl-4 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div>
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustomContactMethod"
                                  >
                                    Contact Method
                                  </label>
                                  <div className="d-sm-flex align-items-center justify-content-between">
                                    <div className="form-check form-check-md d-flex align-items-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="1"
                                        id="checkbox-call"
                                        checked={contactMethod.includes("1")}
                                        onChange={() =>
                                          handleContactMethodChange("1")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="checkbox-call"
                                      >
                                        Call
                                      </label>
                                    </div>
                                    <div className="form-check form-check-md d-flex align-items-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="2"
                                        id="checkbox-email"
                                        checked={contactMethod.includes("2")}
                                        onChange={() =>
                                          handleContactMethodChange("2")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="checkbox-email"
                                      >
                                        Email
                                      </label>
                                    </div>
                                    <div className="form-check form-check-md d-flex align-items-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value="3"
                                        id="checkbox-text"
                                        checked={contactMethod.includes("3")}
                                        onChange={() =>
                                          handleContactMethodChange("3")
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="checkbox-text"
                                      >
                                        Text
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div> */}

                              <div className="card-header card-buttons">
                                <h5 className="card-title">2) Lead Source</h5>
                              </div>

                              <div className="col-md-4 mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomContactMethod"
                                >
                                  Source of Inquiry
                                </label>
                                <select
                                  className={`form-select ${
                                    isSourceOfInquiryInvalid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomContactMethod"
                                  value={sourceOfInquiry}
                                  onChange={(e) =>
                                    setSourceOfInquiry(e.target.value)
                                  }
                                  required
                                >
                                  <option>Select an option</option>
                                  <option value="1">Direct Call</option>
                                  <option value="2">Representative</option>
                                  <option value="3">Website</option>
                                  <option value="4">Social Media</option>
                                  <option value="5">Campaigns</option>
                                </select>
                                {isSourceOfInquiryInvalid && (
                                  <div className="invalid-feedback">
                                    Please select a source of inquiry.
                                  </div>
                                )}
                              </div>

                              {sourceOfInquiry === "4" && (
                                <div className="col-md-4 mb-3 mt-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustomContactMethod"
                                  >
                                    Social Media Type
                                  </label>
                                  <select
                                    className={`form-select ${
                                      isSocialMediaTypeInvalid
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="validationCustomContactMethod"
                                    value={socialMediaType}
                                    onChange={(e) =>
                                      setSocialMediaType(e.target.value)
                                    }
                                    required
                                  >
                                    <option>Select an option</option>
                                    <option value="4">YouTube</option>
                                    <option value="1">Facebook</option>
                                    <option value="2">Instagram</option>
                                    <option value="3">LinkedIn</option>
                                    <option value="4">Tik Tok</option>
                                  </select>
                                  {isSocialMediaTypeInvalid && (
                                    <div className="invalid-feedback">
                                      Please select a social media type.
                                    </div>
                                  )}
                                </div>
                              )}

                              {sourceOfInquiry === "5" && (
                                <div className="col-md-4 mb-3 mt-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustomContactMethod"
                                  >
                                    Campaign Name
                                  </label>
                                  <select
                                    className={`form-select ${
                                      isCampaignInvalid ? "is-invalid" : ""
                                    }`}
                                    id="validationCustomContactMethod"
                                    value={selectedCampaign}
                                    onChange={(e) =>
                                      setSelectedCampaign(e.target.value)
                                    }
                                    required
                                  >
                                    <option>Select an option</option>
                                    {campaignList.map((campaign: Category) => (
                                      <option
                                        key={campaign.id}
                                        value={campaign.id}
                                      >
                                        {campaign.name}
                                      </option>
                                    ))}
                                  </select>
                                  {isCampaignInvalid && (
                                    <div className="invalid-feedback">
                                      Please select a social media type.
                                    </div>
                                  )}
                                </div>
                              )}

                              {sourceOfInquiry === "2" && (
                                <div className="col-md-4 mb-3 mt-3">
                                  <label
                                    className="form-label"
                                    htmlFor="validationCustomContactMethod"
                                  >
                                    Representative's Name
                                  </label>
                                  <select
                                    className={`form-select ${
                                      isRepresentativeNameInvalid
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    id="validationCustomContactMethod"
                                    value={representativeName}
                                    onChange={(e) =>
                                      setRepresentativeName(e.target.value)
                                    }
                                    required
                                  >
                                    <option>Select an option</option>
                                    <option value="1">Representative 01</option>
                                    <option value="2">Representative 02</option>
                                    <option value="3">Representative 03</option>
                                    <option value="4">Representative 04</option>
                                  </select>
                                  {isRepresentativeNameInvalid && (
                                    <div className="invalid-feedback">
                                      Please select a representative's name.
                                    </div>
                                  )}
                                </div>
                              )}

                              <div className="card-header card-buttons">
                                <h5 className="card-title">
                                  3) Academic Interest
                                </h5>
                              </div>

                              <div className="col-md-4 mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomContactMethod"
                                >
                                  Preferred Degree
                                </label>
                                <select
                                  className={`form-select ${
                                    isPreferredDegreeInvalid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomContactMethod"
                                  value={preferredDegree}
                                  onChange={(e) =>
                                    setPreferredDegree(e.target.value)
                                  }
                                  required
                                >
                                  <option>Select an option</option>
                                  {coursesCategoryList.map((courseCategory) => (
                                    <option
                                      key={courseCategory.course_category_id}
                                      value={courseCategory.course_category_id}
                                    >
                                      {courseCategory.category_name}
                                    </option>
                                  ))}
                                </select>
                                {isPreferredDegreeInvalid && (
                                  <div className="invalid-feedback">
                                    Please select a preferred degree.
                                  </div>
                                )}
                              </div>

                              <div className="col-md-12 mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomContactMethod"
                                >
                                  Preferred Course
                                </label>

                                <Select
                                  classNamePrefix="react-select"
                                  className="js-example-placeholder-multiple select2 js-states"
                                  isMulti={true}
                                  placeholder="Select"
                                  options={options}
                                  value={options.filter((option) =>
                                    selectedCourses.includes(option.value)
                                  )}
                                  onChange={(selectedOptions) => {
                                    const courseValues = selectedOptions.map(
                                      (option) => option.value
                                    );
                                    setSelectedCourses(courseValues);
                                    // setIsPreferredCourseInvalid(
                                    //   courseValues.length === 0
                                    // );
                                  }}
                                />

                                {isPreferredCourseInvalid && (
                                  <div className="invalid-feedback">
                                    Please select a preferred course.
                                  </div>
                                )}
                              </div>

                              <div className="card-header card-buttons">
                                <h5 className="card-title">
                                  4) Status and Priority
                                </h5>
                              </div>

                              <div className="col-md-4 mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomContactMethod"
                                >
                                  Lead Status
                                </label>
                                <select
                                  disabled={true}
                                  className={`form-select ${
                                    isLeadStatusInvalid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomContactMethod"
                                  value={leadStatus}
                                  onChange={(e) =>
                                    setLeadStatus(e.target.value)
                                  }
                                  required
                                >
                                  <option>Select an option</option>
                                  <option value="1">New</option>
                                  <option value="2">Contacted</option>
                                  <option value="3">Interested</option>
                                  <option value="4">
                                    Application in Progress
                                  </option>
                                  <option value="5">Enrolled</option>
                                  <option value="6">Disqualified</option>
                                  <option value="7">Follow-Up Needed</option>
                                  <option value="8">Lost</option>
                                </select>
                                {isLeadStatusInvalid && (
                                  <div className="invalid-feedback">
                                    Please select a lead status.
                                  </div>
                                )}
                              </div>

                              <div className="col-md-4 mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomContactMethod"
                                >
                                  Lead Priority Level
                                </label>
                                <select
                                  className={`form-select ${
                                    isPriorityInvalid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomContactMethod"
                                  value={priority}
                                  onChange={(e) => setPriority(e.target.value)}
                                  required
                                  disabled={prorityLevelStatus}
                                >
                                  <option>Select an option</option>
                                  <option value="1">Low </option>
                                  <option value="2">Medium</option>
                                  <option value="3">High </option>
                                </select>
                                {isPriorityInvalid && (
                                  <div className="invalid-feedback">
                                    Please select a priority level.
                                  </div>
                                )}
                              </div>

                              <div className="card-header card-buttons">
                                <h5 className="card-title">
                                  5) Follow Up Details
                                </h5>
                              </div>

                              <div className="col-md-4 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomFollowUpDate"
                                >
                                  Follow Up Date
                                </label>
                                <input
                                  type="date"
                                  className={`form-control ${
                                    isFollowUpDateInvalid ? "is-invalid" : ""
                                  }`}
                                  id="validationCustomFollowUpDate"
                                  value={followUpDate}
                                  disabled={true}
                                  onChange={(e) =>
                                    setFollowUpDate(e.target.value)
                                  }
                                  required
                                />
                                {isFollowUpDateInvalid && (
                                  <div className="invalid-feedback">
                                    Please select a follow-up date.
                                  </div>
                                )}
                              </div>

                              <div className="col-md-8 mb-3 mt-3">
                                <label
                                  className="form-label"
                                  htmlFor="validationCustomContactMethod"
                                >
                                  Additional Note
                                </label>
                                <textarea
                                  rows={1}
                                  cols={5}
                                  className="form-control"
                                  placeholder="Enter text here"
                                  defaultValue={""}
                                  onChange={(e) => setNote(e.target.value)}
                                  value={note}
                                />
                              </div>

                              <div className="col-md-4 mt-3">
                                <button
                                  className="btn btn-primary"
                                  onClick={handleSubmit}
                                >
                                  Submit form
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center mt-5">
                  <div className="card w-70">
                    <div
                      className="card-body d-flex justify-content-center align-items-center"
                      style={{ height: "30vh" }}
                    >
                      <form
                        className="needs-validation"
                        noValidate
                        style={{ width: "500px", marginTop: "30px" }}
                      >
                        <div className="form-row row justify-content-center">
                          <div className="col-md-12 mb-3">
                            <div className="input-group">
                              <input
                                type="text"
                                className={`form-control ${
                                  isNicInvalid ? "is-invalid" : ""
                                }`}
                                id="validationCustom01"
                                placeholder="Please enter lead's NIC"
                                value={nic}
                                onChange={handleNicChange}
                                required
                              />
                              {isNicInvalid && (
                                <div className="invalid-feedback">
                                  {nicError}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-md-12 d-flex justify-content-center">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleValidate}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                "Validate NIC"
                              )}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Analytics;
