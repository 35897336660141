export const activitiesTask = [
  {
    id: 1,
    checkbox: "",
    title: "Store and manage contact data",
    due_date: "19 Oct 2023, 02:35 pm",
    owner: "Swaniawski",
    created_date: "14 Oct 2023, 01:25 pm",
    status: "2",
    action: "",
	key: "1"
  },
  {
    id: 2,
    checkbox: "",
    title: "Built landing pages",
    due_date: "25 Nov 2023, 01:40 pm",
    owner: "Danny",
    created_date: "20 Nov 2023, 08:20 am",
    status: "2",
    action: "",
	key: "2"
  },
];
